import React from 'react'
import { Col, Row,  } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const About = () => {
    return (
        <div >
            <div className='contact-image ' >
                <Row className="mt-8">
                    <Col className='text-center ' style={{ marginTop: '50px' }}>
                        <nav aria-label="breadcrumb" >
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/home" className="text-500 text-base font-semibold" style={{ textDecoration: "none" }}>Home</Link></li>
                                <li className="breadcrumb-item active" aria-current="page" ><Link to="/about" className="text-teal-600  text-base font-semibold" style={{ textDecoration: "none" }}>About Us</Link></li>
                            </ol>
                        </nav>
                        <span className="" style={{ color: '#1a2141', fontSize: '30px', fontWeight: 700, textTransform: 'uppercase', lineHeight: 1.2, marginTop: '100px' }}>About us</span>
                    </Col>
                    <div data-aos="fade-up" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="600">

                        <Row lg={12} className='d-flex justify-content-start' >
                            <Col className="d-flex flex-column justify-content-start mt-4 m-3 ">
                                <p style={{ fontSize: '17px', color: '#777777', fontStyle: 'sans-serif' }}>
                                    The Raheja Revanta project, launched in 2012 by Raheja Developers Ltd. was scheduled to be completed in all respects by 2016.
                                    However, despite several assurances from the builder, the project looks in no shape for delivery anytime soon.
                                    Barring sporadic construction updates and communication to extend delivery dates citing further delays,
                                    nothing concrete has come from the builders in the last few years. As a result of this, several buyers of the Tapas Townhouses and
                                    Surya Towers (covered in Revanta) projects, have decided to get together and raise their voice from a common platform to persuade Raheja Builders Ltd.
                                    to become more accountable. The association charter was, thus, kick-started in April 2020 - 4 years after the date of promised delivery of the project
                                </p>
                                <p style={{ fontSize: '17px', color: '#777777', fontStyle: 'sans-serif' }} className="mt-5">
                                    The ‘RGFBA (raheja gurgaon flat buyers association), registered under The Societies Registration Act, 1860,
                                    is an association formed by apartment owners of the under construction Raheja Revanta project
                                    (Tapas Townhouses & Surya Tower) in Sector 78, Gurugram (Haryana). The association has been created to
                                    represent the interests of the residents of Raheja Revanta buyers and future residents of the society.
                                    The primary purpose of the RGFBA (raheja gurgaon flat buyers association) shall be to work as a front
                                    to raise common civic and legal problems related to the Raheja Revanta project and achieve results with mutual co-operation.
                                </p>
                            </Col>
                        </Row>
                    </div>
                </Row>
            </div>
        </div>
    )
}

export default About
