import React, { useState } from "react";
import axios from "axios";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from "react-router-dom";

export default function Login() {

    const [mobile, setMobile] = useState("");
    const navigate = useNavigate()
    let [loadingBtn, setLoadingBtn] = useState(false);

    const onSubmit = (data) => {
        console.log(mobile)
        data.preventDefault();
       
        if (mobile) {
            let body = {
                email_or_phone : mobile
            }
            let url = process.env.REACT_APP_BASEURL + "login-number/";

            const config = {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-type": "application/json",
                }
            };

            axios.post(url, body, config)
                .then((res) => {
                    setLoadingBtn(false)
                    console.log(res.data)
                    toast.success("OTP send successfully", {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 1000,
                        theme: "colored",
                      });
                    navigate(`/otp?num=${mobile}`, {num: mobile})
                })
                .catch((err) => {
                    setLoadingBtn(false)
                    console.log(err);
                    toast.error("Something went wrong.", {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 1000,
                        theme: "colored",
                      });
                    navigate("/")
                });
        };
    }
 
    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-3"></div>
                    <div className="col-md-6" style={{ marginTop: "120px" }}>
                        <div className="card text-center px-5" style={{ boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)" }}>
                            <div className="card-body">
                                <form onSubmit={onSubmit}>
                                    <div className='mt-5' style={{ fontFamily: 'sans-serif', "color": "#13547A", fontWeight: 600, "fontSize": '24px' }}>Login Page.</div>
                                    <div className='mt-2' style={{ fontFamily: 'sans-serif', "color": "#000000", fontWeight: 400, "fontSize": '16px' }}>Login Using Email Or Phone Number.</div>         
                                    <div className="col-sm mt-3">
                                        <input
                                            style={{ borderRadius: "30px" }}
                                            type="text"
                                            name="first_name"
                                            className="form-control py-3 mt-1 "
                                            placeholder="Enter Email Or Phone Number"
                                            onChange={(e) => { setMobile(e.target.value) }}
                                            required
                                        />
                                        {/* {
                                            mobile.length !== 10 ? "Phone number is not valid!" : 
                                            null
                                        } */}
                                    </div>
                                    <div className="w-100"></div>

                                    <div className="mb-3 mt-0 text-center">
                                                    {loadingBtn ?<button
                                                        disabled
                                                        style={{ borderRadius: "30px" , fontFamily:'sans-serif', "color": "#ffffff" , fontWeight: 500 ,"fontSize":'16px' }}
                                                        className="btn w-100 py-3 editbtnsubmit mt-3 mb-2 border-0"
                                                        type='submit' label='Submit'>
                                                        Send OTP...
                                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                    </button> : <button
                                                        style={{ borderRadius: "30px" , fontFamily:'sans-serif', "color": "#ffffff" , fontWeight: 500 ,"fontSize":'16px' }}
                                                        className="btn w-100 py-3 editbtnsubmit mt-3 mb-2 border-0"
                                                        type='submit' label='Submit'>
                                                        Send OTP
                                                </button>}
                                    </div>
                                    
                                </form>
                                    <button
                                        style={{ borderRadius: "30px" , fontFamily:'sans-serif', fontWeight: 600 ,"fontSize":'16px' }}
                                        className="w-100 py-3 otpbtn mt-2 mb-3"
                                        type='submit' label='Submit'>
                                        <i className="fa-brands fa-google mr-2"></i>
                                        Sign In With Google
                                    </button>
                            </div>
                        </div>
                    </div>
                <div className="col-md-3"></div>
            </div>
        </div>
    </>
    );
}
