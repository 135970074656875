import React, {  useState } from "react";
// import { Route, Redirect } from 'react-router-dom';
import { Navigate,Outlet  } from 'react-router-dom';
import Footer from '../Components/Footer';
import Navigation from '../Components/Navbar';
import Navbar from '../navbar/Navbar';
import Sidebar from '../sidebar/Sidebar';

const PrivateRoute = ({ role }) => {
    const isLoggedIn = localStorage.getItem('access_token');
    const userRole = localStorage.getItem('role');

    let [sidebarOpen, setSidebarOpen] = useState(false);
    const openSidebar = () => {
        setSidebarOpen(true);
    }
    const closeSidebar = () => {
        setSidebarOpen(false);
    }

    return isLoggedIn && userRole === role ?
           <div className="container_custom">
            <Navbar sidebarOpen={sidebarOpen} openSidebar={openSidebar} />
        
        <Outlet />
        <Sidebar sidebarOpen={sidebarOpen} closeSidebar={closeSidebar} />
        </div>

        : <Navigate to="/" />;
};


const CheckHeader = ({ isHeader }) => {

    return isHeader ? <>
        <Navigation ></Navigation>
            <Outlet />
        <Footer></Footer>
        </> :<Outlet />;

}

export { PrivateRoute,CheckHeader };