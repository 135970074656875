import React from "react";
import { Row, Col, Button } from "reactstrap";
import { BiCalendar } from "react-icons/bi";
import { Link } from "react-router-dom";

function CourtOrder() {

    return (
        <>
        <div style={{ marginTop: "130px" }} className="vh-100 ">
            <nav aria-label="breadcrumb" >
                <ol className="breadcrumb ml-5 ">
                    <li className="breadcrumb-item"><Link to="/home" className="text-500 text-base font-semibold" style={{ textDecoration: "none" }}>Home</Link></li>
                    <li className="breadcrumb-item active" aria-current="page" ><Link to="/courtorder" className="text-teal-600  text-base font-semibold" style={{ textDecoration: "none" }}>Court Order</Link></li>
                </ol>
            </nav>
            <Row className="mt-5">
                <Row >
                    <Col lg={6} md={10} className='d-flex justify-content-center flex-column align-items-center' data-aos="zoom-in" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="600">
                        <img src={require("../../images/order.jpeg")} height='300' width='50%' alt="title2"
                            style={{
                                borderRadius: " 10px",
                                boxShadow: "3px 5px 11px 4px grey"
                            }}></img>
                    </Col>
                    <Col lg={6} md={12} className='d-flex flex-column justify-content-center' data-aos="zoom-in" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="600">
                        <span style={{ color: '#000', fontSize: '25px', fontWeight: 600, textTransform: 'uppercase', lineHeight: 1.2, }}>Raheja Developers To Refund Buyers For Delaying Gurgaon Project: Consumer
                        </span>
                        <div className=" d-flex flex-column justify-content-center ">
                            <p style={{ fontSize: '17px', color: '#0b0b2b' }} >
                                The National Consumer Disputes Redressal Commission (NCDRC) has directed real estate developer Raheja Developers to refund the amount paid by homebuyers along with interest for delaying its project Revanta located in Sector 78, Gurgaon. “The opposite party is directed to
                            </p>
                        </div>
                        <div>
                            <Button style={{ margin: '15px 5px 15px 5px', padding: '4px 9px 4px 9px', fontSize: '16px', background: 'teal', border: 'none', fontWeight: '500' }}>Court Order</Button><span style={{ fontSize: '12px' }}><BiCalendar /> 06 September, 2022</span>
                        </div>
                    </Col>
                </Row>
            </Row>
        </div>
    </>)
}
export default CourtOrder;