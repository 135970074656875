import React from 'react';

export default function MemberDocViewModel() {

  return (
    <>
        <h4>Document View</h4>
    </>
  );
}
