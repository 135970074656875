import React from 'react'
import { useState } from "react";
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import { Row, Col } from "react-bootstrap";
import { Input } from "reactstrap";
import { FaSearch } from "react-icons/fa";
// import Modal from 'react-bootstrap/Modal';
import pdf from "../../InnerComponets/assets/images/PDF.png"


export default function MemberBillReceipts() {

  return (
    <>
        <main>
            <div className='main__container'> 
            <div className="container">
                <div className="row d-flex justify-content-center">
                    <div className="col">
                        <p className="main_title_heading m-0">Member Bill Receipts</p>
                    </div>
                    <div className="col-sm-12 col-md-4 text-end">
                        <div className="input-group input-group">
                            <span className="input-group-text searchbar" id="basic-addon1"><FaSearch></FaSearch></span>
                            <Input name="search" placeholder="Search" />
                        </div>
                    </div>
                </div>
                <Row className="col-12 p-0 mt-2">
                    <div className="flex-wrap d-flex justify-content-center  ">
                                        
                        <Card style={{ padding: '0px', margin: '2px', boxShadow: '0px 8px 18px -6px rgba(24, 39, 75, 0.12),  0px 12px 42px -4px rgba(24, 39, 75, 0.12)', width: '250px', borderRadius: "10px" }} className='pt-3 m-3 border-0'>
                            <div className="d-flex justify-content-center mt-2">
                                <img
                                src={pdf}
                                    alt="file"
                                    className="rounded-circle mt-0"
                                    style={{ width: "50px", height: "50px" }}
                                />
                            </div>
                            <Card.Body >
                                <Card.Title className='text-center card_title'>Aman Thakur</Card.Title>
                                <Card.Text className='text-center text-capitalize text-secondary'>
                                    <p>Unit Number #10056 <br/>Application Receipt</p>   
                                </Card.Text>
                                <Col className="d-flex justify-content-center" >
                                    <Button className="cardviewbtn">
                                        View
                                    </Button>
                                </Col>
                            </Card.Body>
                        </Card>
                        
                        <Card style={{ padding: '0px', margin: '2px', boxShadow: '0px 8px 18px -6px rgba(24, 39, 75, 0.12),  0px 12px 42px -4px rgba(24, 39, 75, 0.12)', width: '250px', borderRadius: "10px" }} className='pt-3 m-3 border-0'>
                            <div className="d-flex justify-content-center mt-2">
                                <img
                                src={pdf}
                                    alt="file"
                                    className="rounded-circle mt-0"
                                    style={{ width: "50px", height: "50px" }}
                                />
                            </div>
                            <Card.Body >
                                <Card.Title className='text-center card_title'>Aman Thakur</Card.Title>
                                <Card.Text className='text-center text-capitalize text-secondary'>
                                    <p>Unit Number #10056 <br/>Application Receipt</p>   
                                </Card.Text>
                                <Col className="d-flex justify-content-center" >
                                    <Button className="cardviewbtn">
                                        View
                                    </Button>
                                </Col>
                            </Card.Body>
                        </Card>
                            
                        <Card style={{ padding: '0px', margin: '2px', boxShadow: '0px 8px 18px -6px rgba(24, 39, 75, 0.12),  0px 12px 42px -4px rgba(24, 39, 75, 0.12)', width: '250px', borderRadius: "10px" }} className='pt-3 m-3 border-0'>
                            <div className="d-flex justify-content-center mt-2">
                                <img
                                src={pdf}
                                    alt="file"
                                    className="rounded-circle mt-0"
                                    style={{ width: "50px", height: "50px" }}
                                />
                            </div>
                            <Card.Body >
                                <Card.Title className='text-center card_title'>Aman Thakur</Card.Title>
                                <Card.Text className='text-center text-capitalize text-secondary'>
                                    <p>Unit Number #10056 <br/>Application Receipt</p>   
                                </Card.Text>
                                <Col className="d-flex justify-content-center" >
                                    <Button className="cardviewbtn">
                                        View
                                    </Button>
                                </Col>
                            </Card.Body>
                        </Card>
                            
                        <Card style={{ padding: '0px', margin: '2px', boxShadow: '0px 8px 18px -6px rgba(24, 39, 75, 0.12),  0px 12px 42px -4px rgba(24, 39, 75, 0.12)', width: '250px', borderRadius: "10px" }} className='pt-3 m-3 border-0'>
                            <div className="d-flex justify-content-center mt-2">
                                <img
                                src={pdf}
                                    alt="file"
                                    className="rounded-circle mt-0"
                                    style={{ width: "50px", height: "50px" }}
                                />
                            </div>
                            <Card.Body >
                                <Card.Title className='text-center card_title'>Aman Thakur</Card.Title>
                                <Card.Text className='text-center text-capitalize text-secondary'>
                                    <p>Unit Number #10056 <br/>Application Receipt</p>   
                                </Card.Text>
                                <Col className="d-flex justify-content-center" >
                                    <Button className="cardviewbtn">
                                        View
                                    </Button>
                                </Col>
                            </Card.Body>
                        </Card>
                        
                        <Card style={{ padding: '0px', margin: '2px', boxShadow: '0px 8px 18px -6px rgba(24, 39, 75, 0.12),  0px 12px 42px -4px rgba(24, 39, 75, 0.12)', width: '250px', borderRadius: "10px" }} className='pt-3 m-3 border-0'>
                            <div className="d-flex justify-content-center mt-2">
                                <img
                                src={pdf}
                                    alt="file"
                                    className="rounded-circle mt-0"
                                    style={{ width: "50px", height: "50px" }}
                                />
                            </div>
                            <Card.Body >
                                <Card.Title className='text-center card_title'>Aman Thakur</Card.Title>
                                <Card.Text className='text-center text-capitalize text-secondary'>
                                    <p>Unit Number #10056 <br/>Application Receipt</p>   
                                </Card.Text>
                                <Col className="d-flex justify-content-center" >
                                    <Button className="cardviewbtn">
                                        View
                                    </Button>
                                </Col>
                            </Card.Body>
                        </Card>
                        
                        <Card style={{ padding: '0px', margin: '2px', boxShadow: '0px 8px 18px -6px rgba(24, 39, 75, 0.12),  0px 12px 42px -4px rgba(24, 39, 75, 0.12)', width: '250px', borderRadius: "10px" }} className='pt-3 m-3 border-0'>
                            <div className="d-flex justify-content-center mt-2">
                                <img
                                src={pdf}
                                    alt="file"
                                    className="rounded-circle mt-0"
                                    style={{ width: "50px", height: "50px" }}
                                />
                            </div>
                            <Card.Body >
                                <Card.Title className='text-center card_title'>Aman Thakur</Card.Title>
                                <Card.Text className='text-center text-capitalize text-secondary'>
                                    <p>Unit Number #10056 <br/>Application Receipt</p>   
                                </Card.Text>
                                <Col className="d-flex justify-content-center" >
                                    <Button className="cardviewbtn">
                                        View
                                    </Button>
                                </Col>
                            </Card.Body>
                            </Card>
                            
                    </div>
                </Row>
            </div>
        </div>
        </main>
    </>
  );
}
