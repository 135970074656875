import React from 'react';
import sslogo from '../InnerComponets/assets/images/MainLogo.jpeg';
import rgfbacs from '../images/rgfba-sig.jpg';
import './uitest.css';
export default function UiTest() {

  return (
    <>
      <div className='container' style={{ marginBottom: '80px'}}>
        <div className="container">
          <div className="row" style={{marginTop: '80px'}}>
            <div className="col-sm-3 justify-content-center d-flex">
              <img  src={sslogo} alt="logo" width={100} height={100} />
            </div>
            <div className="col-sm-6 justify-content-center" style={{lineHeight: 0.5}}>
              <p style={{textAlign: 'center', color: '#13547A', fontSize: '20px', fontWeight: 600,fontFamily:'sans-serif'}}>Revanta Gurgaon Flat Buyers Association</p>
              <p style={{textAlign: 'center', fontSize: '15px', fontWeight: 600, fontFamily:'sans-serif'}}>Flat No. 801, Tower 5, Orchid Petals, Sohna Road,</p>
              <p style={{textAlign: 'center', fontSize: '15px', fontWeight: 600, fontFamily:'sans-serif'}}>Sector 49, Gurgaon, Haryana-122018</p>
              <p style={{textAlign: 'center', fontSize: '15px', fontWeight: 600, fontFamily:'sans-serif'}}>+91 82888 55509 | rgfba.main@gmail.com | www.RGFBA.com</p>
            </div>
            <div className="col-sm-3 justify-content-center d-flex">
              <img  src={sslogo} alt="logo" width={100} height={100} />
            </div>
          </div>
        </div>
        <hr style={{borderTop: '5px solid'}} />
        <div className="container">
          <div className="row d-flex">
            <p style={{ textAlign: 'center', color: '#13547A', fontSize: '20px', fontWeight: 600, fontFamily: 'sans-serif' }}><span className='boderdetails' >Reciept</span></p>
                <div className="col-sm">
                  <p><b style={{fontSize: '17px', fontWeight: 600, fontFamily:'sans-serif'}}>Reciept no: <mark className='boderdetails'>4646446464666</mark></b></p>
                </div>
                <div className="col-sm text-end">
                  <p><b style={{fontSize: '17px', fontWeight: 600, fontFamily:'sans-serif',paddingRight: '17px'}}>Date: <mark className='boderdetails'>26/05/2202</mark></b></p>
                </div>
                <div className="w-100"></div>
            <p><b style={{fontSize: '17px', fontWeight: 600, fontFamily:'sans-serif',paddingRight: '17px'}}>Recieved with thanks from: <mark className='boderdetails'>Revanta Gurgaon Flat Buyers Association</mark></b></p>
            <p><b style={{fontSize: '17px', fontWeight: 600, fontFamily:'sans-serif',paddingRight: '17px'}}>Towards:<mark className='boderdetails'>Revanta Gurgaon Flat Buyers Association</mark></b></p>
            <p><b style={{fontSize: '17px', fontWeight: 600, fontFamily:'sans-serif',paddingRight: '17px'}}>The sum of:<mark className='boderdetails'>Twenty Thousand Only</mark></b></p>
            <p><b style={{ fontSize: '17px', fontWeight: 600, fontFamily: 'sans-serif' }}>By:<span style={{ paddingLeft: '17px' }}>
                        <input type="checkbox" id="same_as_permanent_address" name="same_as_permanent_address" value="same_as_permanent_address"  />
                        <label for="same_as_permanent_address" style={{fontSize: '17px', fontWeight: 600, fontFamily:'sans-serif'}} className='ml-1'> Cheque</label>
                        <input type="checkbox" id="same_as_permanent_address" name="same_as_permanent_address" value="same_as_permanent_address" className='ml-3' />
                        <label for="same_as_permanent_address" style={{fontSize: '17px', fontWeight: 600, fontFamily:'sans-serif'}} className='ml-1'> DD</label>
                        <input type="checkbox" id="same_as_permanent_address" name="same_as_permanent_address" value="same_as_permanent_address" className='ml-3' />
                        <label for="same_as_permanent_address" style={{fontSize: '17px', fontWeight: 600, fontFamily:'sans-serif'}} className='ml-1'> Online</label>
            </span></b></p>
            <p><b style={{fontSize: '17px', fontWeight: 600, fontFamily:'sans-serif',paddingRight: '17px'}}>Reference. no:<mark className='boderdetails'>465464454</mark></b></p>
          </div>
        </div>
        <div className="container" style={{paddingTop: '30px'}}>
          <div className="row">
            <div className="col-sm-3">
              <div style={{border: '4px solid black, padding-right:40px'}}>
                <p>
                  <input type="text" placeholder='gjhkk' className="rupee-txt" value="&#8377; 4556/-" />
                </p>
              </div>
            </div>
            <div className="col-sm-6">
            </div>
            <div className="col-sm-3 ">
            <img src={rgfbacs} alt="logo" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
