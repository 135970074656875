import React from 'react';
// import {  Link } from "react-router-dom";
import Button from 'react-bootstrap/Button';

export default function NocModal() {

  return (
    <>
        <h1>NOC Certificate</h1>
          <div className='row d-flex justify-content-center'>
            <div className="col-md-6 mt-5">
                <Button
                    style={{ borderRadius: "30px" , "color": "#ffffff", fontFamily:'sans-serif' }}
                    className="btn viewbtn1 w-100 py-3 editbtnsubmit border-0"
                    type='submit' label='Submit'>
                    Download
                </Button>
            </div>
        </div>
    </>
  );
}
