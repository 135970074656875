import React from 'react';
import sslogo from '../images/Logo_rgfba.png';
import rgfbacs from '../images/letter-sign.jpg';
import './uitest.css';
import mainlogo from '../InnerComponets/assets/images/MainLogo.jpeg';
import cardsign from '../images/card-sign.jpg';
import letterprofile from '../images/letter-profile.jpg';

export default function LetterCard() {

  return (
    <>
      <div className='container' style={{ marginBottom: '80px'}}>
        <div className="container">
          <div className="row" style={{marginTop: '80px'}}>
            <div className="col-sm-3 justify-content-center d-flex">
              <img  src={sslogo} alt="logo" width={100} height={100} />
            </div>
            <div className="col-sm-6 justify-content-center" style={{lineHeight: 0.5}}>
              <p style={{textAlign: 'center', color: '#13547A', fontSize: '25px', fontWeight: 600,fontFamily:'sans-serif'}}>REVANTA GURGAON</p>
              <p style={{textAlign: 'center' , color: '#13547A', fontSize: '22px', fontWeight: 500, fontFamily:'sans-serif'}}>FLAT BUYERS ASSOCIATION</p>
              <p style={{textAlign: 'center', fontSize: '18px', fontWeight: 500, fontFamily:'sans-serif'}}>Registration No. HR/018/2021/02644</p>
            </div>
            <div className="col-sm-3 justify-content-center d-flex">
            <div className="col-sm-6 justify-content-center" style={{lineHeight: 0.5}}>
                <p style={{textAlign: 'right', color: '#13547A', fontSize: '10px', fontWeight: 600,fontFamily:'sans-serif', lineHeight:'1.5'}}>Flat No.801, Tower 5, Orchid Petals, Sohna Road, Sector 49, Gurgaon, Haryana-122018</p>
                <p style={{textAlign: 'right' , color: '#13547A', fontSize: '10px', fontWeight: 600, fontFamily:'sans-serif'}}>+91 8288855509  <i class="fa-solid fa-phone"></i></p>
                <p style={{ textAlign: 'right', color: '#13547A', fontSize: '10px', fontWeight: 600, fontFamily: 'sans-serif' }}>rgfba.main@gmail.com  <i class="fa-solid fa-envelope"></i></p>
                <p style={{textAlign: 'right',color: '#13547A', fontSize: '10px', fontWeight: 600, fontFamily:'sans-serif'}}>www.rgfba.com  <i class="fa-solid fa-globe"></i></p>
            </div>
        </div>
          </div>
        </div>
        <hr style={{borderTop: '5px solid'}} />
        <div className="container">
          <div className="row d-flex">
                <div className="col-sm">
                    <p className='appdetails'>To.</p>
                </div>
                <div className="col-sm text-end">
                  <p><b className='appdetails'>Date: <span>26/05/2202</span></b></p>
                </div>
                <div className="w-100"></div>
            <p className='appdetails'>Mr. AJIT KUMAR</p>
            <p className='appdetails'>D-105, Purva Fountain Square,<br></br> Marathahalli, Bangalore, Karnataka,<br></br> India - 560037</p>
            <p className='appdetails1'>SUBJECT: MEMBERSHIP APPLICATION - ACCEPTED WITH MEMBER ID CARD</p>
            <p className='appdetails'>Dear Mr. AJIT KUMAR</p>
            <p className='appdetails'>With regards to your application for membership of Revanta Gurgaon Flat Buyers Association, we are pleased to welcome you to our Society.</p>
            <p className='appdetails'>If you have not already paid, please pay the annual membership fees within 15 days of the date of this letter, failing which the membership will be suspended</p>
            <p className='appdetails'>Your member ID card is also attached to this mail. Please get it printed and laminated. The same can be provided to you free of cost upon your request and visit to our office.</p>
            {/* <p className='appdetails'>Thank you,</p> */}
            <div className='row'>
                <div className='col-4'>       
                    <img  src={rgfbacs} alt="logo" />
                </div>
                <div className='col-4'>       
                </div>
                <div className='col-4'>       
                </div> 
            </div>
            {/* <div className='d-flex'>
                <p className='appdetails'><span>Saikat Mukhopadhayay /<br></br> (Vice President)</span></p>
                <p className='appdetails ml-2'><span>Dinesh Goyal <br></br> (Secretary)</span></p>
            </div> */}
          </div>
        </div>
        <hr style={{borderTop: '3px dashed'}} />
    </div>
          

    <div className="movie-card">
        <div className="container11">                 
            <img src={letterprofile} alt="cover" className="cover" />
            <div className="hero">
                <div className="details">
                    <div className="title1">REVANTA GURGAON FLAT BUYERS ASSOCIATION</div>
                </div>
            </div>
            <div className='row'>
                <div className='col-3'>
                </div>
                <div className='col-6'>
                    <span className="title2">Mr. Ajit Kumar</span><br></br>
                    <span className="title3">S/O Mr. Ram Nath Sharma</span><br></br>
                    <span className="title2">A-095</span>
                </div>
                <div className='col-3'>
                    <img  src={mainlogo} alt="logo" width={80} height={80} />
                </div>
            </div>
            <div className='row'>
                <div className='col-3'>
                    <span className="title2">D.O.B.</span><br></br>
                    <span className="title3">29-12-1974</span><br></br>
                    <br></br>
                    <span className="title2">Member Type</span><br></br>
                    <span className="title3">Ordinary</span><br></br>
                    <br></br>
                    <span className="title2">Member I.D.</span><br></br>
                    <span className="title3">RGFBA-012</span><br></br>
                </div>
                <div className='col-1 vl'></div>
                <div className='col-4'>
                    <span className="title2">Date of Issue</span><br></br>
                    <span className="title3">14-08-2021</span><br></br>
                    <br></br>
                    <span className="title2">Address</span><br></br>
                    <span className="title3">BANGALORE, KARNATAKA, INDIA-560037</span><br></br>
                    </div>
                <div className='col-1 vl'></div>
                <div className='col-3'>
                    <img  src={cardsign} alt="logo" width={120} height={120} />
                </div>
                  </div>
                  <div className='row text-center'>
                      <p className='title4'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. </p>
                  </div>
        </div>
    </div>
    </>
  );
}
