import React from 'react'
import {  Link } from "react-router-dom";

export default function LawAndInstruction() {

  return (
        <>
            <main>
                <div className='main__container'>
                    <div className="container" style={{fontFamily:'sans-serif' ,fontWeight:'semibold'}}>
                        <div className="row">
                            <div className="col-md">
                                    <h4 className="text-center" style={{ fontFamily:'sans-serif', "color": "#13547A" ,"fontSize":'24px' , fontWeight: 600  }}>
                                        Law & Instructions
                                    </h4>
                            </div>
                        </div>
                        <div className="row ">
                            <div className='col-12'>
                                <p className='mt-2 md-2 paragraph_style'>1. Read the Bye Laws of the society available on our website www.rgfba.com before filling this form.</p>
                                <p className='mt-2 md-2 paragraph_style'>2. All fields marked with ‘*’ are compulsory fields.</p>
                                <p className='mt-2 md-2 paragraph_style'>3. CAUTION: The “Reset” button of each page resets the entire form and not just that page.</p>
                                <p className='mt-2 md-2 paragraph_style'>4. All fields will capitalise automatically once you leave the field.</p>
                                <p className='mt-2 md-2 paragraph_style'>5. Only the first line of the address fields out of the three is compulsory.</p>
                                <p className='mt-2 md-2 paragraph_style'>6. By checking “Same as Permanent Address” checkboxes, Communication Address gets auto populated.Refer to Point No. 7 below</p>
                                <p className='mt-2 md-2 paragraph_style'>7. By checking “Same as Primary Applicant” checkbox, the Permanent Address of Joint Owner gets auto populated. Refer to Point No. 7 below</p>
                                <p className='mt-2 md-2 paragraph_style'>8. Destination fields do not auto update if changes are made to source fields after selecting checkboxes. You will need to un‐check and re‐check the checkboxes to update the destination fields. (Source field: Is where information is being pickedup from. Destination field: Is where information is being auto populated)</p>
                            </div>
                        </div>
                        <div className='row d-flex justify-content-center'>
                            <div className="col-md-6 mt-3 mb-3">
                                <Link
                                    to="/applicationform"
                                    style={{ borderRadius: "30px" , "color": "#ffffff" }}
                                    className="btn w-100 py-3 editbtnsubmit border-0"
                                    type='submit' label='Submit'>
                                    Read & Next
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    );
}
