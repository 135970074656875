import React from "react";
import Content from "./Content";
import Slider from "./Slider";


function Home(){
    return(<>
      <Slider></Slider>
      {/* who we are  and our purpose*/}
      <Content></Content>
      {/* notice */}
      {/* <Notice ></Notice>

      <WhywWExist></WhywWExist> */}
      {/* blog */}
      {/* <Services></Services>
      <FrequentlyQuestion></FrequentlyQuestion>
      <Event></Event> */}
    </>)
}
export default Home;