import React from 'react'
import { useState } from "react";
// import {  Link } from "react-router-dom";
import axios from "axios";
import { toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";


export default function Email() {
        
    let [addData, setAddData] = useState({ membername: '', unitnumber: '', email: '', mobilenumber: ''});

    let [checkMemberName, setCheckMemberName] = useState('');
    let [checkUnitNumber, setCheckUnitNumber] = useState('');
    let [checkEmail, setCheckEmail] = useState('');
    let [checkMobileNumber, setCheckMobileNumber] = useState('');
    let [loadingBtn, setLoadingBtn] = useState(false);
    const navigate = useNavigate()

        let changeMemberName = (e) => {
        setAddData({ ...addData, membername: e.target.value });
        if (e.target.value.trim() === '') {
            setCheckMemberName('Member Name is required');
        } else {
            setCheckMemberName('');
        }
            }
        
        let changeUnitnumber = (e) => {
            setAddData({ ...addData, unitnumber: e.target.value });
            if (e.target.value.trim() === ''){
                setCheckUnitNumber('Unit Number is required'); } 
            else{
                setCheckUnitNumber('');
            }
        }
    
        let changePhonenumber = (e) => {
            setAddData({ ...addData, mobilenumber: e.target.value });
            if (e.target.value.length !== 10 || Number(e.target.value) < 6000000000) {
                setCheckMobileNumber('Mobile Number is required or invalid');
            } else {
                setCheckMobileNumber('');
            }
        }

        let changeEmail = (e) => {
            setAddData({ ...addData, email: e.target.value });
            if (!(RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(e.target.value))){
                setCheckEmail('Email is required or invalid'); } 
            else{
                setCheckEmail('');
            }
        }

    
    let save = (e) => {
        e.preventDefault();
        let error = 0;

        if (addData.membername.trim() === '') {
            setCheckMemberName('First name is required');
            error = 1;
        }
        

        if (Number(addData.mobilenumber) <= 6000000000 || Number(addData.mobilenumber) >= 9999999999) {
            setCheckMobileNumber('Mobile number is required or invalid');
            error = 1;
        } 

        if (addData.email.trim() === '') {
            setCheckEmail('Email is required or invalid');
            error = 1;
        } 

        if (addData.unitnumber.trim() === '') {
            setCheckUnitNumber('Unit number is required');
            error = 1;
        }

        if (error === 0) {
            setLoadingBtn(true)
            let addmember = {
                name: addData.membername,
                member_unit: addData.unitnumber,
                email: addData.email,
                phone: addData.mobilenumber,
            }
           
            let url = process.env.REACT_APP_BASEURL + "add-member/";
          
            const config = {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-type": "application/json",
                    "Authorization": 'Bearer ' + localStorage.getItem("access_token")
                }
            };
            axios.post(url, addmember, config)
                .then((res) => {
                    console.log(res)
                    setLoadingBtn(false)
                    toast.success("Member added successful!", {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 3000,
                        theme: "colored",
                    }) 
                    navigate("/admindashboard")
                  }
                )
                .catch((error) => {
                    setLoadingBtn(false)
                    console.log(error);
                    toast.error("Something went wrong.", {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 1000,
                        theme: "colored",
                      });
                })
        }
    }

  return (
    <>
        <main>
            <div className='main__container'> 
                <div className="container">
                    <div className="row d-flex justify-content-center">
                        <div className="col ">
                            <p className="main_title_heading m-0">Email</p>
                        </div>
                    </div> 
                    <form onSubmit={save}>
                        <div className="row">                  
                            <div className="col-sm mt-3 mr-3">
                                <span className='form-text ml-3' style={{ fontFamily:'sans-serif', "color": "#000000" , fontWeight: 400 ,"fontSize":'16px' }}>Member Name*</span>
                                <input
                                    style={{ borderRadius: "30px" }}
                                    type="text"
                                    name="first_name"
                                    className="form-control py-3 mt-1 "
                                    placeholder="Enter Member Name"
                                    onChange={changeMemberName}
                                    />
                                <small className="text-danger">{checkMemberName}</small>
                            </div>
                            <div className="col-sm mt-3 mr-3">
                                <span className='form-text ml-3' style={{ fontFamily:'sans-serif', "color": "#000000" , fontWeight: 400 ,"fontSize":'16px' }}>Member Unit Number*</span>
                                <input
                                    style={{ borderRadius: "30px" }}
                                    type="text"
                                    name="first_name"
                                    className="form-control py-3 mt-1 "
                                    placeholder="Enter Member Unit Number"
                                    onChange={changeUnitnumber}
                                    />
                                <small className="text-danger">{checkUnitNumber}</small>
                            </div>
                            <div className="w-100"></div>
                            <div className="col-sm mt-3 mr-3">
                                <span className='form-text ml-3' style={{ fontFamily:'sans-serif', "color": "#000000" , fontWeight: 400 ,"fontSize":'16px' }}>Email ID*</span>
                                <input
                                    style={{ borderRadius: "30px" }}
                                    type="text"
                                    name="first_name"
                                    className="form-control py-3 mt-1 "
                                    placeholder="Enter Email ID"
                                    onChange={changeEmail}
                                    />
                                <small className="text-danger">{checkEmail}</small>
                            </div>
                            <div className="col-sm mt-3 mr-3">
                                <span className='form-text ml-3' style={{ fontFamily:'sans-serif', "color": "#000000" , fontWeight: 400 ,"fontSize":'16px' }}>Phone Number*</span>
                                <input
                                    style={{ borderRadius: "30px" }}
                                    type="text"
                                    name="first_name"
                                    className="form-control py-3 mt-1 "
                                    placeholder="Enter Phone Number"
                                    onChange={changePhonenumber}
                                    />
                                <small className="text-danger">{checkMobileNumber}</small>
                            </div>
                            <div className="w-100"></div>                  
                        </div>
                        <div className='row d-flex justify-content-center'>
                                <div className="col-md-6 mt-5">
                                    {loadingBtn ?<button
                                            disabled
                                            style={{ borderRadius: "30px" , "color": "#ffffff" }}
                                            className="btn w-100 py-3 editbtnsubmit border-0"
                                            type='submit' label='Submit'>
                                            Submitting...
                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                        </button> : <button
                                                style={{ borderRadius: "30px" , "color": "#ffffff" }}
                                            className="btn w-100 py-3 editbtnsubmit border-0"
                                            type='submit' label='Submit'>
                                            Submit
                                </button>}
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </main>
    </>
  );
}
