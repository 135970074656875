import React, { useState } from "react";
import axios from "axios";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate, useSearchParams } from "react-router-dom";

export default function Otp() {

let [val, setVal] = useSearchParams();
let [addData, setAddData] = useState({ emailphone:'', otp:''});
let [checkotp, setCheckotp] = useState(null);
let [loadingBtn, setLoadingBtn] = useState(false);

const navigate = useNavigate()

    const onSubmit = (data) => {
        data.preventDefault();
        let error = 0;
       
        if (addData.otp === '') {
            setCheckotp('OTP is Required or Invalid');
            error = 1;
        }
        if (error === 0) {
            setLoadingBtn(true)
            let formData = new FormData();

            formData.append("email_or_phone", val.get("num"));
            formData.append("otp", addData.otp);
 
            let url = process.env.REACT_APP_BASEURL + "loginotp/";

            const config = {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-type": "application/json",
                    // "Authorization": localStorage.getItem("token")
                }
            };

            axios.post(url, formData, config)
                .then((res) => {
                    setLoadingBtn(false)
                    if (res.data) {   
                        localStorage.setItem("access_token", res.data.access)
                        localStorage.setItem("role", res.data.role)
                        if (res.data.role === "user") {
                            navigate("/memberdashboard")
                        } else if (res.data.role === "admin") {
                            navigate("/admindashboard")
                        }
                    }
                      toast.success("Login successfully", {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 1000,
                        theme: "colored", 
                      });
                })
                .catch((err) => {
                    setLoadingBtn(false)
                    console.log(err);
                    toast.error("Something went wrong.", {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 1000,
                        theme: "colored",
                      });
                });
        };
    }
    
    let changeOtp = (e) => {
        setAddData({ ...addData, otp: e.target.value });
        if (e.target.value.trim() === '') {
            setCheckotp('OTP is Required or Invalid');
        } else {
            setCheckotp('');
        }
    }

    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-3"></div>
                    <div className="col-md-6" style={{ marginTop: "120px" }}>
                        <div className="card text-center px-5" style={{ boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)" }}>
                            <div className="card-body">
                                <form onSubmit={onSubmit}>
                                    <div className='mt-5' style={{ fontFamily: 'sans-serif', "color": "#13547A", fontWeight: 600, "fontSize": '24px' }}>OTP Page.</div>
                                    <div className='mt-2' style={{ fontFamily: 'sans-serif', "color": "#000000", fontWeight: 400, "fontSize": '16px' }}>Enter valid Email Or Phone Number and OTP.</div>         
                                    <div className="col-sm mt-3">
                                        <input
                                            style={{ borderRadius: "30px" }}
                                            type="text"
                                            name="first_name"
                                            className="form-control py-3 mt-1 "
                                            placeholder="Enter Email Or Phone Number"
                                            value={val.get("num")}
                                        />
                                    </div>
                                   
                                    <div className="col-sm mt-3">
                                        <input
                                            style={{ borderRadius: "30px" }}
                                            type="text"
                                            name="first_name"
                                            className="form-control py-3 mt-1 "
                                            placeholder="Enter OTP"
                                            onChange={changeOtp}
                                            />
                                        <small className="text-danger mb-3">{checkotp}</small>
                                    </div>
                                        
                                    <div className="w-100"></div>
                                    <div className="mb-3 mt-0 text-center">
                                            {loadingBtn ?<button
                                                disabled
                                                style={{ borderRadius: "30px" , fontFamily:'sans-serif', "color": "#ffffff" , fontWeight: 500 ,"fontSize":'16px' }}
                                                className="btn w-100 py-3 editbtnsubmit mt-3 mb-2 border-0"
                                                type='submit' label='Submit'>
                                                Login...
                                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                            </button> : <button
                                                style={{ borderRadius: "30px" , fontFamily:'sans-serif', "color": "#ffffff" , fontWeight: 500 ,"fontSize":'16px' }}
                                                className="btn w-100 py-3 editbtnsubmit mt-3 mb-2 border-0"
                                                type='submit' label='Submit'>
                                                Login
                                        </button>}
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                <div className="col-md-3"></div>
            </div>
        </div>
    </>
    );
}
