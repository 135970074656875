import React from 'react';

export default function SignatureModal() {

  return (
    <>
     <h1>Signature Modal</h1>
    </>
  );
}
