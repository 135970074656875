import React from "react"
import './App.css';
import Home from './Components/Home';
import Contact from './Components/Contact/Contact';
import Social from './Components/social/Social';
import About from './Components/AboutUs/About';
import Vision from './Components/AboutUs/Vision';
import Concerns from './Components/AboutUs/Concerns';
import Mission from './Components/AboutUs/Mission';
import News from './Components/News/News';
import CourtOrder from './Components/CourtOrder/Courtorder';
import Construction from './Components/Construction/Construction';
import CheatedHome from './Components/CheatedBuyer/Cheatedhome';
import RefundPayment from './Components/RefundPayment/Refundpayment';
// import RecentEvents from './Components/events/recentEvents';
// import UpcomingEvents from './Components/events/upcomingEvents';
import ApplicationForm from './InnerComponets/applicationForm/ApplicationForm'
import ApplicationFormSuccessfull from './InnerComponets/applicationForm/ApplicationFormSuccessfull';
import MemberDocuments from './InnerComponets/applicationForm/MemberDocuments';
import LawAndInstruction from './InnerComponets/applicationForm/LawAndInstruction';
import PaymentPage from './InnerComponets/applicationForm/PaymentPage';
import MemberMemberShipCard from './InnerComponets/memberMemberShipCard/MemberMemberShipCard';
import MemberBillReceipt from './InnerComponets/memberBillReceipt/MemberBillReceipt';
import MemberProfile from './InnerComponets/memberProfile/MemberProfile';
import Email from './adminComponents/adminemail/Email';
import MemberApplicationList from "./adminComponents/adminApplicationForm/MemberApplicationList";
import MemberViewProfile from "./adminComponents/adminApplicationForm/MemberViewProfile";
import MemberEditProfile from "./adminComponents/adminApplicationForm/MemberEditProfile";
import MemberBillReceipts from "./adminComponents/adminMemberBillReceipt/MemberBillReceipts";
import MemberShipCards from "./adminComponents/adminMemberShipCard/MemberShipCards";
import AdminDashboard from "./adminComponents/adminDashboard/AdminDashboard";
import MemberDashboard from "./InnerComponets/memberDashboard/MemberDashboard";
import Login from "./Login/Login";
import Otp from "./Login/Otp";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import {PrivateRoute, CheckHeader} from "./utils/PrivateRoute";
import { ToastContainer } from "react-toastify";
import UiTest from "./templatesCode/uitest";
import LetterCard from "./templatesCode/letterCard";

function App() {
  return (

    <>
      <ToastContainer />
      <Routes>
        {/* Common Component */}
            <Route path='/login' element={<Login />}></Route>
              <Route path='/otp' element={<Otp />}></Route>
              
              {/* Templates  */}
              <Route path='/uitest' element={<UiTest />}></Route>
              <Route path='/lettercard' element={<LetterCard />}></Route>

        {/* Website Components */}
            <Route exact path='/' element={<CheckHeader isHeader={true} />}>
                <Route path='/' element={<Home></Home>}></Route>
                <Route path='/home' element={<Home></Home>}></Route>
                {/* <Route path='/gallery' element={<Gallery></Gallery>}></Route> */}
                <Route path='/contact' element={<Contact></Contact>}></Route>
                {/* <Route path='/notice' element={<NoticePage></NoticePage>}></Route> */}
                <Route path='/socialmediacorner' element={<Social></Social>}></Route>
                {/* <Route path='/recentevnet' element={<RecentEvents></RecentEvents>}></Route> */}
                {/* <Route path='/upcomingevnet' element={<UpcomingEvents></UpcomingEvents>}></Route> */}
                <Route path='/news' element={<News/>}></Route>
                <Route path='/courtorder' element={<CourtOrder/>}></Route>
                <Route path='/construction' element={<Construction/>}></Route>
                <Route path='/cheatedhomebuyers' element={<CheatedHome/>}></Route>
                <Route path='/refundpayment' element={<RefundPayment/>}></Route>
                <Route path='/about' element={<About></About>}></Route>
                <Route path='/mission' element={<Mission></Mission>}></Route>
                <Route path='/vision' element={<Vision></Vision>}></Route>
                <Route path='/concerns' element={<Concerns></Concerns>}></Route>
            </Route>

        {/* Member Component */}
            <Route exact path='/' element={<PrivateRoute role={'user'} />}>
                <Route path='/memberdashboard' element={<MemberDashboard />}></Route>
                <Route path='/applicationform' element={<ApplicationForm />}></Route>
                <Route path='/applicationformsuccessfull' element={<ApplicationFormSuccessfull />}></Route>
                <Route path='/memberdocuments' element={<MemberDocuments />}></Route>
                <Route path='/lawandinstructions' element={<LawAndInstruction />}></Route>
                <Route path='/paymentpage' element={<PaymentPage />}></Route>
                <Route path='/membermembershipcard' element={<MemberMemberShipCard />}></Route>
                <Route path='/memberbillreceipt' element={<MemberBillReceipt />}></Route>
                <Route path='/memberprofile' element={<MemberProfile />}></Route>
            </Route>
        
        {/* Admin Component */}
            <Route exact path='/' element={<PrivateRoute role={'admin'} />}>
                <Route path='/admindashboard' element={<AdminDashboard />}></Route>
                <Route path='/email' element={<Email />}></Route>
                <Route path='/memberapplicationlist' element={<MemberApplicationList />}></Route>
                <Route path='/memberviewprofile/:id' element={<MemberViewProfile />}></Route>
                <Route path='/membereditprofile' element={<MemberEditProfile />}></Route>
                <Route path='/memberbillreceipts' element={<MemberBillReceipts />}></Route>
                <Route path='/membershipcards' element={<MemberShipCards />}></Route>
                </Route>
            </Routes>
          </>
  );
}

export default App;
