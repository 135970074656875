import React from "react";
import { Row, Col, Button } from "reactstrap";
import { BiCalendar } from "react-icons/bi";
import { Link } from "react-router-dom";
function Construction() {
    return (<>
        <div style={{ marginTop: "130px" }} className="vh-100 ">
            <nav aria-label="breadcrumb" >
                <ol className="breadcrumb ml-5 ">
                    <li className="breadcrumb-item"><Link to="/home" className="text-500 text-base font-semibold" style={{ textDecoration: "none" }}>Home</Link></li>
                    <li className="breadcrumb-item active" aria-current="page" ><Link to="/construction" className="text-teal-600  text-base font-semibold" style={{ textDecoration: "none" }}>Construction</Link></li>
                </ol>
            </nav>
            <Row className="mt-5">
                <Row >
                    <Col lg={6} md={10} className='d-flex justify-content-center flex-column align-items-center' data-aos="zoom-in" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="600">
                        <img src={require("../../images/b-block.jpeg")} height='300' width='50%' alt="title1"
                            style={{
                                borderRadius: " 10px",
                                boxShadow: "3px 5px 11px 4px grey"
                            }}></img>

                    </Col>
                    <Col lg={6} md={12} className='d-flex flex-column justify-content-center' data-aos="zoom-in" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="600">
                        <span style={{ color: '#000', fontSize: '25px', fontWeight: 600, textTransform: 'uppercase', lineHeight: 1.2, }}>B-C Block Decking Still Has A Bunch Of Scrap

                        </span>
                        <div className=" d-flex flex-column justify-content-center ">
                            <p style={{ fontSize: '17px', color: '#0b0b2b' }} >
                            Raheja Developers have been working on the 46th Club House Floor of Surya Towers of  Raheja Revanta since early 2020. 2 years on only A-B block decking is finished, B-C block decking still has a bunch of scrap and malba
                            </p>
                        </div>
                        <div>
                            <Button style={{ margin: '15px 5px 15px 5px', padding: '4px 9px 4px 9px', fontSize: '16px', background: 'teal', border: 'none', fontWeight: '500' }}>Construction Update</Button><span style={{ fontSize: '12px' }}><BiCalendar /> 06 September, 2022</span>
                        </div>
                    </Col>
                    
                </Row>
            </Row>
            {/* <h3 style={{ fontSize: '22px', marginTop: '30px',fontWeight:'semibold' ,marginLeft:'25px'}}>Categories</h3>
                  <ul className="ml-5">
                    <li>Architecture</li>
                    <li>New look 2021</li>
                    <li>Gadgets</li>
                    <li>Mobile and Phones</li>
                    <li>Recipes</li>
                    <li>Decorating</li>
                    <li>Interiors</li>
                    <li>Street fashion</li>
                    <li>Lifestyle</li>
                  </ul> */}
        </div>
       
    </>)
}
export default Construction;