import React from 'react';
// import { HiCamera } from "react-icons/hi"
import { useState } from "react";
// import {  Link } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import { Row, Col } from "react-bootstrap";
import Modal from 'react-bootstrap/Modal';
import SignatureModal from './SignatureModal';

export default function MemberProfile() {

const [show, setShow] = useState(false);

  return (
    <>
        <main>
            <div className='main__container'>
                <div className="container" style={{fontFamily:'sans-serif' ,fontWeight:'semibold'}}>
                    <form>
                        <div className="row">
                            <h4 className="text-center" style={{ fontFamily:'sans-serif', "color": "#13547A" ,"fontSize":'24px' , fontWeight: 600  }}>
                                Profile Page
                            </h4>
                            <div className="text-center">
                                {
                                    /* {image !== "" ? <img
                                    alt="profile"
                                    src={image}
                                    className="img-fluid mt-3 rounded-circle "
                                    style={{ width: "100px", height: "100px" }}
                                /> : */
                                    <img
                                    alt="profile"
                                    src={require("../../InnerComponets/assets/images/profilee.jpg")}
                                    className="img-fluid rounded-circle "
                                    style={{ width: "100px", height: "100px" }}
                                    />
                                }
                                <input type="file" accept="image/*" className='d-none' name="image-upload" id="input"
                                    // onChange={imageHandler} 
                                    />
                                {/* <div className="label">
                                    <label className="image-upload font-bold mb-3" htmlFor="input">
                                    <span className='camicon'><HiCamera /></span></label>
                                </div> */}
                            </div>  
                        </div>
                            <div className="row">
                            <div className="col-sm mt-3 mr-3">
                                <span className='form-text ml-3' style={{ fontFamily:'sans-serif', "color": "#000000" , fontWeight: 400 ,"fontSize":'16px' }}>Name of the Applicant</span>
                                        <input
                                        type="text"
                                        name="first_name"
                                        className="form-control py-3 mt-1"
                                        aria-label="Username"
                                        aria-describedby="basic-addon1"
                                        style={{ borderRadius: "30px", background: "#ffffff"}}
                                        defaultValue="Ramesh Sharma"
                                        disabled
                                    />
                            </div>
                            <div className="col-sm mt-3 mr-3">
                                <span className='form-text ml-3' style={{ fontFamily:'sans-serif', "color": "#000000" , fontWeight: 400 ,"fontSize":'16px' }}>Father’s / Husband’s Name</span>
                                    <input
                                        style={{ borderRadius: "30px", background: "#ffffff"}}
                                        defaultValue="Bhupendra Sharam"
                                        disabled
                                        type="text"
                                        name="first_name"
                                        className="form-control py-3 mt-1 "
                                        aria-label="Username"
                                        aria-describedby="basic-addon1"
                                    />
                            </div>
                            <div className="w-100"></div>
                            <div className="col-sm mt-3 mr-3">
                                <span className='form-text ml-3' style={{ fontFamily:'sans-serif', "color": "#000000" , fontWeight: 400 ,"fontSize":'16px' }}>Permanent Address</span>
                                    <input
                                        style={{ borderRadius: "30px", background: "#ffffff"}}
                                        defaultValue="17 Mangal Nagar, Indore, Madhay Pradesh, India Pin-451010 "
                                        disabled
                                        type="text"
                                        name="first_name"
                                        className="form-control py-3 mt-1 "
                                        aria-label="Username"
                                        aria-describedby="basic-addon1"
                                    />
                            </div>
                            <div className="col-sm mt-3 mr-3">
                                <span className='form-text ml-3' style={{ fontFamily:'sans-serif', "color": "#000000" , fontWeight: 400 ,"fontSize":'16px' }}>Correspondence Address</span>
                                    <input
                                        style={{ borderRadius: "30px", background: "#ffffff"}}
                                        defaultValue="17 Mangal Nagar, Indore, Madhay Pradesh, India Pin-451010 "
                                        disabled
                                        type="text"
                                        name="first_name"
                                        className="form-control py-3 mt-1 "
                                        aria-label="Username"
                                        aria-describedby="basic-addon1"
                                    />
                            </div>
                            <div className="w-100"></div>
                            <div className="col-sm mt-3 mr-3">
                                <span className='form-text ml-3' style={{ fontFamily:'sans-serif', "color": "#000000" , fontWeight: 400 ,"fontSize":'16px' }}>Phone Number With Code</span>
                                    <input
                                        style={{ borderRadius: "30px", background: "#ffffff"}}
                                        defaultValue="+91 888888888"
                                        disabled
                                        type="text"
                                        name="first_name"
                                        className="form-control py-3 mt-1 "
                                        aria-label="Username"
                                        aria-describedby="basic-addon1"
                                    />
                            </div>
                            <div className="col-sm mt-3 mr-3">
                                <span className='form-text ml-3' style={{ fontFamily:'sans-serif', "color": "#000000" , fontWeight: 400 ,"fontSize":'16px' }}>Date Of Birth</span>
                                    <input
                                        style={{ borderRadius: "30px", background: "#ffffff"}}
                                        defaultValue="06/08/1986"
                                        disabled
                                        type="text"
                                        name="first_name"
                                        className="form-control py-3 mt-1 "
                                        aria-label="Username"
                                        aria-describedby="basic-addon1"
                                    />
                            </div>
                            <div className="w-100"></div>
                            <div className="col-sm mt-3 mr-3">
                                <span className='form-text ml-3' style={{ fontFamily:'sans-serif', "color": "#000000" , fontWeight: 400 ,"fontSize":'16px' }}>Pan number</span>
                                    <input
                                        style={{ borderRadius: "30px", background: "#ffffff"}}
                                        defaultValue="CCCDD32232"
                                        disabled
                                        type="text"
                                        name="first_name"
                                        className="form-control py-3 mt-1 "
                                        aria-label="Username"
                                        aria-describedby="basic-addon1"
                                    />
                            </div>
                            <div className="col-sm mt-3 mr-3">
                                <span className='form-text ml-3' style={{ fontFamily:'sans-serif', "color": "#000000" , fontWeight: 400 ,"fontSize":'16px' }}>Email ID</span>
                                    <input
                                        style={{ borderRadius: "30px", background: "#ffffff"}}
                                        defaultValue="ramesh@gmail.com"
                                        disabled
                                        type="text"
                                        name="first_name"
                                        className="form-control py-3 mt-1 "
                                        aria-label="Username"
                                        aria-describedby="basic-addon1"
                                    />
                            </div>
                            <div className="w-100"></div>
                            <div className="col-sm mt-3 mr-3">
                                <span className='form-text ml-3' style={{ fontFamily:'sans-serif', "color": "#000000" , fontWeight: 400 ,"fontSize":'16px' }}>Unit Number</span>
                                    <input
                                        style={{ borderRadius: "30px", background: "#ffffff"}}
                                        defaultValue="A-354"
                                        disabled
                                        type="text"
                                        name="first_name"
                                        className="form-control py-3 mt-1 "
                                        aria-label="Username"
                                        aria-describedby="basic-addon1"
                                    />
                            </div>
                            <div className="col-sm mt-3 mr-3">
                                <span className='form-text ml-3' style={{ fontFamily:'sans-serif', "color": "#000000" , fontWeight: 400 ,"fontSize":'16px' }}>Occupation</span>
                                    <input
                                        style={{ borderRadius: "30px", background: "#ffffff"}}
                                        defaultValue="Businessmen"
                                        disabled
                                        type="text"
                                        name="first_name"
                                        className="form-control py-3 mt-1 "
                                        aria-label="Username"
                                        aria-describedby="basic-addon1"
                                    />
                            </div>
                                <div className="w-100"></div>
                                <div className="col-sm mt-3 mr-3">
                                <span className='form-text ml-3' style={{ fontFamily:'sans-serif', "color": "#000000" , fontWeight: 400 ,"fontSize":'16px' }}>Date</span>
                                    <input
                                        style={{ borderRadius: "30px", background: "#ffffff"}}
                                        defaultValue="29/09/2023"
                                        disabled
                                        type="text"
                                        name="first_name"
                                        className="form-control py-3 mt-1 "
                                        aria-label="Username"
                                        aria-describedby="basic-addon1"
                                    />
                            </div>
                            <div className="col-sm mt-3 mr-3">
                                <span className='form-text ml-3' style={{ fontFamily:'sans-serif', "color": "#000000" , fontWeight: 400 ,"fontSize":'16px' }}>Place</span>
                                    <input
                                        style={{ borderRadius: "30px", background: "#ffffff"}}
                                        defaultValue="Indore"
                                        disabled
                                        type="text"
                                        name="first_name"
                                        className="form-control py-3 mt-1 "
                                        aria-label="Username"
                                        aria-describedby="basic-addon1"
                                    />
                            </div>
                            <div className="w-100"></div>
                            <div className="col-sm mt-3 mr-3">
                                <span className='form-text ml-3' style={{ fontFamily:'sans-serif', "color": "#000000" , fontWeight: 400 ,"fontSize":'16px' }}>Owner Type</span><br />
                                  <input className='mt-3' type="radio" id="single" name="owner_type" value="single" />
                                  <label for="html">Single</label>
                                  <input type="radio" id="joint" name="owner_type" value="joint" />
                                  <label for="css">Joint</label><br />
                            </div>
                            <div className="col-sm"></div>
                            <div className="w-100"></div>
                            <span className='form-text mt-3 ' style={{ fontFamily: 'sans-serif', "color": "#13547A", fontWeight: 600, "fontSize": '18px' }}>Joint Owner Brief Particulars are as under</span>
                            <div className="col-sm mt-3 mr-3">
                                <span className='form-text ml-3' style={{ fontFamily:'sans-serif', "color": "#000000" , fontWeight: 400 ,"fontSize":'16px' }}>Name of the Applicant</span>
                                        <input
                                        type="text"
                                        name="first_name"
                                        className="form-control py-3 mt-1"
                                        aria-label="Username"
                                        aria-describedby="basic-addon1"
                                        style={{ borderRadius: "30px", background: "#ffffff"}}
                                        defaultValue="Ramesh Sharma"
                                        disabled
                                    />
                            </div>
                            <div className="col-sm mt-3 mr-3">
                                <span className='form-text ml-3' style={{ fontFamily:'sans-serif', "color": "#000000" , fontWeight: 400 ,"fontSize":'16px' }}>Father’s / Husband’s Name</span>
                                    <input
                                        style={{ borderRadius: "30px", background: "#ffffff"}}
                                        defaultValue="Bhupendra Sharam"
                                        disabled
                                        type="text"
                                        name="first_name"
                                        className="form-control py-3 mt-1 "
                                        aria-label="Username"
                                        aria-describedby="basic-addon1"
                                    />
                            </div>
                            <div className="w-100"></div>
                            <div className="col-sm mt-3 mr-3">
                                <span className='form-text ml-3' style={{ fontFamily:'sans-serif', "color": "#000000" , fontWeight: 400 ,"fontSize":'16px' }}>Permanent Address</span>
                                    <input
                                        style={{ borderRadius: "30px", background: "#ffffff"}}
                                        defaultValue="17 Mangal Nagar, Indore, Madhay Pradesh, India Pin-451010 "
                                        disabled
                                        type="text"
                                        name="first_name"
                                        className="form-control py-3 mt-1 "
                                        aria-label="Username"
                                        aria-describedby="basic-addon1"
                                    />
                            </div>
                            <div className="col-sm mt-3 mr-3">
                                <span className='form-text ml-3' style={{ fontFamily:'sans-serif', "color": "#000000" , fontWeight: 400 ,"fontSize":'16px' }}>Correspondence Address</span>
                                    <input
                                        style={{ borderRadius: "30px", background: "#ffffff"}}
                                        defaultValue="17 Mangal Nagar, Indore, Madhay Pradesh, India Pin-451010 "
                                        disabled
                                        type="text"
                                        name="first_name"
                                        className="form-control py-3 mt-1 "
                                        aria-label="Username"
                                        aria-describedby="basic-addon1"
                                    />
                            </div>
                            <div className="w-100"></div>
                            <div className="col-sm mt-3 mr-3">
                                <span className='form-text ml-3' style={{ fontFamily:'sans-serif', "color": "#000000" , fontWeight: 400 ,"fontSize":'16px' }}>Phone Number With Code</span>
                                    <input
                                        style={{ borderRadius: "30px", background: "#ffffff"}}
                                        defaultValue="+91 888888888"
                                        disabled
                                        type="text"
                                        name="first_name"
                                        className="form-control py-3 mt-1 "
                                        aria-label="Username"
                                        aria-describedby="basic-addon1"
                                    />
                            </div>
                            <div className="col-sm mt-3 mr-3">
                                <span className='form-text ml-3' style={{ fontFamily:'sans-serif', "color": "#000000" , fontWeight: 400 ,"fontSize":'16px' }}>Date Of Birth</span>
                                    <input
                                        style={{ borderRadius: "30px", background: "#ffffff"}}
                                        defaultValue="06/08/1986"
                                        disabled
                                        type="text"
                                        name="first_name"
                                        className="form-control py-3 mt-1 "
                                        aria-label="Username"
                                        aria-describedby="basic-addon1"
                                    />
                            </div>
                            <div className="w-100"></div>
                            <div className="col-sm mt-3 mr-3">
                                <span className='form-text ml-3' style={{ fontFamily:'sans-serif', "color": "#000000" , fontWeight: 400 ,"fontSize":'16px' }}>Occupation</span>
                                <input
                                        style={{ borderRadius: "30px", background: "#ffffff"}}
                                        defaultValue="Businessmen"
                                        disabled
                                        type="text"
                                        name="first_name"
                                        className="form-control py-3 mt-1 "
                                        aria-label="Username"
                                        aria-describedby="basic-addon1"
                                    />
                            </div>
                            <div className="col-sm mt-3 mr-3">
                                <span className='form-text ml-3' style={{ fontFamily:'sans-serif', "color": "#000000" , fontWeight: 400 ,"fontSize":'16px' }}>Email ID</span>
                                    <input
                                        style={{ borderRadius: "30px", background: "#ffffff"}}
                                        defaultValue="ramesh@gmail.com"
                                        disabled
                                        type="text"
                                        name="first_name"
                                        className="form-control py-3 mt-1 "
                                        aria-label="Username"
                                        aria-describedby="basic-addon1"
                                    />
                            </div>
                            <div className="w-100"></div>
                                    <span className='form-text mt-3 ' style={{ fontFamily: 'sans-serif', "color": "#13547A", fontWeight: 600, "fontSize": '18px' }}>Second Joint Owner Brief Particulars are as under</span>
                                    <div className="col-sm mt-3 mr-3">
                                <span className='form-text ml-3' style={{ fontFamily:'sans-serif', "color": "#000000" , fontWeight: 400 ,"fontSize":'16px' }}>Name of the Applicant</span>
                                        <input
                                        type="text"
                                        name="first_name"
                                        className="form-control py-3 mt-1"
                                        aria-label="Username"
                                        aria-describedby="basic-addon1"
                                        style={{ borderRadius: "30px", background: "#ffffff"}}
                                        defaultValue="Ramesh Sharma"
                                        disabled
                                    />
                            </div>
                            <div className="col-sm mt-3 mr-3">
                                <span className='form-text ml-3' style={{ fontFamily:'sans-serif', "color": "#000000" , fontWeight: 400 ,"fontSize":'16px' }}>Father’s / Husband’s Name</span>
                                    <input
                                        style={{ borderRadius: "30px", background: "#ffffff"}}
                                        defaultValue="Bhupendra Sharam"
                                        disabled
                                        type="text"
                                        name="first_name"
                                        className="form-control py-3 mt-1 "
                                        aria-label="Username"
                                        aria-describedby="basic-addon1"
                                    />
                            </div>
                            <div className="w-100"></div>
                            <div className="col-sm mt-3 mr-3">
                                <span className='form-text ml-3' style={{ fontFamily:'sans-serif', "color": "#000000" , fontWeight: 400 ,"fontSize":'16px' }}>Permanent Address</span>
                                    <input
                                        style={{ borderRadius: "30px", background: "#ffffff"}}
                                        defaultValue="17 Mangal Nagar, Indore, Madhay Pradesh, India Pin-451010 "
                                        disabled
                                        type="text"
                                        name="first_name"
                                        className="form-control py-3 mt-1 "
                                        aria-label="Username"
                                        aria-describedby="basic-addon1"
                                    />
                            </div>
                            <div className="col-sm mt-3 mr-3">
                                <span className='form-text ml-3' style={{ fontFamily:'sans-serif', "color": "#000000" , fontWeight: 400 ,"fontSize":'16px' }}>Correspondence Address</span>
                                    <input
                                        style={{ borderRadius: "30px", background: "#ffffff"}}
                                        defaultValue="17 Mangal Nagar, Indore, Madhay Pradesh, India Pin-451010 "
                                        disabled
                                        type="text"
                                        name="first_name"
                                        className="form-control py-3 mt-1 "
                                        aria-label="Username"
                                        aria-describedby="basic-addon1"
                                    />
                            </div>
                            <div className="w-100"></div>
                        
                            <div className="col-sm mt-3 mr-3">
                                <span className='form-text ml-3' style={{ fontFamily:'sans-serif', "color": "#000000" , fontWeight: 400 ,"fontSize":'16px' }}>Phone Number With Code</span>
                                    <input
                                        style={{ borderRadius: "30px", background: "#ffffff"}}
                                        defaultValue="+91 888888888"
                                        disabled
                                        type="text"
                                        name="first_name"
                                        className="form-control py-3 mt-1 "
                                        aria-label="Username"
                                        aria-describedby="basic-addon1"
                                    />
                            </div>
                            <div className="col-sm mt-3 mr-3">
                                <span className='form-text ml-3' style={{ fontFamily:'sans-serif', "color": "#000000" , fontWeight: 400 ,"fontSize":'16px' }}>Date Of Birth</span>
                                    <input
                                        style={{ borderRadius: "30px", background: "#ffffff"}}
                                        defaultValue="06/08/1986"
                                        disabled
                                        type="text"
                                        name="first_name"
                                        className="form-control py-3 mt-1 "
                                        aria-label="Username"
                                        aria-describedby="basic-addon1"
                                    />
                            </div>
                            <div className="w-100"></div>
                            <div className="col-sm mt-3 mr-3">
                                <span className='form-text ml-3' style={{ fontFamily:'sans-serif', "color": "#000000" , fontWeight: 400 ,"fontSize":'16px' }}>Occupation</span>
                                <input
                                        style={{ borderRadius: "30px", background: "#ffffff"}}
                                        defaultValue="Businessmen"
                                        disabled
                                        type="text"
                                        name="first_name"
                                        className="form-control py-3 mt-1 "
                                        aria-label="Username"
                                        aria-describedby="basic-addon1"
                                    />
                            </div>
                            <div className="col-sm mt-3 mr-3">
                                <span className='form-text ml-3' style={{ fontFamily:'sans-serif', "color": "#000000" , fontWeight: 400 ,"fontSize":'16px' }}>Email ID</span>
                                    <input
                                        style={{ borderRadius: "30px", background: "#ffffff"}}
                                        defaultValue="ramesh@gmail.com"
                                        disabled
                                        type="text"
                                        name="first_name"
                                        className="form-control py-3 mt-1 "
                                        aria-label="Username"
                                        aria-describedby="basic-addon1"
                                    />
                            </div>
                            <div className="w-100 mb-3"></div>
                            </div>                  
                            <span className='form-text mt-3' style={{ fontFamily: 'sans-serif', "color": "#13547A", fontWeight: 600, "fontSize": '18px' }}>Member Document</span>                    
                            <Row className="col-12 p-0 mt-2 text-center">
                                    <Col lg={3} className="mt-3 mb-3">
                                    <p className='profile_detail_heading'>Member Signature</p>
                                        <Button className="cardviewbtn" onClick={() => { setShow(true)}}>
                                            View
                                        </Button>
                                    </Col>                
                                    <Col lg={3} className="mt-3 mb-3">
                                    <p className='profile_detail_heading'>Proof Of Identity</p>
                                        <Button className="cardviewbtn" onClick={() => { setShow(true)}}>
                                            View
                                        </Button>
                                    </Col>   
                                    <Col lg={3} className="mt-3 mb-3">
                                    <p className='profile_detail_heading'>Proof Of Address</p>
                                        <Button className="cardviewbtn" onClick={() => { setShow(true)}}>
                                            View
                                        </Button>
                                    </Col>
                                    <Col lg={3} className="mt-3 mb-3">
                                    <p className='profile_detail_heading'>Proof Of Date Of Birth</p>
                                        <Button className="cardviewbtn" onClick={() => { setShow(true)}}>
                                            View
                                        </Button>
                                    </Col>                      
                            </Row>               
                            <Row className="col-12 p-0 mt-2 text-center">
                                    <Col lg={3} className="mt-3 mb-3">
                                    <p className='profile_detail_heading'>Proof Of Ownership</p>
                                        <Button className="cardviewbtn" onClick={() => { setShow(true)}}>
                                            View
                                        </Button>
                                    </Col>                
                                    <Col lg={3} className="mt-3 mb-3">
                                    <p className='profile_detail_heading'>Proof Of Payment</p>
                                        <Button className="cardviewbtn" onClick={() => { setShow(true)}}>
                                            View
                                        </Button>
                                    </Col>   
                                    <Col lg={3} className="mt-3 mb-3">
                                    <p className='profile_detail_heading'>Proof Of Joint Owner 1</p>
                                        <Button className="cardviewbtn" onClick={() => { setShow(true)}}>
                                            View
                                        </Button>
                                    </Col>
                                    <Col lg={3} className="mt-3 mb-3">
                                    <p className='profile_detail_heading'>Proof Of Joint Owner 2</p>
                                        <Button className="cardviewbtn" onClick={() => { setShow(true)}}>
                                            View
                                        </Button>
                                    </Col>                      
                            </Row>
                            <Row className="col-12 p-0 mt-2 text-center justify-content-center ">
                                    <Col lg={3} className="mt-3 mb-3">
                                    <p className='profile_detail_heading'>NOC Joint Owner 1</p>
                                        <Button className="cardviewbtn" onClick={() => { setShow(true)}}>
                                            View
                                        </Button>
                                    </Col>                
                                    <Col lg={3} className="mt-3 mb-3">
                                    <p className='profile_detail_heading'>NOC Joint Owner 2</p>
                                        <Button className="cardviewbtn" onClick={() => { setShow(true)}}>
                                            View
                                    </Button>
                                </Col>                    
                            </Row>
                    </form>
                </div>
                </div>
                <Modal show={show} onHide={() => { setShow(false) }} className="mt-5">
                    <Modal.Header closeButton style={{padding:'3px', background:'#13547A', paddingRight:'15px'}}>
                        <Modal.Title style={{fontSize:'20px', marginLeft:'5px', fontFamily:'sans-serif', color:'#ffffff'}}>
                            View Signature
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body >
                        <SignatureModal closeModal={setShow}/>
                    </Modal.Body>
                </Modal>
        </main>
    </>
  );
}
