import React from 'react'
import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const Concerns = () => {
    return (
        <div>
            <div className='contact-image'>
                <Row className="mt-8">
                    <Col className='text-center ' style={{ marginTop: '20px' }}>
                    <nav aria-label="breadcrumb" >
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/home" className="text-500 text-base font-semibold" style={{ textDecoration: "none" }}>Home</Link></li>
                                <li className="breadcrumb-item active" aria-current="page" ><Link to="/concerns" className="text-teal-600  text-base font-semibold" style={{ textDecoration: "none" }}>Concerns with the project</Link></li>
                            </ol>
                        </nav>
                        <span className="" style={{ color: '#1a2141', fontSize: '30px', fontWeight: 700, textTransform: 'uppercase', lineHeight: 1.2, marginTop: '100px' }}>Concerns with the project</span>
                    </Col>
                    <div data-aos="fade-up" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="600">
                        <Row lg={12} className='d-flex justify-content-start' >
                            <Col className="d-flex flex-column justify-content-start mt-4 m-3 " >
                                <p style={{ fontSize: '17px', color: '#777777', fontStyle: 'sans-serif' }}>
                                    The Raheja Revanta (RR) project which included the high-rise Surya Towers and low-rise Tapas Townhouses was launched in 2011 in Sector-78, Gurugram.
                                    It was touted as a mega luxury project with names like Arabtec being associated with it. However, the project was grossly mismanaged, leading to several
                                    irregularities creeping in and resulting in project delays.
                                </p>
                                <p style={{ fontSize: '17px', color: '#777777', fontStyle: 'sans-serif' }}>
                                    9 years later the project is still UNDER CONSTRUCTION and looks nowhere close to completion in the foreseeable future.
                                    During this time period, 90% of the consideration amount was siphoned off from the buyers’ pockets by the builders
                                    based on factually incorrect assurances.</p>

                                <p style={{ fontSize: '17px', color: '#777777', fontStyle: 'sans-serif' }}>In view of all the irregularities and issues pertaining to the project, the buyers of the Raheja Revanta project
                                    have come together to form an association and seek legal recourse against the errant builder via available routes
                                    like EOW, NCLT, NCDRC, RERA, etc.</p>
                                <p style={{ fontSize: '17px', color: '#777777', fontStyle: 'sans-serif' }}>
                                    Here is a comprehensive list of issues/irregularities faced by the project:</p>
                                {/* ......section A...... */}
                                <h3 style={{ fontSize: '17px', color: '#696969', marginTop: '30px' }}>(A) Project License / Approvals and Sales</h3>
                                <ol style={{ fontSize: '17px', color: '#777777', fontStyle: 'sans-serif', marginTop: '17px' }}>
                                    <li> a) The BBA’s (Builder Buyer Agreement) was signed several months after accepting payments towards bookings.</li>
                                    <li className='mt-2'>  b) Environment clearance for the project was granted much later via SEIAA/HR/2013/1075 dated 23/10/2013.</li>
                                    <li className='mt-2'>c) The BBA assured handing over possession within 36 months (for Tapas) and 48 months (for Surya) from the date of its execution.
                                        The signing of BBA and starting of construction itself were, unreasonably, and possibly deliberately, delayed.</li>
                                    <li className='mt-2'>d) Despite the delays in the signing of the Builder Buyer Agreement, procuring the requisite approvals and Environmental clearance,
                                        the builders, apparently, continued selling the apartments to customers. Under normal circumstances, the construction should have
                                        started within 6 months of booking of the property.</li>
                                    <li className='mt-2'>e) It also came to our notice via DTCP Memo No. LC-1140-JE(VA)-2016/11800 that the recent application for renewal of license was refused
                                        on grounds of various anomalies, like failure to deposit the EDC due, etc. on the part of the builders. NO communication regarding this
                                        was sent across to the buyers.</li>
                                </ol>

                                <p style={{ fontSize: '17px', color: '#777777', fontStyle: 'sans-serif', marginTop: '25px' }}>
                                    [AC]: What license in point (e)? License to proceed with the construction?
                                </p>
                                {/* ......section B...... */}
                                <h3 style={{ fontSize: '17px', color: '#696969', marginTop: '30px' }}>
                                    (B) Misleading Customers/Buyers with Fraudulent Intent and Breach
                                </h3>
                                <ol style={{ fontSize: '17px', color: '#777777', fontStyle: 'sans-serif', marginTop: '17px' }}>
                                    <li>a) In order to attract buyers, the marketing and sales brochures for the project described it as an ‘ultra-luxury project’
                                        located in the heart of Gurugram with direct access to NH-8. One of the highlights of the project was having Arabtec
                                        (a top UAE-based company) on board as the construction partner. RAHEJA promised to use the latest state-of-the-art
                                        construction techniques to achieve a 7-day floor cycle. Clearly, the advertised level of construction progress was never
                                        achieved as is evident from the state of the project as it stands today.</li>
                                    <li className='mt-5'>b) Another communication floated by the builders stated that the ‘ultra luxury’ project would have luxury club
                                        houses and spa managed by Amatrra. The partnership with Amatrra was subsequently canceled by RAHEJA
                                        once the buyers had signed their BBAs, again raising doubts over their intention.
                                        Also, this was done without informing the buyers. NO substitute luxury spa provider of equal standing
                                        as Amatrra was appointed. The existing buyers were NOT compensated for the loss in value of the project in
                                        any way whatsoever.
                                    </li>
                                    <li className='mt-5'>c) RAHEJA committed that the project would be ready for delivery in 36 months for Tapas and 48 months for Surya Towers.
                                        However, there was a catch. This ‘delivery’ date was deliberately kept variable (depending on the date of execution of
                                        BBAs for each individual buyer).
                                    </li>
                                    <li className='mt-5'>d) After entering into the Builder Buyer Agreement, RAHEJA acquired a dominant position over the buyers owing to
                                        the stringent terms and conditions of the agreement. The binding terms and conditions were designed to favour the
                                        builder and victimize the buyers who suffered due to the project delay beyond a reasonable time frame.
                                    </li>
                                    <li className='mt-5'>e) Milestone demands were raised by RAHEJA ahead of the construction stages being achieved. Dates were specified
                                        for making payments without any qualified or audited evidence of construction in violation of the statutory evidence
                                        and payment norms of the land as well as against the spirit of fair services. Subsequently, several instances came
                                        to notice when demands were rescheduled and postponed based on specific objections raised by some flat buyers
                                        (while RAHEJA continued ignoring to inform all the buyers who had advanced their payments).
                                    </li>
                                    <li className='mt-5'>f) It appears that there have been material changes in the planning of the entire project accompanied by several
                                        deviations from the original project plan. There has been an increase in the number of floors in the Surya tower,
                                        changes to common facilities like the clubhouse, building elevations, etc. Such material changes have been effected
                                        without any prior intimation to the buyers and have come to our notice after pursuing the sales brochure of RAHEJA,
                                        made available through various marketing channels. Such material changes, including an increase in floors in the Surya tower, have been done without compensating existing buyers for reduction in super area and higher utilization of common areas.</li>
                                </ol>
                                {/* ......section C...... */}
                                <h3 style={{ fontSize: '17px', color: '#696969', marginTop: '30px' }}>(C) Execution of Project and its Delays / Servicing Demands</h3>
                                <ol style={{ fontSize: '17px', color: '#777777', fontStyle: 'sans-serif', marginTop: '17px' }}>
                                    <li>a) Unreasonable delays in execution and completion of the project and handing over possessions to Buyers have resulted in
                                        unwarranted financial losses and duress for Buyers.</li>
                                    <li className='mt-3'>b) The communication from the builder regarding delays has been suspect as no justifiable reasons for delays have been conveyed.
                                        The construction updates were shared monthly in the initial phase of the project through unverified photographs.
                                        The practice which didn’t qualify for an audit trail was discontinued later citing logistical issues. RAHEJA continues to
                                        sporadically share photographs as ‘construction update’ to advance delivery dates or raise fresh demands from new buyers.</li>
                                    <li className='mt-3'>c) Banks were compelled to make disbursements of the raised demands based solely on the verbal assurances of RAHEJA due
                                        to the lack of mandatory physical verification and authorized/qualified technical reports.</li>
                                    <li className='mt-3'>d) Given the fact that the Environment clearance was received only in Oct 2013 it is apparent that the construction of
                                        structures could not have continued, legally. Thus, the demands raised during the period were not as per the actual stages of construction,
                                        but based on projected stages of completion.</li>
                                    <li className='mt-3'>e) There is ambiguity in the BBA as it does not specify the number of floors in the Surya towers. It is, therefore, clear that
                                        the definitions in the agreement do not communicate the correct sense and context of the terms such as stages of constructions, etc.</li>
                                </ol>
                                <h3 style={{ fontSize: '17px', color: '#777777', marginTop: '30px' }}>(D) Other Irregularities and Observations</h3>
                                <ol style={{fontSize: '17px', color: '#777777', fontStyle: 'sans-serif', marginTop: '17px'}}>
                                    <li>a) According to a recent verdict given by the high court, service tax is not applicable to ‘under construction projects and the same must be refunded to buyers with appropriate interest.</li>
                                    <p style={{ marginTop: "10px" }}>There has been no clarity from RAHEJA on this.</p>
                                    <li>b) RAHEJA has charged for 1-2 parking slots plus service tax, thereon, which is otherwise not legally allowed to be charged as has been ruled by courts in India and confirmed by the
                                        Hon’ble Supreme Court of India.</li>
                                </ol>
                                {/* ......section D...... */}
                                <h3 style={{ fontSize: '17px', color: '#696969', marginTop: '30px' }}>Why we are here</h3>
                                <p style={{ fontSize: '17px', color: '#777777', fontStyle: 'sans-serif', marginTop: '10px' }}>
                                    Forming an association by interested buyers gives us an ability to represent in RERA and insight into builder actions and
                                    financial situation, which is becoming increasingly important as the construction is in its 9th year and keeps getting delayed.</p>
                                <p style={{ fontSize: '17px', color: '#777777', fontStyle: 'sans-serif', marginTop: '10px' }}>
                                    Furthermore, to fight a legal battle at various forums, the leverage that each of us gets from an association
                                    (irrespective of earlier legal recourse)</p>
                                <ol style={{ fontSize: '17px', color: '#777777', fontStyle: 'sans-serif', marginTop: '10px' }}>
                                    <li>Numbers help negotiate the lawyer fee even if the cases are filed individually.
                                        This will allow us to file individually in RERA and NCRDC afresh. Builder will likely
                                        find it difficult to influence every case, judge, and lawyer.</li>
                                    <li className='mt-1'>An association is a legal show of strength and with time, we will have many more members
                                        and those members will entrust an association rather than an individual or an informal WHATSAPP group</li>
                                    <li className='mt-1'>An association puts immense pressure on the builder, as not only it can facilitate legal action against
                                        NCRDC as individuals, it can represent the members in Supreme Court, RERA and NCLT as well.</li>
                                    <li className='mt-1'>An association can divide responsibilities and work like a corporation in a cohesive manner and take up projects like
                                        supervision, media, legal, finance, etc. the burden of which has been on just a few individuals. Updates and
                                        history are more readily available on a website.</li>
                                </ol>
                                <p style={{ fontSize: '17px', color: '#777777', fontStyle: 'sans-serif', marginTop: '10px' }}>
                                There are many more advantages of an association which you will be made aware of soon by the core committee.
                                 So it helps everyone equally rather than favoring a few individuals who have taken their own legal actions in their own capacities.</p>




                            </Col>
                        </Row>
                    </div>
                </Row>
            </div>
        </div>
    )
}

export default Concerns
