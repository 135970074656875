import React  from 'react';
import { Col, Container, Row, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import { IoLocationSharp } from "react-icons/io5";
import { GoMail } from "react-icons/go";
import { FiPhoneCall } from "react-icons/fi";
import Form from 'react-bootstrap/Form';
import '../style.css'
import 'react-phone-number-input/style.css'
import Input from 'react-phone-number-input'
import { useState } from 'react';

function Contact() {

    const [value, setValue] = useState();

    return (
        <>
            <div>
                <div className='contact-image'>
                    <Row className="mt-8">
                        <Col className='text-center ' style={{ marginTop: '20px' }}>
                            <nav aria-label="breadcrumb" >
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><Link to="/home" className="text-500 text-base font-semibold" style={{ textDecoration: "none" }}>Home</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page" ><Link to="/contact" className="text-teal-600  text-base font-semibold" style={{ textDecoration: "none" }}>Contact</Link></li>
                                </ol>
                            </nav>
                            <span className="" style={{ color: '#1a2141', fontSize: '30px', fontWeight: 700, textTransform: 'uppercase', lineHeight: 1.2, marginTop: '100px' }}>RGFBA CONTACT DETAILS</span>
                        </Col>
                    </Row>
                </div>
            </div>

            <Container className='mt-5' data-aos="zoom-in" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="600" >
                <div className='d-flex justify-content-center mt-5'>
                    <Row >
                        <Col className='d-flex justify-content-center' >
                            <Card className='zoom-hov' style={{ width: '18rem', margin: '10px', boxShadow: '1px 2px 9px 0px #808080db' }} data-aos="fade-down" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="600">
                                <div className='d-flex justify-content-center mt-3'>
                                    <IoLocationSharp style={{ color: '#ff5f13', fontSize: '50px', border: '4px dotted #ff8d57', borderRadius: '50%', padding: '8px' }}></IoLocationSharp>
                                </div>
                                <Card.Body className='text-center'>
                                    <Card.Title>Our Address</Card.Title>
                                    <Card.Text>
                                        Flat No. 801, Tower 5, Orchid Petals, Sohna Road, Sector 49, Gurgaon, Haryana -122 018
                                    </Card.Text>

                                </Card.Body>
                            </Card>
                        </Col>
                        <Col className='d-flex justify-content-center' >
                            <Card className='zoom-hov' style={{ width: '18rem', margin: '10px', boxShadow: '1px 2px 9px 0px #808080db' }} data-aos="fade-down" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="600">
                                <div className='d-flex justify-content-center mt-3'>
                                    <GoMail style={{ color: '#ff5f13', fontSize: '50px', border: '4px dotted #ff8d57', borderRadius: '50%', padding: '8px' }} />
                                </div>

                                <Card.Body className='text-center'>
                                    <Card.Title>Email Us</Card.Title>
                                    <Card.Text>
                                        rgfba.main@gmail.com
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col className='d-flex justify-content-center' >
                            <Card className='zoom-hov' style={{ width: '18rem', margin: '10px', boxShadow: '1px 2px 9px 0px #808080db' }} data-aos="fade-down" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="600">
                                <div className='d-flex justify-content-center mt-3'>
                                    <FiPhoneCall style={{ color: '#ff5f13', fontSize: '50px', border: '4px dotted #ff8d57', borderRadius: '50%', padding: '8px' }} />
                                </div>
                                <Card.Body className='text-center'>
                                    <Card.Title style={{ fontSize: '17px' }}>Call Us</Card.Title>
                                    <Card.Text>
                                        +91 8288855509
                                    </Card.Text>
                                    <Card.Title style={{ fontSize: '17px' }}>Registration No.</Card.Title>
                                    <Card.Text>
                                        HR/018/2021/02644
                                    </Card.Text>

                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </div>
                <div>
                    <Row className='mt-6' >
                        <Col lg={6} md={12}>
                            <iframe className="mb-4 me-md-3 me-sm-0  mb-lg-0" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14042.321709287922!2d76.977565!3d28.371532!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d3d1d6b646733%3A0x90f793327016c32b!2sRaheja%20Revanta%2C%20Sector%2078%2C%20Gurugram%2C%20Haryana%20122004%2C%20India!5e0!3m2!1sen!2sus!4v1672133737187!5m2!1sen!2sus" frameborder="0" style={{ border: "0", width: "100%", height: "530px", boxShadow: '1px 2px 9px 2px #808080db' }} allowfullscreen title="myFrame"></iframe>
                        </Col>
                        {/* <Col>
                        <Card className='p-3'>
                            <form>
                                <h4>Get in Touch</h4>
                                <lable style={{    color: "#7F7F7F",fontWeight:"500"}}>Full Name </lable>
                                <input className='form-control mt-2 p-2' type='text' placeholder='Enter your name'></input>
                                <lable style={{ color: "#7F7F7F", fontWeight: "500"}}>Email </lable>
                                <input className='form-control mt-2 p-2 ' type='email' placeholder='Enter your email'></input>
                                <lable style={{ color: "#7F7F7F", fontWeight: "500" }} >Phone Number :</lable>
                                <input className='form-control mt-2 p-2' type='number' placeholder='Enter your phone number'></input>
                                <lable style={{ color: "#7F7F7F", fontWeight: "500" }}>Message :</lable>
                                <textarea className='form-control mt-2 p-5' type='text-area' placeholder='Enter your Message'></textarea>
                                <Button style={{ background:"#ff5f13",border:"none"}}>Send Message</Button>
                            </form>
                        </Card>

                    </Col>
                */}
                        <Col lg={6} md={12}>
                            <Card className='ms-md-4 ms-sm-0' style={{ border: "0", width: "100%", height: "530px", boxShadow: '1px 2px 9px 2px #808080db' }}>
                                <Card.Body>
                                    <p style={{ fontWeight: 600 }}>Get in Touch & Let Us Know</p>
                                    <Form>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Full Name</Form.Label>
                                            <Form.Control required type="text" placeholder="Enter full name" style={{ borderLeftColor: 'grey', borderRightColor: 'grey', borderTopColor: 'grey', borderBottomColor: 'grey' }} />
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                            <Form.Label>Email address</Form.Label>
                                            <Form.Control required type="email" placeholder="Enter email" style={{ borderLeftColor: 'grey', borderRightColor: 'grey', borderTopColor: 'grey', borderBottomColor: 'grey' }} />
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="formBasicPassword">
                                            <Form.Label>Phone Number</Form.Label>
                                            {/* <Form.Control required type="tel" placeholder="Enter phone number" maxlength="10"  style={{borderLeftColor:'grey',borderRightColor:'grey',borderTopColor:'grey',borderBottomColor:'grey'}}/> */}
                                            <Input
                                            style={{lineHeight:'2.0'}}
                                            international
                                            defaultCountry="US"
                                            value={value}
                                            onChange={setValue}
                                             />
                                        </Form.Group>
                                        <Form.Group className="mb-3" >
                                            <Form.Label>Message</Form.Label>
                                            <textarea className='form-control p-4' placeholder='Enter message' style={{ borderLeftColor: 'grey', borderRightColor: 'grey', borderTopColor: 'grey', borderBottomColor: 'grey' }}></textarea>
                                        </Form.Group>
                                        <div className='d-flex justify-content-start'>
                                            <Button style={{ background: 'teal', border: "#ff5f13", paddingLeft: '30px', paddingRight: '30px', paddingTop: '14px', paddingBottom: '14px' }} type="submit">
                                                Submit
                                            </Button>
                                        </div>
                                    </Form>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </Container>
        </>
    )

}
export default Contact;