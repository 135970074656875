import React from 'react'
import {  Link } from "react-router-dom";

export default function PaymentPage() {

  return (
    <>
        <main>
        <div className='main__container'>
            <div className="container" style={{fontFamily:'sans-serif' ,fontWeight:'semibold'}}>
                <div className="row">
                    <h4 className="text-center" style={{ fontFamily:'sans-serif', "color": "#13547A" ,"fontSize":'24px' , fontWeight: 600  }}>
                        Payment Page
                    </h4>
                </div>
                <div className="row ">
                    <div className='col-12'>
                        <p className='mt-2 md-2 paragraph_style'>1. Pay the sum of Rs.5000/‐ drawn in favour of M/s Revanta Gurgaon Flat Buyers Association towards application fees. I understand that this fee is non refundable.</p>
                        <p className='mt-2 md-2 paragraph_style'>2. Pay the sum of Rs. 15000/‐ drawn in favour of M/s Revanta Gurgaon Flat Buyers Association towards annual subscription fees for the year 2020‐2021. I understand that this fee is refundable in case of rejection of membership.</p>
                    </div>
                </div>
                <div className='row d-flex justify-content-center'>
                    <div className="col-md-6 mt-3 mb-3">
                            <Link
                            to="/memberdocuments"
                            style={{ borderRadius: "30px" , "color": "#ffffff" }}
                            className="btn w-100 py-3 editbtnsubmit border-0"
                            type='submit' label='Submit'>
                            Pay & Next
                        </Link>
                    </div>
                </div>
            </div>
        </div>
        </main>
    </>
  );
}
