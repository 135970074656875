import React, { useEffect } from 'react';
// import { HiCamera } from "react-icons/hi"
import { useState } from "react";
import {  Link, useParams } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import { Row, Col } from "react-bootstrap";
import Modal from 'react-bootstrap/Modal';
import MemberDocViewModel from './MemberDocViewModel';
import AlterModel from './AlterModel';
import axios from "axios";

export default function MemberViewProfile() {
    
    const [show, setShow] = useState(false);
    const [show1, setShow1] = useState(false);
    let [memberDetails, setMemberDetails] = useState("")
    let [memberEmailDetails, setEmailMemberDetails] = useState("")

    let [memberFullDetails, setMemberFullDetails] = useState("")
    let [memberAddress, setMemberAddress] = useState("")
    let [memberCaddress, setMemberCaddress] = useState("")
    let [jointOwnerDetails, setJointOwnerDetails] = useState("")
    let [jopAddress, setJopAddress] = useState("")
    let [jocAddress, setJocAddress] = useState("")
    let [secondJointOwnerDetails, setSecondJointOwnerDetails] = useState("")
    let [sjopAddress, setSJopAddress] = useState("")
    let [sjocAddress, setSJocAddress] = useState("")
    const searchparams = useParams()

    useEffect(() => {
        getMemberDetails()
    }, []);


    const getMemberDetails = () => {
        let url = process.env.REACT_APP_BASEURL + "member-view/" + searchparams.id + "/";
        const config = {
            headers: {
                "Authorization": 'Bearer ' + localStorage.getItem("access_token")
            }
        };
        axios.get(url, config)
            .then(res => {
                setMemberDetails(res.data.response.member);
                setEmailMemberDetails(res.data.response.member.user)

                const getMemberRes = res.data.response

                setMemberFullDetails(getMemberRes);
                const a = getMemberRes.per_add_line1
                const b = getMemberRes.per_add_line2
                const c = getMemberRes.per_add_line3
                const d = getMemberRes.per_add_city
                const e = getMemberRes.per_add_state
                const f = getMemberRes.per_add_country
                const g = getMemberRes.per_add_postcode

                let permanentAddress = `${a} ${b} ${c} ${d} ${e} ${f} ${g}`;
                setMemberAddress(permanentAddress)

                const a1 = getMemberRes.comm_add_line1
                const b1 = getMemberRes.comm_add_line2
                const c1 = getMemberRes.comm_add_line3
                const d1 = getMemberRes.comm_add_city
                const e1 = getMemberRes.comm_add_state
                const f1 = getMemberRes.comm_add_country
                const g1 = getMemberRes.comm_add_postcode
                
                let correspondanceAddress = `${a1} ${b1} ${c1} ${d1} ${e1} ${f1} ${g1}`;
                setMemberCaddress(correspondanceAddress)
                
                // jointOwnerDetails

                setJointOwnerDetails(res.data.response.joint_member[0])

                const getJointOwnerDetails = res.data.response.joint_member[0]

                setJointOwnerDetails(getJointOwnerDetails);
                const a2 = getJointOwnerDetails.per_add_line1
                const b2 = getJointOwnerDetails.per_add_line2
                const c2 = getJointOwnerDetails.per_add_line3
                const d2 = getJointOwnerDetails.per_add_city
                const e2 = getJointOwnerDetails.per_add_state
                const f2 = getJointOwnerDetails.per_add_country
                const g2 = getJointOwnerDetails.per_add_postcode

                let jopAddress = `${a2} ${b2} ${c2} ${d2} ${e2} ${f2} ${g2}`;
                setJopAddress(jopAddress)

                const a3 = getJointOwnerDetails.comm_add_line1
                const b3 = getJointOwnerDetails.comm_add_line2
                const c3 = getJointOwnerDetails.comm_add_line3
                const d3 = getJointOwnerDetails.comm_add_city
                const e3 = getJointOwnerDetails.comm_add_state
                const f3 = getJointOwnerDetails.comm_add_country
                const g3 = getJointOwnerDetails.comm_add_postcode

                let jocAddress = `${a3} ${b3} ${c3} ${d3} ${e3} ${f3} ${g3}`;
                setJocAddress(jocAddress)

                console.log(res.data.response)

                // secondjointOwnerDetails

                setSecondJointOwnerDetails(res.data.response.joint_member[1])

                const getSecondJointOwnerDetails = res.data.response.joint_member[1]

                setSecondJointOwnerDetails(getSecondJointOwnerDetails);
                const a4 = getSecondJointOwnerDetails.per_add_line1
                const b4 = getSecondJointOwnerDetails.per_add_line2
                const c4 = getSecondJointOwnerDetails.per_add_line3
                const d4 = getSecondJointOwnerDetails.per_add_city
                const e4 = getSecondJointOwnerDetails.per_add_state
                const f4 = getSecondJointOwnerDetails.per_add_country
                const g4 = getSecondJointOwnerDetails.per_add_postcode

                let sjopAddress = `${a4} ${b4} ${c4} ${d4} ${e4} ${f4} ${g4}`;
                setSJopAddress(sjopAddress)

                const a5 = getSecondJointOwnerDetails.comm_add_line1
                const b5 = getSecondJointOwnerDetails.comm_add_line2
                const c5 = getSecondJointOwnerDetails.comm_add_line3
                const d5 = getSecondJointOwnerDetails.comm_add_city
                const e5 = getSecondJointOwnerDetails.comm_add_state
                const f5 = getSecondJointOwnerDetails.comm_add_country
                const g5 = getSecondJointOwnerDetails.comm_add_postcode

                let sjocAddress = `${a5} ${b5} ${c5} ${d5} ${e5} ${f5} ${g5}`;
                setSJocAddress(sjocAddress)
                
            }).catch(err => {
                console.log(err)
            })
    }

  return (
    <>
        <main>
            <div className='main__container'>
                <div className="container" style={{fontFamily:'sans-serif' ,fontWeight:'semibold'}}>
                      <form>
                        <div className="row d-flex justify-content-center">
                            <div className="col">
                                <p className="main_title_heading m-0">View Member Profile</p>
                            </div>
                            <div className="col-sm-12 col-md-4 text-end ">
                                <Link
                                    to=""
                                    style={{ borderRadius: "30px" , "color": "#ffffff" }}
                                    className="btn w-75 py-1 editbtnsubmit border-0"
                                    type='submit' label='Submit'>
                                    Download PDF
                                </Link>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md">
                                <div className="text-center">
                                {
                                    /* {image !== "" ? <img
                                    alt="profile"
                                    src={image}
                                    className="img-fluid mt-3 rounded-circle "
                                    style={{ width: "100px", height: "100px" }}
                                /> : */
                                    <img
                                    alt="profile"
                                    src={require("../../InnerComponets/assets/images/profilee.jpg")}
                                    className="img-fluid rounded-circle "
                                    style={{ width: "100px", height: "100px" }}
                                    />
                                }
                                <input type="file" accept="image/*" className='d-none' name="image-upload" id="input"
                                    // onChange={imageHandler} 
                                    />
                                {/* <div className="label">
                                    <label className="image-upload font-bold mb-3" htmlFor="input">
                                    <span className='camicon'><HiCamera /></span></label>
                                </div> */}
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm mt-3 mr-3">
                                <span className='form-text ml-3' style={{ fontFamily:'sans-serif', "color": "#000000" , fontWeight: 400 ,"fontSize":'16px' }}>Name of the Applicant</span>
                                        <input
                                        type="text"
                                        name="first_name"
                                        className="form-control py-3 mt-1"
                                        aria-label="Username"
                                        aria-describedby="basic-addon1"
                                        style={{ borderRadius: "30px", background: "#ffffff"}}
                                        defaultValue={memberDetails.name}
                                        disabled
                                    />
                            </div>
                            <div className="col-sm mt-3 mr-3">
                                <span className='form-text ml-3' style={{ fontFamily:'sans-serif', "color": "#000000" , fontWeight: 400 ,"fontSize":'16px' }}>Father’s / Husband’s Name</span>
                                    <input
                                        style={{ borderRadius: "30px", background: "#ffffff"}}
                                        type="text"
                                        name="first_name"
                                        className="form-control py-3 mt-1 "
                                        aria-label="Username"
                                        aria-describedby="basic-addon1"
                                        defaultValue={memberFullDetails.father_or_husband_name}
                                        disabled
                                    />
                            </div>
                            <div className="w-100"></div>
                            <div className="col-sm mt-3 mr-3">
                                <span className='form-text ml-3' style={{ fontFamily:'sans-serif', "color": "#000000" , fontWeight: 400 ,"fontSize":'16px' }}>Permanent Address</span>
                                    <input
                                        style={{ borderRadius: "30px", background: "#ffffff"}}
                                        type="text"
                                        name="first_name"
                                        className="form-control py-3 mt-1 "
                                        aria-label="Username"
                                        aria-describedby="basic-addon1"
                                        defaultValue={memberAddress}
                                        // defaultValue={memberFullDetails.per_add_line1}
                                        disabled
                                    />
                            </div>
                            <div className="col-sm mt-3 mr-3">
                                <span className='form-text ml-3' style={{ fontFamily:'sans-serif', "color": "#000000" , fontWeight: 400 ,"fontSize":'16px' }}>Correspondence Address</span>
                                    <input
                                        style={{ borderRadius: "30px", background: "#ffffff"}}
                                        type="text"
                                        name="first_name"
                                        className="form-control py-3 mt-1 "
                                        aria-label="Username"
                                        aria-describedby="basic-addon1"
                                        defaultValue={memberCaddress}
                                        disabled
                                    />
                            </div>
                            <div className="w-100"></div>
                            <div className="col-sm mt-3 mr-3">
                                <span className='form-text ml-3' style={{ fontFamily:'sans-serif', "color": "#000000" , fontWeight: 400 ,"fontSize":'16px' }}>Phone Number With Code</span>
                                    <input
                                        style={{ borderRadius: "30px", background: "#ffffff"}}
                                        type="text"
                                        name="first_name"
                                        className="form-control py-3 mt-1 "
                                        aria-label="Username"
                                        aria-describedby="basic-addon1"
                                        defaultValue={memberDetails.phone}
                                        disabled
                                    />
                            </div>
                            <div className="col-sm mt-3 mr-3">
                                <span className='form-text ml-3' style={{ fontFamily:'sans-serif', "color": "#000000" , fontWeight: 400 ,"fontSize":'16px' }}>Date Of Birth</span>
                                    <input
                                        style={{ borderRadius: "30px", background: "#ffffff"}}
                                        type="text"
                                        name="first_name"
                                        className="form-control py-3 mt-1 "
                                        aria-label="Username"
                                        aria-describedby="basic-addon1"
                                        defaultValue={memberFullDetails.dob}
                                        disabled
                                    />
                            </div>
                            <div className="w-100"></div>
                            <div className="col-sm mt-3 mr-3">
                                <span className='form-text ml-3' style={{ fontFamily:'sans-serif', "color": "#000000" , fontWeight: 400 ,"fontSize":'16px' }}>Pan number</span>
                                    <input
                                        style={{ borderRadius: "30px", background: "#ffffff"}}
                                        type="text"
                                        name="first_name"
                                        className="form-control py-3 mt-1 "
                                        aria-label="Username"
                                        aria-describedby="basic-addon1"
                                        defaultValue={memberFullDetails.pan_num}
                                        disabled
                                    />
                            </div>
                            <div className="col-sm mt-3 mr-3">
                                <span className='form-text ml-3' style={{ fontFamily:'sans-serif', "color": "#000000" , fontWeight: 400 ,"fontSize":'16px' }}>Email ID</span>
                                    <input
                                        style={{ borderRadius: "30px", background: "#ffffff"}}
                                        type="text"
                                        name="first_name"
                                        className="form-control py-3 mt-1 "
                                        aria-label="Username"
                                        aria-describedby="basic-addon1"
                                        defaultValue={memberEmailDetails.email}
                                        disabled
                                    />
                            </div>
                            <div className="w-100"></div>
                            <div className="col-sm mt-3 mr-3">
                                <span className='form-text ml-3' style={{ fontFamily:'sans-serif', "color": "#000000" , fontWeight: 400 ,"fontSize":'16px' }}>Unit Number</span>
                                    <input
                                        style={{ borderRadius: "30px", background: "#ffffff"}}
                                        type="text"
                                        name="first_name"
                                        className="form-control py-3 mt-1 "
                                        aria-label="Username"
                                        aria-describedby="basic-addon1"
                                        defaultValue={memberDetails.member_unit}
                                        disabled
                                    />
                            </div>
                            <div className="col-sm mt-3 mr-3">
                                <span className='form-text ml-3' style={{ fontFamily:'sans-serif', "color": "#000000" , fontWeight: 400 ,"fontSize":'16px' }}>Occupation</span>
                                    <input
                                        style={{ borderRadius: "30px", background: "#ffffff"}}
                                        type="text"
                                        name="first_name"
                                        className="form-control py-3 mt-1 "
                                        aria-label="Username"
                                        aria-describedby="basic-addon1"
                                        defaultValue={memberFullDetails.occupation}
                                        disabled
                                    />
                            </div>
                                <div className="w-100"></div>
                                <div className="col-sm mt-3 mr-3">
                                <span className='form-text ml-3' style={{ fontFamily:'sans-serif', "color": "#000000" , fontWeight: 400 ,"fontSize":'16px' }}>Date</span>
                                    <input
                                        style={{ borderRadius: "30px", background: "#ffffff"}}
                                        type="text"
                                        name="first_name"
                                        className="form-control py-3 mt-1 "
                                        aria-label="Username"
                                        aria-describedby="basic-addon1"
                                        defaultValue={memberFullDetails.sign_date}
                                        disabled
                                    />
                            </div>
                            <div className="col-sm mt-3 mr-3">
                                <span className='form-text ml-3' style={{ fontFamily:'sans-serif', "color": "#000000" , fontWeight: 400 ,"fontSize":'16px' }}>Place</span>
                                    <input
                                        style={{ borderRadius: "30px", background: "#ffffff"}}
                                        type="text"
                                        name="first_name"
                                        className="form-control py-3 mt-1 "
                                        aria-label="Username"
                                        aria-describedby="basic-addon1"
                                        defaultValue={memberFullDetails.sign_place}
                                        disabled
                                    />
                            </div>
                            <div className="w-100"></div>
                            {/* <div className="col-sm mt-3 mr-3">
                                <span className='form-text ml-3' style={{ fontFamily:'sans-serif', "color": "#000000" , fontWeight: 400 ,"fontSize":'16px' }}>Owner Type</span><br />
                                  <input className='mt-3' type="radio" id="single" name="owner_type" value="single" />
                                  <label for="html">Single</label>
                                  <input type="radio" id="joint" name="owner_type" value="joint" />
                                  <label for="css">Joint</label><br />
                            </div>
                            <div className="col-sm"></div>
                            <div className="w-100"></div> */}
                            <span className='form-text mt-3 ' style={{ fontFamily: 'sans-serif', "color": "#13547A", fontWeight: 600, "fontSize": '18px' }}>Joint Owner Brief Particulars are as under</span>
                            <div className="col-sm mt-3 mr-3">
                                <span className='form-text ml-3' style={{ fontFamily:'sans-serif', "color": "#000000" , fontWeight: 400 ,"fontSize":'16px' }}>Name of the Applicant</span>
                                        <input
                                        type="text"
                                        name="first_name"
                                        className="form-control py-3 mt-1"
                                        aria-label="Username"
                                        aria-describedby="basic-addon1"
                                        style={{ borderRadius: "30px", background: "#ffffff"}}
                                        defaultValue={jointOwnerDetails.name}
                                        disabled
                                    />
                            </div>
                            <div className="col-sm mt-3 mr-3">
                                <span className='form-text ml-3' style={{ fontFamily:'sans-serif', "color": "#000000" , fontWeight: 400 ,"fontSize":'16px' }}>Father’s / Husband’s Name</span>
                                    <input
                                        style={{ borderRadius: "30px", background: "#ffffff"}}
                                        type="text"
                                        name="first_name"
                                        className="form-control py-3 mt-1 "
                                        aria-label="Username"
                                        aria-describedby="basic-addon1"
                                        defaultValue={jointOwnerDetails.father_or_husband_name}
                                        disabled
                                    />
                            </div>
                            <div className="w-100"></div>
                            <div className="col-sm mt-3 mr-3">
                                <span className='form-text ml-3' style={{ fontFamily:'sans-serif', "color": "#000000" , fontWeight: 400 ,"fontSize":'16px' }}>Permanent Address</span>
                                    <input
                                        style={{ borderRadius: "30px", background: "#ffffff"}}
                                        type="text"
                                        name="first_name"
                                        className="form-control py-3 mt-1 "
                                        aria-label="Username"
                                        aria-describedby="basic-addon1"
                                        defaultValue={jopAddress}
                                        disabled
                                    />
                            </div>
                            <div className="col-sm mt-3 mr-3">
                                <span className='form-text ml-3' style={{ fontFamily:'sans-serif', "color": "#000000" , fontWeight: 400 ,"fontSize":'16px' }}>Correspondence Address</span>
                                    <input
                                        style={{ borderRadius: "30px", background: "#ffffff"}}
                                        type="text"
                                        name="first_name"
                                        className="form-control py-3 mt-1 "
                                        aria-label="Username"
                                        aria-describedby="basic-addon1"
                                        defaultValue={jocAddress}
                                        disabled
                                    />
                            </div>
                            <div className="w-100"></div>
                            <div className="col-sm mt-3 mr-3">
                                <span className='form-text ml-3' style={{ fontFamily:'sans-serif', "color": "#000000" , fontWeight: 400 ,"fontSize":'16px' }}>Phone Number With Code</span>
                                    <input
                                        style={{ borderRadius: "30px", background: "#ffffff"}}
                                        type="text"
                                        name="first_name"
                                        className="form-control py-3 mt-1 "
                                        aria-label="Username"
                                        aria-describedby="basic-addon1"
                                        defaultValue={jointOwnerDetails.phone_number}
                                        disabled
                                    />
                            </div>
                            <div className="col-sm mt-3 mr-3">
                                <span className='form-text ml-3' style={{ fontFamily:'sans-serif', "color": "#000000" , fontWeight: 400 ,"fontSize":'16px' }}>Date Of Birth</span>
                                    <input
                                        style={{ borderRadius: "30px", background: "#ffffff"}}
                                        type="text"
                                        name="first_name"
                                        className="form-control py-3 mt-1 "
                                        aria-label="Username"
                                        aria-describedby="basic-addon1"
                                        defaultValue={jointOwnerDetails.dob}
                                        disabled
                                    />
                            </div>
                            <div className="w-100"></div>
                            <div className="col-sm mt-3 mr-3">
                                <span className='form-text ml-3' style={{ fontFamily:'sans-serif', "color": "#000000" , fontWeight: 400 ,"fontSize":'16px' }}>Occupation</span>
                                <input
                                        style={{ borderRadius: "30px", background: "#ffffff"}}
                                        type="text"
                                        name="first_name"
                                        className="form-control py-3 mt-1 "
                                        aria-label="Username"
                                        aria-describedby="basic-addon1"
                                        defaultValue={jointOwnerDetails.occupation}
                                        disabled
                                    />
                            </div>
                            <div className="col-sm mt-3 mr-3">
                                <span className='form-text ml-3' style={{ fontFamily:'sans-serif', "color": "#000000" , fontWeight: 400 ,"fontSize":'16px' }}>Email ID</span>
                                    <input
                                        style={{ borderRadius: "30px", background: "#ffffff"}}
                                        type="text"
                                        name="first_name"
                                        className="form-control py-3 mt-1 "
                                        aria-label="Username"
                                        aria-describedby="basic-addon1"
                                        defaultValue={jointOwnerDetails.email_id}
                                        disabled
                                    />
                            </div>
                            <div className="w-100"></div>
                                    <span className='form-text mt-3 ' style={{ fontFamily: 'sans-serif', "color": "#13547A", fontWeight: 600, "fontSize": '18px' }}>Second Joint Owner Brief Particulars are as under</span>
                                    <div className="col-sm mt-3 mr-3">
                                <span className='form-text ml-3' style={{ fontFamily:'sans-serif', "color": "#000000" , fontWeight: 400 ,"fontSize":'16px' }}>Name of the Applicant</span>
                                        <input
                                        type="text"
                                        name="first_name"
                                        className="form-control py-3 mt-1"
                                        aria-label="Username"
                                        aria-describedby="basic-addon1"
                                        style={{ borderRadius: "30px", background: "#ffffff"}}
                                        defaultValue={secondJointOwnerDetails.name}
                                        disabled
                                    />
                            </div>
                            <div className="col-sm mt-3 mr-3">
                                <span className='form-text ml-3' style={{ fontFamily:'sans-serif', "color": "#000000" , fontWeight: 400 ,"fontSize":'16px' }}>Father’s / Husband’s Name</span>
                                    <input
                                        style={{ borderRadius: "30px", background: "#ffffff"}}
                                        type="text"
                                        name="first_name"
                                        className="form-control py-3 mt-1 "
                                        aria-label="Username"
                                        aria-describedby="basic-addon1"
                                        defaultValue={secondJointOwnerDetails.father_or_husband_name}
                                        disabled
                                    />
                            </div>
                            <div className="w-100"></div>
                            <div className="col-sm mt-3 mr-3">
                                <span className='form-text ml-3' style={{ fontFamily:'sans-serif', "color": "#000000" , fontWeight: 400 ,"fontSize":'16px' }}>Permanent Address</span>
                                    <input
                                        style={{ borderRadius: "30px", background: "#ffffff"}}
                                        type="text"
                                        name="first_name"
                                        className="form-control py-3 mt-1 "
                                        aria-label="Username"
                                        aria-describedby="basic-addon1"
                                        defaultValue={sjopAddress}
                                        disabled
                                    />
                            </div>
                            <div className="col-sm mt-3 mr-3">
                                <span className='form-text ml-3' style={{ fontFamily:'sans-serif', "color": "#000000" , fontWeight: 400 ,"fontSize":'16px' }}>Correspondence Address</span>
                                    <input
                                        style={{ borderRadius: "30px", background: "#ffffff"}}
                                        type="text"
                                        name="first_name"
                                        className="form-control py-3 mt-1 "
                                        aria-label="Username"
                                        aria-describedby="basic-addon1"
                                        defaultValue={sjocAddress}
                                        disabled
                                    />
                            </div>
                            <div className="w-100"></div>
                        
                            <div className="col-sm mt-3 mr-3">
                                <span className='form-text ml-3' style={{ fontFamily:'sans-serif', "color": "#000000" , fontWeight: 400 ,"fontSize":'16px' }}>Phone Number With Code</span>
                                    <input
                                        style={{ borderRadius: "30px", background: "#ffffff"}}
                                        type="text"
                                        name="first_name"
                                        className="form-control py-3 mt-1 "
                                        aria-label="Username"
                                        aria-describedby="basic-addon1"
                                        defaultValue={secondJointOwnerDetails.phone_number}
                                        disabled
                                    />
                            </div>
                            <div className="col-sm mt-3 mr-3">
                                <span className='form-text ml-3' style={{ fontFamily:'sans-serif', "color": "#000000" , fontWeight: 400 ,"fontSize":'16px' }}>Date Of Birth</span>
                                    <input
                                        style={{ borderRadius: "30px", background: "#ffffff"}}
                                        type="text"
                                        name="first_name"
                                        className="form-control py-3 mt-1 "
                                        aria-label="Username"
                                        aria-describedby="basic-addon1"
                                        defaultValue={secondJointOwnerDetails.dob}
                                        disabled
                                    />
                            </div>
                            <div className="w-100"></div>
                            <div className="col-sm mt-3 mr-3">
                                <span className='form-text ml-3' style={{ fontFamily:'sans-serif', "color": "#000000" , fontWeight: 400 ,"fontSize":'16px' }}>Occupation</span>
                                <input
                                        style={{ borderRadius: "30px", background: "#ffffff"}}
                                        type="text"
                                        name="first_name"
                                        className="form-control py-3 mt-1 "
                                        aria-label="Username"
                                        aria-describedby="basic-addon1"
                                        defaultValue={secondJointOwnerDetails.occupation}
                                        disabled
                                    />
                            </div>
                            <div className="col-sm mt-3 mr-3">
                                <span className='form-text ml-3' style={{ fontFamily:'sans-serif', "color": "#000000" , fontWeight: 400 ,"fontSize":'16px' }}>Email ID</span>
                                    <input
                                        style={{ borderRadius: "30px", background: "#ffffff"}}
                                        type="text"
                                        name="first_name"
                                        className="form-control py-3 mt-1 "
                                        aria-label="Username"
                                        aria-describedby="basic-addon1"
                                        defaultValue={secondJointOwnerDetails.email_id}
                                        disabled
                                    />
                            </div>
                            <div className="w-100 mb-3"></div>
                            </div> 
                        <span className='form-text mt-3' style={{ fontFamily: 'sans-serif', "color": "#13547A", fontWeight: 600, "fontSize": '18px' }}>Member Document</span>                    
                        <Row className="col-12 p-0 mt-2 text-center">
                                <Col lg={3} className="mt-3 mb-3">
                                <p className='profile_detail_heading'>Member Signature</p>
                                    <Button className="cardviewbtn" onClick={() => { setShow(true)}}>
                                        View
                                    </Button>
                                </Col>                
                                <Col lg={3} className="mt-3 mb-3">
                                <p className='profile_detail_heading'>Proof Of Identity</p>
                                    <Button className="cardviewbtn" onClick={() => { setShow(true)}}>
                                        View
                                    </Button>
                                </Col>   
                                <Col lg={3} className="mt-3 mb-3">
                                <p className='profile_detail_heading'>Proof Of Address</p>
                                    <Button className="cardviewbtn" onClick={() => { setShow(true)}}>
                                        View
                                    </Button>
                                </Col>
                                <Col lg={3} className="mt-3 mb-3">
                                <p className='profile_detail_heading'>Proof Of Date Of Birth</p>
                                    <Button className="cardviewbtn" onClick={() => { setShow(true)}}>
                                        View
                                    </Button>
                                </Col>                      
                        </Row>               
                        <Row className="col-12 p-0 mt-2 text-center">
                            <Col lg={3} className="mt-3 mb-3">
                            <p className='profile_detail_heading'>Proof Of Ownership</p>
                                <Button className="cardviewbtn" onClick={() => { setShow(true)}}>
                                    View
                                </Button>
                            </Col>                
                            <Col lg={3} className="mt-3 mb-3">
                            <p className='profile_detail_heading'>Proof Of Payment</p>
                                <Button className="cardviewbtn" onClick={() => { setShow(true)}}>
                                    View
                                </Button>
                            </Col>   
                            <Col lg={3} className="mt-3 mb-3">
                            <p className='profile_detail_heading'>Proof Of Joint Owner 1</p>
                                <Button className="cardviewbtn" onClick={() => { setShow(true)}}>
                                    View
                                </Button>
                            </Col>
                            <Col lg={3} className="mt-3 mb-3">
                            <p className='profile_detail_heading'>Proof Of Joint Owner 2</p>
                                <Button className="cardviewbtn" onClick={() => { setShow(true)}}>
                                    View
                                </Button>
                            </Col>                      
                        </Row>
                        <Row className="col-12 p-0 mt-2 text-center justify-content-center">
                            <Col lg={3} className="mt-3 mb-3">
                            <p className='profile_detail_heading'>NOC Joint Owner 1</p>
                                <Button className="cardviewbtn" onClick={() => { setShow(true)}}>
                                    View
                                </Button>
                            </Col>                
                            <Col lg={3} className="mt-3 mb-3">
                            <p className='profile_detail_heading'>NOC Joint Owner 2</p>
                                <Button className="cardviewbtn" onClick={() => { setShow(true)}}>
                                    View
                                </Button>
                            </Col>                    
                        </Row>                    
                        <Row className="col-12 p-0 mt-2 text-center">
                                <Col lg={4} className="mt-3 mb-3">
                                    <Button
                                        style={{ borderRadius: "30px" , "color": "#ffffff" }}
                                        className="btn w-75 py-3 editbtnsubmit border-0">
                                        Approved
                                    </Button>
                                </Col>                
                                <Col lg={4} className="mt-3 mb-3">
                                    <Button
                                        onClick={() => { setShow1(true)}}
                                        style={{ borderRadius: "30px" , "color": "#ffffff" }}
                                        className="btn w-75 py-3 editbtnsubmit border-0">
                                        Alter
                                    </Button>
                                </Col>   
                                <Col lg={4} className="mt-3 mb-3">
                                    <Button
                                        style={{ borderRadius: "30px" , "color": "#ffffff" }}
                                        className="btn w-75 py-3 editbtnsubmit border-0">
                                        Deny
                                    </Button>
                                </Col>                      
                        </Row>               
                    </form>
                </div>
                </div>
                <Modal show={show} onHide={() => { setShow(false) }} className="mt-5">
                    <Modal.Header closeButton style={{padding:'3px', background:'#13547A', paddingRight:'15px'}}>
                        <Modal.Title style={{fontSize:'20px', marginLeft:'5px', fontFamily:'sans-serif', color:'#ffffff'}}>
                            View Document
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body >
                        <MemberDocViewModel closeModal={setShow}/>
                    </Modal.Body>
                </Modal>
                <Modal show={show1} onHide={() => { setShow1(false) }} className="mt-5">
                    <Modal.Header closeButton style={{padding:'3px', background:'#13547A', paddingRight:'15px'}}>
                        <Modal.Title style={{fontSize:'20px', marginLeft:'5px', fontFamily:'sans-serif', color:'#ffffff'}}>
                            Alter Details
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body >
                        <AlterModel closeModal={setShow1}/>
                    </Modal.Body>
                </Modal>
        </main>
    </>
  );
}
