import React from 'react'
import img from "../assets/images/Group 313.png";
import {  Link } from "react-router-dom";

export default function ApplicationFormSuccessfull() {

    return (
        <> 
            <main>
                <div className='main__container'> 
                    <div className="container">
                        <div className="row">
                            <div className="col-md-3"></div>
                            <div className="col-md-6">
                                <div className="card text-center" style={{ boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)" }}>
                                    <div className="card-body">
                                            <img
                                                alt='file not found'
                                                src={img}
                                                className="img-fluid mt-4"
                                                style={{ width: "150px", height: "125px" }}
                                            />
                                            <div className='mt-5' style={{ fontFamily:'sans-serif', "color": "#13547A" , fontWeight: 600 ,"fontSize":'16px' }}>Application Form for Membership Has been Successfully Submitted.</div>                            
                                            <Link
                                                to="/memberdashboard"
                                                style={{ borderRadius: "30px" , fontFamily:'sans-serif', "color": "#ffffff" , fontWeight: 500 ,"fontSize":'16px' }}
                                                className="btn viewbtn1 w-100 py-3 editbtnsubmit mt-4 mb-3 border-0"
                                                type='submit' label='Submit'>
                                                Go To Dashboard
                                            </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3"></div>
                        </div>
                    </div>
                </div>
            </main>      
        </>
    );
}
