import "./Sidebar.css";
import {  Link, useLocation } from "react-router-dom";
import React, { useState } from "react";
import sslogo from '../InnerComponets/assets/images/MainLogo.jpeg';

const Sidebar = ({ sidebarOpen, closeSidebar }) => {

    let location = useLocation(); 
    let pathName = location.pathname.split('/');
    pathName = pathName[pathName.length - 1]; 
    const [isOpenSubMenu, setIsOpenSubMenu] = useState('');
    
    const onSelectSideBarMenu = (type) => {
        if(type && type === isOpenSubMenu){
            setIsOpenSubMenu('')
        }else{
            setIsOpenSubMenu(type)
        }
    }

    return (
        <div className={sidebarOpen ? "sidebar-responsive" : ""} id="sidebar">
            <i className="fa-solid fa-circle-xmark"
                id="sidebarIcon"
                onClick={() => closeSidebar()}>
            </i>
            <div onClick={() => onSelectSideBarMenu('TopProfile')}>
            <div className="sidebar__title ">
                <div className="sidebar__img mx-3">
                    <img
                        src={sslogo}  
                        alt="logo" style={{borderRadius:'50%', width:"100px"}}                            
                    />
                </div>
            </div>
        </div>
        <div className="sidebar__menu">
                {localStorage.getItem('role') === 'user' && (
                    <>
                        {/* Member Components */}
                        <Link style={{textDecoration:"none"}} to="/memberdashboard" onClick={() => closeSidebar()}>
                            <div className={pathName===''?"sidebar__link_custom active__button":"sidebar__link_custom mt-4"}>
                                <i className="fa fa-home"></i>
                                <span className="text_css">Member Dashboard</span>  
                            </div>
                        </Link>
                        <Link style={{textDecoration:"none"}} to="/lawandinstructions" onClick={() => closeSidebar()}>
                            <div className={pathName==='memberdetails'?"sidebar__link_custom active__button":"sidebar__link_custom"}>
                                <i className="fa-solid fa-file"></i>
                                <span className="text_css">Application Form</span>
                            </div>
                        </Link>
                        <Link style={{textDecoration:"none"}} to="/membermembershipcard" onClick={() => closeSidebar()}>
                            <div className={pathName==='documentwallet'?"sidebar__link_custom active__button":"sidebar__link_custom"}>
                                <i className="fa-sharp fa-solid fa-credit-card"></i>
                                <span className="text_css">MemberShip Card</span>
                            </div>
                        </Link>
                        <Link style={{textDecoration:"none"}} to="/memberbillreceipt" onClick={() => closeSidebar()}>
                            <div className={pathName==='complaintsmanagement'?"sidebar__link_custom active__button":"sidebar__link_custom"}>
                                <i className="fa-solid fa-money-bills"></i>
                                <span className="text_css">Member Bill Receipts</span>
                            </div>
                        </Link>
                    </>
                )}
                
                {localStorage.getItem('role') === 'admin' && (
                    <>
                        {/* Admin Components */}
                        <Link style={{textDecoration:"none"}} to="/admindashboard" onClick={() => closeSidebar()}>
                            <div className={pathName==='memberapproval'?"sidebar__link_custom active__button":"sidebar__link_custom"}>
                                <i className="fa fa-home"></i>
                                <span className="text_css">Admin Dashboard</span>
                            </div>
                        </Link>
                        <Link style={{textDecoration:"none"}} to="/email" onClick={() => closeSidebar()}>
                            <div className={pathName==='memberapproval'?"sidebar__link_custom active__button":"sidebar__link_custom"}>
                                <i className="fa-solid fa-envelope"></i>
                                <span className="text_css">Email</span>
                            </div>
                        </Link>
                        <Link style={{textDecoration:"none"}} to="/memberapplicationlist" onClick={() => closeSidebar()}>
                            <div className={pathName==='memberapproval'?"sidebar__link_custom active__button":"sidebar__link_custom"}>
                                <i className="fa-solid fa-file"></i>
                                <span className="text_css">Application Form</span>
                            </div>
                        </Link>
                        <Link style={{textDecoration:"none"}} to="/membershipcards" onClick={() => closeSidebar()}>
                            <div className={pathName==='memberapproval'?"sidebar__link_custom active__button":"sidebar__link_custom"}>
                                <i className="fa-sharp fa-solid fa-credit-card"></i>
                                <span className="text_css">MemberShip Cards</span>
                            </div>
                        </Link>
                        <Link style={{textDecoration:"none"}} to="/memberbillreceipts" onClick={() => closeSidebar()}>
                            <div className={pathName==='memberapproval'?"sidebar__link_custom active__button":"sidebar__link_custom"}>
                                <i className="fa-solid fa-money-bills"></i>
                                <span className="text_css">Member Bill Receipts</span>
                            </div>
                        </Link>
                    </>
                )}
        </div>
    </div>  
    );
}
export default Sidebar;
