import "./Navbar.css";
import axios from "axios";
import { Link } from "react-router-dom";
import React, { useState } from "react";
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Box from '@mui/material/Box';
import { FaRegBell } from "react-icons/fa";
import sslogo from '../InnerComponets/assets/images/profilee.jpg'
// import NotificationsIcon from '@mui/icons-material/Notifications';
// import Typography from '@mui/material/Typography';
// import Divider from '@mui/material/Divider';
// import LockResetIcon from '@mui/icons-material/LockReset';
import Swal from "sweetalert2";
import { toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";


const Navbar = ({ sidebarOpen, openSidebar }) => {

  let [notificationcount,
    // setNotificationcount
  ] = useState(); 
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  let navigate = useNavigate();

  const logout = () => {
    Swal.fire({
      title: 'Are you sure you want to logout?',
      showCancelButton: true,
      confirmButtonText: `Logout`,
      denyButtonText: `Cancel`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */ 
      if (result.isConfirmed) {
        let logUrl = process.env.REACT_APP_BASEURL + "logout/";
        let config = {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-type": "application/json",
            "Authorization": 'Bearer ' + localStorage.getItem("access_token")
          },
        };
        axios.delete(logUrl, config).then(response => {
          console.log(response)
          localStorage.removeItem('access_token')
          localStorage.removeItem('role')
          toast.success("Logout successfully", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1500,
            theme: "colored",
          }
          );
          navigate("/")
          window.location.reload();
        }).catch(err => {
          console.log(err)
          toast.error("Something went wrong.", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
            theme: "colored",
          });
        });
      } 
    }) 
  }

    
    return (
      <>
        <nav className="navbar_custom">
            <div className="nav_icon col-6" onClick={() => openSidebar()}>
              <i className="fa-solid fa-bars"></i>
            </div>
            <div className="navbar__right col">
              <div className="text-end  me-2">              
                {/* Member Components */}
                {localStorage.getItem('role') === 'user' && (
                        <>
                            <div className="crypDrop">
                              <MenuItem >                 
                                <Badge badgeContent={notificationcount} color="error" >
                                  <FaRegBell className="Userbell" />
                                {/* <Link onClick={() => { setNotification(true) }}>
                                  </Link>{notification && (<Societynotification closeModal={setNotification} />)} */}
                                </Badge>
                              </MenuItem>
                              <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
                                <Tooltip title="Account settings">
                                  <IconButton
                                        onClick={handleClick}
                                        size="small"
                                        aria-controls={open ? 'account-menu' : undefined}
                                        aria-haspopup="true"
                                        aria-expanded={open ? 'true' : undefined}
                                      >
                                      <Avatar>
                                            <img src={sslogo}  alt="logo" style={{ widht: '60px', height: '60px', borderRadius: '50%' }} />
                                      </Avatar>
                                  </IconButton>
                                </Tooltip>
                              </Box>
                              <Menu
                                anchorEl={anchorEl}
                                id="account-menu"
                                open={open}
                                onClose={handleClose}
                                onClick={handleClose}
                                PaperProps={{
                                  elevation: 0,
                                  sx: {
                                    overflow: 'visible',
                                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                    mt: 1.5,
                                    '& .MuiAvatar-root': {
                                      width: 32,
                                      height: 32,
                                      ml: -0.5,
                                      mr: 1,
                                    },
                                    '&:before': {
                                      content: '""',
                                      display: 'block',
                                      position: 'absolute',
                                      top: 0,
                                      right: 14,
                                      width: 10,
                                      height: 10,
                                      bgcolor: 'background.paper',
                                      transform: 'translateY(-50%) rotate(45deg)',
                                      zIndex: 0,
                                    },
                                  },
                                }}
                                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                              >
                              <MenuItem>
                                <ListItemIcon >
                                  <i className="fa-solid fa-user"></i>
                                </ListItemIcon>
                                  <Link
                                      to='/memberprofile'
                                      style={{ textDecoration: "none", color: "#000000" }}>
                                      Member Profile
                                  </Link>
                              </MenuItem>
                              <MenuItem
                                onClick={logout}
                                >
                                <ListItemIcon >
                                  <i className="fa-solid fa-right-from-bracket"></i>
                                </ListItemIcon>
                                Logout
                                </MenuItem>
                              </Menu>
                            </div>
                        </>
                )}
                  
                {/* Admin Components */}
                {localStorage.getItem('role') === 'admin' && (
                      <>
                          <div className="crypDrop">
                            <MenuItem >                 
                              <Badge badgeContent={notificationcount} color="error" >
                                <FaRegBell className="Userbell" />
                              {/* <Link onClick={() => { setNotification(true) }}>
                                </Link>{notification && (<Societynotification closeModal={setNotification} />)} */}
                              </Badge>
                            </MenuItem>
                            <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
                              <Tooltip title="Account settings">
                                <IconButton
                                      onClick={handleClick}
                                      size="small"
                                      aria-controls={open ? 'account-menu' : undefined}
                                      aria-haspopup="true"
                                      aria-expanded={open ? 'true' : undefined}
                                    >
                                    <Avatar>
                                          <img src={sslogo}  alt="logo" style={{ widht: '60px', height: '60px', borderRadius: '50%' }} />
                                    </Avatar>
                                </IconButton>
                              </Tooltip>
                            </Box>
                            <Menu
                              anchorEl={anchorEl}
                              id="account-menu"
                              open={open}
                              onClose={handleClose}
                              onClick={handleClose}
                              PaperProps={{
                                elevation: 0,
                                sx: {
                                  overflow: 'visible',
                                  filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                  mt: 1.5,
                                  '& .MuiAvatar-root': {
                                    width: 32,
                                    height: 32,
                                    ml: -0.5,
                                    mr: 1,
                                  },
                                  '&:before': {
                                    content: '""',
                                    display: 'block',
                                    position: 'absolute',
                                    top: 0,
                                    right: 14,
                                    width: 10,
                                    height: 10,
                                    bgcolor: 'background.paper',
                                    transform: 'translateY(-50%) rotate(45deg)',
                                    zIndex: 0,
                                  },
                                },
                              }}
                              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                            >
                            <MenuItem>
                              <ListItemIcon >
                                <i className="fa-solid fa-user"></i>
                              </ListItemIcon>
                                <Link
                                    to='/memberprofile'
                                    style={{ textDecoration: "none", color: "#000000" }}>
                                    Admin Profile
                                </Link>
                            </MenuItem>
                            <MenuItem
                              onClick={logout}
                              >
                              <ListItemIcon >
                                <i className="fa-solid fa-right-from-bracket"></i>
                              </ListItemIcon>
                              Logout
                              </MenuItem>
                            </Menu>
                          </div>
                      </>
                )}
              </div>
            </div>
        </nav>
    </>
    )
}

export default Navbar;