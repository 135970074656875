import "../../InnerComponets/memberDashboard/MemberDashboard.css";
import React, {  useState } from "react";
// import Profile from '../../InnerComponets/assets/images/profilee.jpg'
import { Link } from "react-router-dom";
import { AiTwotoneEye } from 'react-icons/ai'
// import Button from 'react-bootstrap/Button';
// import Card from 'react-bootstrap/Card';
// import { Row, Col } from "react-bootstrap";

const AdminDashboard = () => {
    const [eventlist,
        // setEventlist
    ] = useState([]);
    const [profile,
        // setProfile
    ] = useState({});
    const [count,
        // setCount
    ] = useState({});
 
    return (
        <>
            <main>
                <div className="main__container">
                    <div className="main__title">
                        <div className="main__greeting">
                            <h1 className="text-capitalize">Hello, <span style={{ color: '#36a3ce' }}>{`${profile.first_name} ${profile.last_name}`}</span></h1>
                        </div>
                    </div>
                    <div className="main__cards ">
                        <div className="card__main ">
                            <p className="text-primary-p">Outstanding Payment</p>
                            <div className="card_inner">
                                {count.outstanding_payment ? <span className="font-bold text-title">{count.outstanding_payment}</span> : <span className="font-bold text-title">N/A</span>}
                                <span className="rounded-circle p-2 cirle__bg">
                                    <i className="fa-sharp fa-solid fa-credit-card card_icons"></i>
                                </span>
                            </div>
                        </div>
                        <div className="card__main">
                            <p className="text-primary-p">Total Active Members</p>
                            <div className="card_inner">
                                {count.total_active_members ? <span className="font-bold text-title">{count.total_active_members}</span> : <span className="font-bold text-title">N/A</span>}
                                <span className="rounded-circle p-2 cirle__bg">
                                    <i className="fa-solid fa-users card_icons"></i>
                                </span>
                            </div>
                        </div>
                        <div className="card__main">
                            <p className="text-primary-p">Total Complaint</p>
                            <div className="card_inner">
                                {count.total_members_complaint ? <span className="font-bold text-title">{count.total_members_complaint}</span> : <span className="font-bold text-title">N/A</span>}
                                <span className="rounded-circle p-2 cirle__bg">
                                <i className="fa-solid fa-clipboard card_icons"></i>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="charts">
                        <div className="charts__left">
                            <div className="charts__left__title">
                                <div>
                                    <h3 className="fs-5 " >Event List</h3>
                                </div>
                            </div>
                            <table className="table table-striped table_border  responce__table" >
                                <thead className="text-center table_header">
                                    <tr>
                                        <th scope="col">S. No.</th>
                                        <th scope="col">Event Title</th>
                                        <th scope="col">Date</th>
                                        <th scope="col">View</th>
                                    </tr>
                                </thead>
                                <tbody className="text-center">
                                    {eventlist.length === 0 ? <tr>
                                        <td colSpan={12}>No Event found.</td>
                                    </tr> :
                                        eventlist.slice(0, 3).map((event, index) => {
                                            return (<tr key={index}>
                                                <th>{index + 1}</th>
                                                <td>{event.title}</td>
                                                <td>{event.date}</td>
                                                <td className="text-center" style={{ cursor: "pointer" }}>
                                                    <AiTwotoneEye className="fs-4 text-secondary" />
                                                </td>
                                            </tr>)
                                        })}
                                </tbody>
                            </table>
                            <Link to='/eventsmanagement' style={{ textDecoration: 'none' }}><span className="view_more">View More</span></Link>
                        </div>
                        <div className="charts__right">
                            <div className="charts__right__title">
                                <div>
                                    <h3 className="fs-5 ">Board Meeting</h3>
                                </div>
                            </div>
                            <table className="table table-striped table_border responce__table2">
                                <thead className="text-center table_header">
                                    <tr >
                                        <th scope="col">S. No.</th>
                                        <th scope="col">Feedback</th>
                                        <th scope="col">Date</th>
                                    </tr>
                                </thead>
                                <tbody className="text-center">
                                <tr>
                                    <th>index</th>
                                    <td>title</td>
                                    <td>notice</td>
                                </tr>
                                </tbody>
                            </table>
                            <Link to='/feedback' style={{ textDecoration: 'none' }}><span className="view_more" >View More</span></Link>
                        </div>
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <h3 className="fs-5 mt-3" >Member List</h3>
                            </div>
                        </div>
                        {/* <Row className="col-12 p-0 mt-2">
                            <div className="flex-wrap d-flex justify-content-center  " >
                                {memberlist?.length && memberlist?.length > 0 ?
                                    memberlist.map((item, index) => {
                                        return (
                                            <Card style={{ padding: '0px', margin: '5px', boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)' }}  >
                                                <div className="d-flex justify-content-center mt-2">
                                                    {item.profile === null || "" ? <img
                                                        src={Profile}
                                                        className="rounded-circle mt-0"
                                                        style={{ width: "50px", height: "50px" }}
                                                        alt="image"
                                                    /> : <img
                                                        src={`http://104.225.217.230:8003${item.profile}`}
                                                        className="rounded-circle mt-0"
                                                        style={{ width: "50px", height: "50px" }}
                                                        alt="image"
                                                    />}
                                                </div>
                                                <Card.Body>
                                                    {item.first_name ? <Card.Title className='text-center'>{item.first_name}</Card.Title> : <Card.Title className='text-center'>N/A</Card.Title>}
                                                    <Card.Text className='text-center text-capitalize text-secondary'>
                                                        <p>{`Member ID ${item.member_public_id}`}<br></br>
                                                            {`+91 ${item.mobile_number}`}<br></br>
                                                        </p>

                                                    </Card.Text>
                                                    <Row >
                                                        <Col className="d-flex justify-content-center " >
                                                            <Button className="viewbtn1" >View</Button>
                                                        </Col>
                                                    </Row>
                                                </Card.Body>
                                            </Card>
                                        )
                                    }) : <div className="h3">No data found</div>
                                }</div>
                            <Link to='/memberdetails' style={{ textDecoration: 'none' }}><span className="view_more" >View More</span></Link>
                        </Row> */}
                    </div>
                </div>
            </main>
        </>
    )
}

export default AdminDashboard;

