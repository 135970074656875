import React from 'react'
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import { Row, Col } from "react-bootstrap";
import { Input } from "reactstrap";
import { FaSearch } from "react-icons/fa";
import pdf from "../../InnerComponets/assets/images/profilee.jpg"
import {  Link } from "react-router-dom";
import axios from "axios";
import { useState, useEffect } from "react";
import Loader from '../../Loader/Loader';

export default function MemberApplicationList() {
  
    let [memberlist, setMemberlist] = useState([]);
    let [memberlistdownload, setMemberlistdownload] = useState([]);

    let [search, setSearch] = useState(null);
    let [loading, setLoading] = useState(true);

    useEffect(() => {
        getMemberList();
        getMemberListDownload();
    }, []);

    // Member List

    const getMemberList = () => {
        setLoading(true);
        let url = process.env.REACT_APP_BASEURL + `member-application-list/`
        const config = {
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-type": "application/json",
                "Authorization": 'Bearer ' + localStorage.getItem("access_token")
            },
        };
        axios
            .get(url, config)
            .then((res) => {
                setLoading(false);
                setMemberlist(res.data.response);
                console.log(res.data.response);

            })
            .catch((err) => {
                setLoading(false);
                console.log(err);
            });
    };

// Download Memberlist

const getMemberListDownload = () => {
    setLoading(true);
    let url = process.env.REACT_APP_BASEURL + `member-list-download/`
    
    const config = {
        headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-type": "application/json",
            "Authorization": 'Bearer ' + localStorage.getItem("access_token")
        },
    };
    axios
        .get(url, config)
        .then((res) => {
            setMemberlistdownload(res.data);
            // console.log(res);
        })
        .catch((err) => {
            console.log(err);
        });
};

let file = `${process.env.REACT_APP_BASEURL}${memberlistdownload.path}`;
function download() {
  window.open(
    file,
    '_blank'
  );
};
    
  return (
    <>

          <main>
              {loading ? <Loader></Loader>:
                  <div className='main__container'>
                      <div className="container">
                          <div className="row d-flex justify-content-center">
                              <div className="col text-center">
                                  <p className="main_title_heading m-0">Member Application List</p>
                              </div>
                              <div className="col-sm-12 col-md-4 text-center mb-2">
                                  <Link
                                      to=""
                                      style={{ borderRadius: "30px", "color": "#ffffff" }}
                                      className="btn w-75 py-1 editbtnsubmit border-0"
                                      type='submit' label='Submit' onClick={download}>
                                      
                                      Download List
                                  </Link>
                              </div>
                              <div className="col-sm-12 col-md-4 text-center">
                                  <div className="input-group input-group">
                                      <span className="input-group-text searchbar" id="basic-addon1"><FaSearch></FaSearch></span>
                                      <Input name="search" type="text" placeholder="Search"
                                          defaultValue={search}
                                          onChange={(e) => { setSearch(e.target.value) }}
                                      />
                                  </div>
                              </div>
                          </div>
                          <Row className="col-12 p-0 mt-2">
                              <div className="flex-wrap d-flex justify-content-center">
                                  {memberlist.length && memberlist.length > 0 ?
                                      memberlist.filter((user) => {
                                          if (search === "") {
                                              return user
                                          } else if ((user.member.name || '').toLowerCase().includes((search || '').toLowerCase()) ||
                                              user.member.phone.toString().includes(search) ||
                                              user.member.member_unit.toString().includes(search)) {
                                              return user
                                          }
                                      }).map((item, index) => {
                                          return (
                                              <Card key={index} style={{ padding: '0px', margin: '2px', boxShadow: '0px 8px 18px -6px rgba(24, 39, 75, 0.12),  0px 12px 42px -4px rgba(24, 39, 75, 0.12)', width: '250px', borderRadius: "10px" }} className='pt-3 m-3 border-0'>
                                                  <div className="d-flex justify-content-center mt-2">
                                                      {
                                                        item.profile_img == null && ' ' ?
                                                            <img
                                                                src={pdf}
                                                                className="rounded-circle mt-0"
                                                                style={{ width: "50px", height: "50px" }} alt="profile"
                                                            /> :
                                                            <img
                                                                src={`${process.env.REACT_APP_BASEURL.slice(0, -1)}${item.profile_img}`}
                                                                className="rounded-circle mt-0" alt='profile'
                                                                style={{ width: "50px", height: "50px", borderRadius: "50px", borderWidth: "1px" }}
                                                            />
                                                        } 
                                                  </div>
                                                  <Card.Body >
                                                      <Card.Title className='text-center card_title'>{item.member.name}</Card.Title>
                                                      <Card.Text className='text-center text-capitalize text-secondary'>
                                                          <p>{item.member.member_unit} <br /> {item.member.phone}</p>
                                                      </Card.Text>
                                                      <Row>
                                                          <Col className="d-flex justify-content-center">
                                                              <Button className="cardviewbtn m-1 border-0" >
                                                                  <Link to={`/memberviewprofile/${item.id}`}  style={{ textDecoration: "none", color: "#ffffff" }} >
                                                                      View
                                                                  </Link>
                                                              </Button>
                                                              <Button className="cardviewbtn1 m-1 border-0">
                                                                  <Link to="/membereditprofile" style={{ textDecoration: "none", color: "#ffffff" }}>
                                                                      Edit
                                                                  </Link>
                                                              </Button>
                                                          </Col>
                                                      </Row>
                                                  </Card.Body>
                                              </Card>
                                          )
                                      })
                                      :
                                      <div className="h3 text-center">No data found</div>
                                  }
                              </div>
                          </Row>
                      </div>
                  </div>
              }
        </main>
    </>
  );
}
