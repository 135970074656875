import React from 'react'
import {  BsFacebook, BsInstagram, BsTwitter, BsGoogle } from "react-icons/bs";

function Footer() {

    return (
        <>
            <footer className="page-footer font-small mt-2 " style={{ backgroundColor: '#000', }}>
                <div className="container text-md-left">
                    <div className="row">
                        <br></br>
                        <hr className="clearfix w-100 d-md-none " />
                        <div className="col-md-2 text-center mx-auto mt-4">
                             <img src={require('../images/logo.png')} alt="logo"></img>
                        </div>
                        <hr className="clearfix w-100 d-md-none" />
                        <div className="col-md-6 mx-auto">
                            <br></br>
                            <ul className="list-unstyled text-white text-center">
                                <li>“Don’t let Shady builder steal your dream and lifetime savings!” “buyer beware”….</li>
                            <br></br>
                            </ul>
                        </div>
                        <hr className="clearfix w-100 d-md-none" />
                        <div className="col-md-2 mx-auto">
                            <br></br>
                            <h6 className="display-8 text-white text-center">GET SOCIAL</h6>
                            <ul className="list-unstyled text-white text-center ">
                                <li className="mt-3">
                                    <BsFacebook style={{ 'fontSize': '1.5em' }} />&nbsp;&nbsp;
                                    <BsInstagram style={{ 'fontSize': '1.5em' }} />&nbsp;&nbsp;
                                    <BsTwitter style={{ 'fontSize': '1.5em' }} />&nbsp;&nbsp;
                                    <BsGoogle style={{ 'fontSize': '1.5em' }} />
                                </li>
                                <li className='mt-2'>website: www.rgfba.com</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </footer>
            <div>
                <div className='d-flex justify-content-center ' style={{background: '#5c5656',color:'white',padding:'10px'}}>© Copyright and All right Reserved 2022 by RGFBA  –  Design By Tech Radix Pvt. Ltd.</div>
            </div>
        </>


    )
}

export default Footer