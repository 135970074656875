import React from 'react'
import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const Mission = () => {
  return (
    <div>
         <div className='contact-image '>
                <Row className="mt-8">
                    <Col className='text-center ' style={{ marginTop: '20px' }}>
                    <nav aria-label="breadcrumb" >
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/home" className="text-500 text-base font-semibold" style={{ textDecoration: "none" }}>Home</Link></li>
                                <li className="breadcrumb-item active" aria-current="page" ><Link to="/mission" className="text-teal-600  text-base font-semibold" style={{ textDecoration: "none" }}>Mission</Link></li>
                            </ol>
                        </nav>
                        <span className="" style={{ color: '#1a2141', fontSize: '30px', fontWeight: 700, textTransform: 'uppercase', lineHeight: 1.2, marginTop: '100px' }}>Mission</span>
                    </Col>
                    <div data-aos="fade-up" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="600">

                        <Row lg={12} className='d-flex justify-content-start' >
                            <Col className="d-flex flex-column justify-content-start mt-4 m-3 ">
                                <p style={{ fontSize: '17px', color: '#777777', fontStyle: 'sans-serif' }}>
                                The RGFBA (raheja gurgaon flat buyers association) , thus, is a collective and voluntary effort of the flat buyers of the Raheja Revanta project to:
                                </p>
                                <ul style={{ fontSize: '17px', color: '#777777', fontStyle: 'sans-serif', marginTop: '20px' }}>
                                    <li >Provide a common and unified platform to all buyers and residents of the Tapas Townhouse and Surya Tower Projects to raise common issues related to the project.</li>
                                    <li className='mt-3'> Initiate, track, and take-to conclusion, remedial actions (legal or otherwise) seeking expedited delivery of their dream homes.</li>
                                    <li className='mt-3'>To approach and communicate with competent authorities for redressal of grievances of the members of society.</li>
                                    <li className='mt-3'>Serve as a civic body that represents the interests of the residents in the Revanta (Tapas and Surya) project in the future. For example, taking up issues related to property maintenance, common areas, and safety measures for residents, builder, and govt. bodies engagement etc. as might be necessary.</li>
                                </ul>
                                <p style={{ fontSize: '17px', color: '#777777', fontStyle: 'sans-serif',marginTop:'15px'}}>
                                All flat owners and residents of all towers are welcome to become members of the RGFBA (raheja gurgaon flat buyers association) .
                                </p>
                            </Col>
                        </Row>
                    </div>
                </Row>
            </div>
    </div>
  )
}

export default Mission
