import React from 'react'
import { useState } from "react";
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import { Row, Col } from "react-bootstrap";
// import { Input } from "reactstrap";
// import { FaSearch } from "react-icons/fa";
import Modal from 'react-bootstrap/Modal';
import pdf from "../assets/images/PDF.png"
import MemberBillModel from './MemberBillModel';


export default function MemberBillReceipt() {

    const [show, setShow] = useState(false);

  return (
    <>
        <main>
        <div className='main__container'> 
            <div className="container">
                <div className="row d-flex justify-content-center">
                    <div className="col">
                        <p className="main_title_heading m-0">Bill Receipt</p>
                    </div>
                </div>
                <Row className="col-12 p-0">
                    <div className="flex-wrap d-flex justify-content-center  ">
                                        
                        <Card style={{ padding: '0px', margin: '2px', boxShadow: '0px 8px 18px -6px rgba(24, 39, 75, 0.12),  0px 12px 42px -4px rgba(24, 39, 75, 0.12)', width: '250px', borderRadius: "10px" }} className='pt-3 m-3 border-0'>
                        <div className="d-flex justify-content-center mt-2">
                            <img
                            src={pdf}
                                alt="file"
                                className="rounded-circle mt-0"
                                style={{ width: "50px", height: "50px" }}
                            />
                        </div>
                        <Card.Body >
                            <Card.Title className='text-center card_title'>Member Ship Application Receipt </Card.Title>
                            <Col className="d-flex justify-content-center" >
                            <Button className="cardviewbtn" onClick={() => { setShow(true)}}>
                                View
                            </Button>
                            </Col>
                        </Card.Body>
                        </Card>
                        
                        <Card style={{ padding: '0px', margin: '2px', boxShadow: '0px 8px 18px -6px rgba(24, 39, 75, 0.12),  0px 12px 42px -4px rgba(24, 39, 75, 0.12)', width: '250px', borderRadius: "10px" }} className='pt-3 m-3 border-0'>
                        <div className="d-flex justify-content-center mt-2">
                            <img
                            src={pdf}
                                alt="file"
                                className="rounded-circle mt-0"
                                style={{ width: "50px", height: "50px" }}
                            />
                        </div>
                        <Card.Body >
                            <Card.Title className='text-center card_title'>Member Ship Application Receipt </Card.Title>
                            <Col className="d-flex justify-content-center" >
                            <Button className="cardviewbtn">
                                View
                            </Button>
                            </Col>
                        </Card.Body>
                        </Card>
                            
                        <Card style={{ padding: '0px', margin: '2px', boxShadow: '0px 8px 18px -6px rgba(24, 39, 75, 0.12),  0px 12px 42px -4px rgba(24, 39, 75, 0.12)', width: '250px', borderRadius: "10px" }} className='pt-3 m-3 border-0'>
                        <div className="d-flex justify-content-center mt-2">
                            <img
                            src={pdf}
                                alt="file"
                                className="rounded-circle mt-0"
                                style={{ width: "50px", height: "50px" }}
                            />
                        </div>
                        <Card.Body >
                            <Card.Title className='text-center card_title'>Member Ship Application Receipt </Card.Title>
                            <Col className="d-flex justify-content-center" >
                            <Button className="cardviewbtn">
                                View
                            </Button>
                            </Col>
                        </Card.Body>
                            </Card>
                            
                            <Card style={{ padding: '0px', margin: '2px', boxShadow: '0px 8px 18px -6px rgba(24, 39, 75, 0.12),  0px 12px 42px -4px rgba(24, 39, 75, 0.12)', width: '250px', borderRadius: "10px" }} className='pt-3 m-3 border-0'>
                        <div className="d-flex justify-content-center mt-2">
                            <img
                            src={pdf}
                                alt="file"
                                className="rounded-circle mt-0"
                                style={{ width: "50px", height: "50px" }}
                            />
                        </div>
                        <Card.Body >
                            <Card.Title className='text-center card_title'>Member Ship Application Receipt </Card.Title>
                            <Col className="d-flex justify-content-center" >
                            <Button className="cardviewbtn">
                                View
                            </Button>
                            </Col>
                        </Card.Body>
                        </Card>
                        
                        <Card style={{ padding: '0px', margin: '2px', boxShadow: '0px 8px 18px -6px rgba(24, 39, 75, 0.12),  0px 12px 42px -4px rgba(24, 39, 75, 0.12)', width: '250px', borderRadius: "10px" }} className='pt-3 m-3 border-0'>
                        <div className="d-flex justify-content-center mt-2">
                            <img
                            src={pdf}
                                alt="file"
                                className="rounded-circle mt-0"
                                style={{ width: "50px", height: "50px" }}
                            />
                        </div>
                        <Card.Body >
                            <Card.Title className='text-center card_title'>Member Ship Application Receipt </Card.Title>
                            <Col className="d-flex justify-content-center" >
                            <Button className="cardviewbtn">
                                View
                            </Button>
                            </Col>
                        </Card.Body>
                        </Card>
                        
                        <Card style={{ padding: '0px', margin: '2px', boxShadow: '0px 8px 18px -6px rgba(24, 39, 75, 0.12),  0px 12px 42px -4px rgba(24, 39, 75, 0.12)', width: '250px', borderRadius: "10px" }} className='pt-3 m-3 border-0'>
                        <div className="d-flex justify-content-center mt-2">
                            <img
                            src={pdf}
                                alt="file"
                                className="rounded-circle mt-0"
                                style={{ width: "50px", height: "50px" }}
                            />
                        </div>
                        <Card.Body >
                            <Card.Title className='text-center card_title'>Member Ship Application Receipt </Card.Title>
                            <Col className="d-flex justify-content-center" >
                            <Button className="cardviewbtn">
                                View
                            </Button>
                            </Col>
                        </Card.Body>
                        </Card>
                    
                    </div>
                </Row>
            </div>
            </div>
            <Modal show={show} onHide={() => { setShow(false) }} className="mt-5">
                <Modal.Header closeButton style={{padding:'3px', background:'#13547A', paddingRight:'15px'}}>
                    <Modal.Title style={{fontSize:'20px', marginLeft:'5px', fontFamily:'sans-serif', color:'#ffffff'}}>
                        Bill Receipt
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    <MemberBillModel closeModal={setShow}/>
                </Modal.Body>
            </Modal>
        </main>
    </>
  );
}
