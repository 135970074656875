import React from 'react'
import { useState } from "react";
import Button from 'react-bootstrap/Button';

export default function AlterModel() {

    let [addData, setAddData] = useState({ title: '', description: ''});
    let [checkTitle, setCheckTitle] = useState('');
    let [checkDescription, setCheckDescription] = useState('');

        let changeTitle = (e) => {
            setAddData({ ...addData, title: e.target.value });
            if (e.target.value.trim() === '') {
                setCheckTitle('Title is required');
            } else {
                setCheckTitle('');
            }
        }
        
        let changeDescription = (e) => {
            setAddData({ ...addData, description: e.target.value });
            if (e.target.value.trim() === ''){
                setCheckDescription('Description is required');
            } else{
                setCheckDescription('');
            }
        }
    
    let save = (e) => {
        e.preventDefault();
        let error = 0;

        if (addData.title.trim() === '') {
        error = 1;
        setCheckTitle('Title is required');
        }
        else {
        setCheckTitle('');
        }

        if (addData.description.trim() === '') {
        error = 1;
        setCheckDescription('Description is required');
        }
        else {
        setCheckDescription('');
        }
    }

  return (
    <>
        <div className="container" style={{fontFamily:'sans-serif',fontWeight:'semibold'}}>
            <div className="row">
                <div className="col">
                    <form onSubmit={save}>
                        <div className="card mx-3 mt-2 rounded border-0">
                        <span className='form-text' style={{ fontFamily:'sans-serif', "color": "#000000" , fontWeight: 400 ,"fontSize":'16px' }}>Title*</span>
                            <input
                                type="text"
                                className="form-control border-2 py-3"
                                placeholder="Enter Title"
                                onChange={changeTitle}
                            /> 
                        <small className="text-danger">{checkTitle}</small>
                        </div>
                        <div className="card mx-3 mt-2 rounded border-0" >
                        <span className='form-text' style={{ fontFamily:'sans-serif', "color": "#000000" , fontWeight: 400 ,"fontSize":'16px' }}>Description*</span>
                            <textarea
                                className="form-control border-2 py-3"
                                id="description" name="description" rows="4"
                                cols="50" placeholder="Enter Description"
                                onChange={changeDescription} 
                            />
                        <small className="text-danger">{checkDescription}</small>
                        </div>
                        <div className='row d-flex justify-content-center'>
                            <div className="col-md-6 mt-5">
                                <Button
                                    style={{ borderRadius: "30px" , "color": "#ffffff", fontFamily:'sans-serif' }}
                                    className="btn viewbtn1 w-100 py-3 editbtnsubmit border-0"
                                    type='submit' label='Submit'>
                                    Send
                                </Button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </>
  );
}
