import React from 'react'
import { useState } from "react";
import Modal from 'react-bootstrap/Modal';
import NocModal from './NocModal';
import Button from 'react-bootstrap/Button';

export default function MemberDocuments() {

  const [show, setShow] = useState(false);

  let [addData, setAddData] = useState({ poi: '', poa: '', podob: '', poo: '', pop: '', poijo1: '', poijo2: ''});

  let [checkPoi, setCheckpoi] = useState('');
  let [checkPoa, setCheckpoa] = useState('');
  let [checkPodob, setCheckpodob] = useState('');
  let [checkPoo, setCheckpoo] = useState('');
  let [checkPop, setCheckpop] = useState('');
  let [checkPoijo1, setCheckpoijo1] = useState('');
  let [checkPoijo2, setCheckpoijo2] = useState('');

  let [checkUpoi, setCheckupoi] = useState(false);
  let [checkUpoierror, setCheckupoierror] = useState('');

  let [checkUpoa, setCheckupoa] = useState(false);
  let [checkUpoaerror, setCheckupoaerror] = useState('');

  let [checkUpodob, setCheckupodob] = useState(false);
  let [checkUpodoberror, setCheckupodoberror] = useState('');

  let [checkUpoo, setCheckupoo] = useState(false);
  let [checkUpooerror, setCheckupooerror] = useState('');

  let [checkUpop, setCheckupop] = useState(false);
  let [checkUpoperror, setCheckupoperror] = useState('');

  let [checkUpoijo1, setCheckupoijo1] = useState(false);
  let [checkUpoijo1error, setCheckupoijo1error] = useState('');

  let [checkUnocjo1, setCheckunocjo1] = useState(false);
  let [checkUnocjo1error, setCheckunocjo1error] = useState('');

  let [checkUpoijo2, setCheckupoijo2] = useState(false);
  let [checkUpoijo2error, setCheckupoijo2error] = useState('');

  let [checkUnocjo2, setCheckunocjo2] = useState(false);
  let [checkUnocjo2error, setCheckunocjo2error] = useState('');

  let poiChange = (e) => {
    setAddData({ ...addData, [e.target.name]: e.target.value });
    if (e.target.value === ''){
      setCheckpoi('Select proof of identity');
    } 
    else{
        setCheckpoi('');
    }
  }

  let poaChange = (e) => {
    setAddData({ ...addData, [e.target.name]: e.target.value });
    if (e.target.value === ''){
      setCheckpoa('Select proof of address');
    } 
    else{
        setCheckpoa('');
    }
  }

  let podobChange = (e) => {
    setAddData({ ...addData, [e.target.name]: e.target.value });
    if (e.target.value === ''){
      setCheckpodob('Select proof of date of birth');
    } 
    else{
        setCheckpodob('');
    }
  }

  let pooChange = (e) => {
    setAddData({ ...addData, [e.target.name]: e.target.value });
    if (e.target.value === ''){
      setCheckpoo('Select proof of ownership');
    } 
    else{
        setCheckpoo('');
    }
  }

  let popChange = (e) => {
    setAddData({ ...addData, [e.target.name]: e.target.value });
    if (e.target.value === ''){
      setCheckpop('Select proof of payment');
    } 
    else{
        setCheckpop('');
    }
  }

  let poijo1Change = (e) => {
    setAddData({ ...addData, [e.target.name]: e.target.value });
    if (e.target.value === ''){
      setCheckpoijo1('Select proof of identity of joint owner 1');
    } 
    else{
        setCheckpoijo1('');
    }
  }

  let poijo2Change = (e) => {
    setAddData({ ...addData, [e.target.name]: e.target.value });
    if (e.target.value === ''){
      setCheckpoijo2('Select proof of identity of joint owner 2');
    } 
    else{
        setCheckpoijo2('');
    }
  }

  let changeUpoi = (e) => {
    e.preventDefault();
    if (e.target.files[0].type === 'application/pdf') {
      setCheckupoi(true); setCheckupoierror('');
    } else {
      setCheckupoi(false); setCheckupoierror('Proof of identity is required or invalid');
    }
  }

  let changeUpoa = (e) => {
    e.preventDefault();
    if (e.target.files[0].type === 'application/pdf') {
      setCheckupoa(true); setCheckupoaerror('');
    } else {
      setCheckupoa(false); setCheckupoaerror('Proof of address is required or invalid');
    }
  }

  let changeUpodob = (e) => {
    e.preventDefault();
    if (e.target.files[0].type === 'application/pdf') {
      setCheckupodob(true); setCheckupodoberror('');
    } else {
      setCheckupodob(false); setCheckupodoberror('Proof of date of birth is required or invalid');
    }
  }

  let changeUpoo = (e) => {
    e.preventDefault();
    if (e.target.files[0].type === 'application/pdf') {
      setCheckupoo(true); setCheckupooerror('');
    } else {
      setCheckupoo(false); setCheckupooerror('Proof of ownership is required or invalid');
    }
  }

  let changeUpop = (e) => {
    e.preventDefault();
    if (e.target.files[0].type === 'application/pdf') {
      setCheckupop(true); setCheckupoperror('');
    } else {
      setCheckupop(false); setCheckupoperror('Proof of payment is required or invalid');
    }
  }

  let changeUpoijo1 = (e) => {
    e.preventDefault();
    if (e.target.files[0].type === 'application/pdf') {
      setCheckupoijo1(true); setCheckupoijo1error('');
    } else {
      setCheckupoijo1(false); setCheckupoijo1error('Proof of identity of joint owner 1 is required or invalid');
    }
  }

  let changeUnocjo1 = (e) => {
    e.preventDefault();
    if (e.target.files[0].type === 'application/pdf') {
      setCheckunocjo1(true); setCheckunocjo1error('');
    } else {
      setCheckunocjo1(false); setCheckunocjo1error('Proof of No Objection Certificate of joint owner 1 is required or invalid');
    }
  }

  let changeUpoijo2 = (e) => {
    e.preventDefault();
    if (e.target.files[0].type === 'application/pdf') {
      setCheckupoijo2(true); setCheckupoijo2error('');
    } else {
      setCheckupoijo2(false); setCheckupoijo2error('Proof of identity of joint owner 2 is required or invalid');
    }
  }

  let changeUnocjo2 = (e) => {
    e.preventDefault();
    if (e.target.files[0].type === 'application/pdf') {
      setCheckunocjo2(true); setCheckunocjo2error('');
    } else {
      setCheckunocjo2(false); setCheckunocjo2error('Proof of No Objection Certificate of joint owner 2 is required or invalid');
    }
  }


  const onSubmit = (e) => {
    e.preventDefault();
     let error = 0;

    if (addData.poi === '') {
      error = 1;
      setCheckpoi('Select proof of identity');
    }

    if (addData.poa === '') {
      error = 1;
      setCheckpoa('Select proof of address');
    }

    if (addData.podob === '') {
      error = 1;
      setCheckpodob('Select proof of date of birth');
    }

    if (addData.poo === '') {
      error = 1;
      setCheckpoo('Select proof of ownership');
    }

    if (addData.pop === '') {
      error = 1;
      setCheckpop('Select proof of payment');
    }

    if (addData.poijo1 === '') {
      error = 1;
      setCheckpoijo1('Select proof of identity of joint owner 1');
    }

    if (addData.poijo2 === '') {
      error = 1;
      setCheckpoijo2('Select proof of identity of joint owner 2');
    }

    if (checkUpoi === false) {
      error = 1;
      setCheckupoierror('Proof of identity is required or invalid');
    }
    else {
      setCheckupoierror('');
    }

    if (checkUpoa === false) {
      error = 1;
      setCheckupoaerror('Proof of address is required or invalid');
    }
    else {
      setCheckupoaerror(''); 
    }

    if (checkUpodob === false) {
      error = 1;
      setCheckupodoberror('Proof of date of birth is required or invalid');
    }
    else {
      setCheckupodoberror(''); 
    }

    if (checkUpoo === false) {
      error = 1;
      setCheckupooerror('Proof of ownership is required or invalid');
    }
    else {
      setCheckupooerror(''); 
    }

    if (checkUpop === false) {
      error = 1;
      setCheckupoperror('Proof of payment is required or invalid');
    }
    else {
      setCheckupoperror(''); 
    }

    if (checkUpoijo1 === false) {
      error = 1;
      setCheckupoijo1error('Proof of identity of joint owner 1 is required or invalid');
    }
    else {
      setCheckupoijo1error(''); 
    }

    if (checkUnocjo1 === false) {
      error = 1;
      setCheckunocjo1error('Proof of No Objection Certificate of joint owner 1 is required or invalid');
    }
    else {
      setCheckunocjo1error(''); 
    }

    if (checkUpoijo2 === false) {
      error = 1;
      setCheckupoijo2error('Proof of identity of joint owner 2 is required or invalid');
    }
    else {
      setCheckupoijo2error(''); 
    }

    if (checkUnocjo2 === false) {
      error = 1;
      setCheckunocjo2error('Proof of No Objection Certificate of joint owner 2 is required or invalid');
    }
    else {
      setCheckunocjo2error(''); 
    }
  }
  return (
    <>
          <main>
            <div className='main__container'> 
              <div className="container" style={{fontFamily:'sans-serif' ,fontWeight:'semibold'}}>
                <form onSubmit={onSubmit}>
                  <div className="row">
                        <h4 className="text-center" style={{ fontFamily:'sans-serif', "color": "#13547A" ,"fontSize":'24px' , fontWeight: 600 }}>
                          Member Documents
                        </h4>
                  </div>
                  <div className="row ">
                    <div className="col-sm mt-3 mr-3">
                    <span className='form-text ml-3' style={{ fontFamily:'sans-serif', "color": "#000000" , fontWeight: 400 ,"fontSize":'16px' }}>For proof of identity*</span>
                        <select 
                            name="poi" 
                            style={{ borderRadius: "30px" }}
                            className="form-select py-3 mt-1"
                            onChange={poiChange}>
                                <option disabled selected={true}>Select Document</option>
                                <option value="Aadhaarcard">Aadhaar Card</option>
                                <option value="Pancard">Pan Card</option>
                        </select>
                    <span className='form-text text-danger'>{checkPoi}</span>
                    </div>
                    <div className="col-sm mt-3">
                    <span className='form-text ms-3' style={{ "color": "#000000" ,"fontSize":'15px'  }}>Upload proof of identity*</span>
                    <div className="input-container mt-1">
                        <div className="input-group">
                          <input
                            className="form-control py-3 editinput"
                            type="file"
                            placeholder="document Docs"
                            aria-label="Search"
                            id="inputGroupFile01"
                            name="document"
                            onChange={changeUpoi}
                          />
                          <div className="w-50 editbtn text-center">
                            <label
                              className="pt-3 text-white"
                              htmlFor="inputGroupFile01"
                            >
                              PDF
                            </label>
                          </div>
                        </div>
                        <small className="text-danger">{checkUpoierror}</small>
                      </div>
                    </div>
                    <div className="w-100"></div>
                    <div className="col-sm mt-3 mr-3">
                    <span className='form-text ml-3' style={{ fontFamily:'sans-serif', "color": "#000000" , fontWeight: 400 ,"fontSize":'16px' }}>For proof of address*</span>
                        <select 
                            name="poa" 
                            style={{ borderRadius: "30px" }}
                            className="form-select py-3 mt-1"
                            onChange={poaChange}>
                                <option disabled selected={true}>Select Document</option>
                                <option value="volvo">Aadhaar Card</option>
                                <option value="saab">Pan Card</option>
                          </select>
                          <span className='form-text text-danger'>{checkPoa}</span>
                    </div>
                    <div className="col-sm mt-3">
                    <span className='form-text ms-3' style={{ "color": "#000000" ,"fontSize":'15px'  }}>Upload proof of address*</span>
                    <div className="input-container mt-1">
                        <div className="input-group">
                          <input
                            className="form-control py-3 editinput"
                            type="file"
                            placeholder="document Docs"
                            aria-label="Search"
                            id="inputGroupFile01"
                            name="document"
                            onChange={changeUpoa}
                          />
                          <div className="w-50 editbtn text-center">
                            <label
                              className="pt-3 text-white"
                              htmlFor="inputGroupFile01"
                            >
                            PDF
                            </label>
                          </div>
                        </div>
                        <small className="text-danger">{checkUpoaerror}</small>
                      </div>
                    </div>
                    <div className="w-100"></div>               
                    <div className="col-sm mt-3 mr-3">
                    <span className='form-text ml-3' style={{ fontFamily:'sans-serif', "color": "#000000" , fontWeight: 400 ,"fontSize":'16px' }}>For proof of date of birth*</span>
                        <select 
                            name="podob" 
                            style={{ borderRadius: "30px" }}
                            className="form-select py-3 mt-1"
                            onChange={podobChange}>
                                <option disabled selected={true}>Select Document</option>
                                <option value="volvo">Aadhaar Card</option>
                                <option value="saab">Pan Card</option>
                        </select>
                        <span className='form-text text-danger'>{checkPodob}</span>
                    </div>
                    <div className="col-sm mt-3">
                    <span className='form-text ms-3' style={{ "color": "#000000" ,"fontSize":'15px'  }}>Upload proof of date of birth*</span>
                    <div className="input-container mt-1">
                        <div className="input-group">
                          <input
                            className="form-control py-3 editinput"
                            type="file"
                            placeholder="document Docs"
                            aria-label="Search"
                            id="inputGroupFile01"
                            name="document"
                            onChange={changeUpodob}
                          />
                          <div className="w-50 editbtn text-center">
                            <label
                              className="pt-3 text-white"
                              htmlFor="inputGroupFile01"
                            >
                            PDF
                            </label>
                          </div>
                        </div>
                        <small className="text-danger">{checkUpodoberror}</small>
                      </div>
                    </div>
                    <div className="w-100"></div>
                    <div className="col-sm mt-3 mr-3">
                    <span className='form-text ml-3' style={{ fontFamily:'sans-serif', "color": "#000000" , fontWeight: 400 ,"fontSize":'16px' }}>For proof of ownership*</span>
                        <select 
                            name="poo" 
                            style={{ borderRadius: "30px" }}
                            className="form-select py-3 mt-1"
                            onChange={pooChange}>
                                <option disabled selected={true}>Select Document</option>
                                <option value="volvo">Aadhaar Card</option>
                                <option value="saab">Pan Card</option>
                        </select>
                        <span className='form-text text-danger'>{checkPoo}</span>
                    </div>
                    <div className="col-sm mt-3">
                    <span className='form-text ms-3' style={{ "color": "#000000" ,"fontSize":'15px'  }}>Upload proof of ownership*</span>
                    <div className="input-container mt-1">
                        <div className="input-group">
                          <input
                            className="form-control py-3 editinput"
                            type="file"
                            placeholder="document Docs"
                            aria-label="Search"
                            id="inputGroupFile01"
                            name="document"
                            onChange={changeUpoo}
                          />
                          <div className="w-50 editbtn text-center">
                            <label
                              className="pt-3 text-white"
                              htmlFor="inputGroupFile01"
                            >
                            PDF
                            </label>
                          </div>
                        </div>
                        <small className="text-danger">{checkUpooerror}</small>
                      </div>
                    </div>
                    <div className="w-100"></div>
                    <div className="col-sm mt-3 mr-3">
                    <span className='form-text ml-3' style={{ fontFamily:'sans-serif', "color": "#000000" , fontWeight: 400 ,"fontSize":'16px' }}>For proof of payment*</span>
                        <select 
                            name="pop" 
                            style={{ borderRadius: "30px" }}
                            className="form-select py-3 mt-1"
                            onChange={popChange}>
                                <option disabled selected={true}>Select Document</option>
                                <option value="volvo">Aadhaar Card</option>
                                <option value="saab">Pan Card</option>
                      </select>
                      <span className='form-text text-danger'>{checkPop}</span>
                    </div>
                    <div className="col-sm mt-3">
                    <span className='form-text ms-3' style={{ "color": "#000000" ,"fontSize":'15px'  }}>Upload proof of payment*</span>
                    <div className="input-container mt-1">
                        <div className="input-group">
                          <input
                            className="form-control py-3 editinput"
                            type="file"
                            placeholder="document Docs"
                            aria-label="Search"
                            id="inputGroupFile01"
                            name="document"
                            onChange={changeUpop}
                          />
                          <div className="w-50 editbtn text-center">
                            <label
                              className="pt-3 text-white"
                              htmlFor="inputGroupFile01"
                            >
                            PDF
                            </label>
                          </div>
                        </div>
                        <small className="text-danger">{checkUpoperror}</small>
                      </div>
                    </div>
                    <div className="w-100"></div>
                    <span className='form-text mt-3 ' style={{ fontFamily:'sans-serif', "color": "#13547A" , fontWeight: 600 ,"fontSize":'18px' }}>Only to be filled in case of joint owner</span>
                    <div className="col-sm mt-3 mr-3">
                    <span className='form-text ml-3' style={{ fontFamily:'sans-serif', "color": "#000000" , fontWeight: 400 ,"fontSize":'16px' }}>For proof of identity of joint owner 1</span>
                        <select 
                            name="poijo1" 
                            style={{ borderRadius: "30px" }}
                            className="form-select py-3 mt-1"
                            onChange={poijo1Change}>
                                <option disabled selected={true}>Select Document</option>
                                <option value="volvo">Aadhaar Card</option>
                                <option value="saab">Pan Card</option>
                        </select>
                        <span className='form-text text-danger'>{checkPoijo1}</span>
                    </div>
                    <div className="col-sm mt-3">
                    <span className='form-text ms-3' style={{ "color": "#000000" ,"fontSize":'15px'  }}>Upload proof of identity of joint owner 1</span>
                    <div className="input-container mt-1">
                        <div className="input-group">
                          <input
                            className="form-control py-3 editinput"
                            type="file"
                            placeholder="document Docs"
                            aria-label="Search"
                            id="inputGroupFile01"
                            name="document"
                            onChange={changeUpoijo1}
                          />
                          <div className="w-50 editbtn text-center">
                            <label
                              className="pt-3 text-white"
                              htmlFor="inputGroupFile01"
                            >
                            PDF
                            </label>
                          </div>
                        </div>
                        <small className="text-danger">{checkUpoijo1error}</small>
                      </div>
                    </div>
                    <div className="w-100"></div>
                    <div className="col-sm mt-3 mr-3 ml-3">
                    <input type="checkbox" id="same_as_permanent_address" name="same_as_permanent_address" value="same_as_permanent_address" className='me-2' />
                    <label for="same_as_permanent_address" style={{ fontFamily:'sans-serif', "color": "#13547A" , fontWeight: 400 ,"fontSize":'16px' }}>No Objection Certificate of joint owner 1</label>
                    </div>
                    <div className="w-100"></div>           
                    <div className="col-sm mt-3 mr-3">
                        <Button
                            style={{ backgroundColor: "#13547A", borderRadius: "30px" , "color": "#ffffff" }}
                            className="btn w-100 py-3"
                            onClick={() => { setShow(true)}}>
                            Download Certificate
                        </Button>
                    </div>
                    <div className="col-sm mt-3">
                    <div className="input-container mt-1">
                        <div className="input-group">
                          <input
                            className="form-control py-3 editinput"
                            type="file"
                            placeholder="document Docs"
                            aria-label="Search"
                            id="inputGroupFile01"
                            name="document"
                            onChange={changeUnocjo1}
                          />
                          <div className="w-50 editbtn text-center">
                            <label
                              className="pt-3 text-white"
                              htmlFor="inputGroupFile01"
                            >
                            PDF
                            </label>
                          </div>
                        </div>
                        <small className="text-danger">{checkUnocjo1error}</small>
                      </div>
                    </div>
                    <div className="w-100"></div>              
                    <div className="col-sm mt-3 mr-3">
                    <span className='form-text ml-3' style={{ fontFamily:'sans-serif', "color": "#000000" , fontWeight: 400 ,"fontSize":'16px' }}>For proof of identity of joint owner 2</span>
                        <select 
                            name="poijo2" 
                            style={{ borderRadius: "30px" }}
                            className="form-select py-3 mt-1"
                            onChange={poijo2Change}>
                                <option disabled selected={true}>Select Document</option>
                                <option value="volvo">Aadhaar Card</option>
                                <option value="saab">Pan Card</option>
                        </select>
                        <span className='form-text text-danger'>{checkPoijo2}</span>
                    </div>
                    <div className="col-sm mt-3">
                    <span className='form-text ms-3' style={{ "color": "#000000" ,"fontSize":'15px'  }}>Upload proof of identity of joint owner 2</span>
                    <div className="input-container mt-1">
                        <div className="input-group">
                          <input
                            className="form-control py-3 editinput"
                            type="file"
                            placeholder="document Docs"
                            aria-label="Search"
                            id="inputGroupFile01"
                            name="document"
                            onChange={changeUpoijo2}
                          />
                          <div className="w-50 editbtn text-center">
                            <label
                              className="pt-3 text-white"
                              htmlFor="inputGroupFile01"
                            >
                            PDF
                            </label>
                          </div>
                        </div>
                        <small className="text-danger">{checkUpoijo2error}</small>
                      </div>
                    </div>
                    <div className="w-100"></div>
                    <div className="col-sm mt-3 mr-3 ml-3">
                    <input type="checkbox" id="same_as_permanent_address" name="same_as_permanent_address" value="same_as_permanent_address" className='me-2' />
                    <label for="same_as_permanent_address" style={{ fontFamily:'sans-serif', "color": "#13547A" , fontWeight: 400 ,"fontSize":'16px' }}>No Objection Certificate of joint owner 2</label>
                    </div>
                    <div className="w-100"></div>                
                    <div className="col-sm mt-3 mr-3">
                        <Button
                            onClick={() => { setShow(true)}}
                            style={{ backgroundColor: "#13547A", borderRadius: "30px" , "color": "#ffffff" }}
                            className="btn w-100 py-3">
                            Download Certificate
                        </Button>
                    </div>
                    <div className="col-sm mt-3">
                    <div className="input-container mt-1">
                        <div className="input-group">
                          <input
                            className="form-control py-3 editinput"
                            type="file"
                            placeholder="document Docs"
                            aria-label="Search"
                            id="inputGroupFile01"
                            name="document"
                            onChange={changeUnocjo2}
                          />
                          <div className="w-50 editbtn text-center">
                            <label
                              className="pt-3 text-white"
                              htmlFor="inputGroupFile01"
                            >
                            PDF
                            </label>
                          </div>
                        </div>
                        <small className="text-danger">{checkUnocjo2error}</small>
                      </div>
                    </div>
                    <div className="w-100"></div>       
                  </div>
                  <div className='row d-flex justify-content-center'>
                    <div className="col-md-6 mt-5">
                      <button
                          // to="/applicationformsuccessfull"
                          style={{ borderRadius: "30px" , "color": "#ffffff", fontFamily:'sans-serif' }}
                          className="btn viewbtn1 w-100 py-3 editbtnsubmit border-0"
                          type='submit' label='Submit'>
                          Submit
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
              <Modal show={show} onHide={() => { setShow(false) }} className="mt-5">
                    <Modal.Header closeButton style={{padding:'3px', background:'#13547A', paddingRight:'15px'}}>
                        <Modal.Title style={{fontSize:'20px', marginLeft:'5px', fontFamily:'sans-serif', color:'#ffffff'}}>
                            NOC Certificate
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body >
                        <NocModal closeModal={setShow}/>
                    </Modal.Body>
              </Modal>
          </main>
    </>
  );
}
