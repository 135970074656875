// import { HiCamera } from "react-icons/hi"
import React, { useState } from "react";
import Modal from 'react-bootstrap/Modal';
import NocModal from '../../InnerComponets/applicationForm/NocModal';
import Button from 'react-bootstrap/Button';

export default function MemberEditProfile() {
  
  const [show, setShow] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [ownerType, setOwnertype] = useState(true);
  
  let [addData, setAddData] = useState({
    firstname: 'Aayush Jain', fathername: 'Ajay Kumar Jain',
    paddress: 'PU4 Vijay Nagar', pcity: 'Indore',
    pstate: 'Madhya Pradesh', pcountry: 'India',
    ppostcode: '484110', caddress: 'Applo Tower',
    ccity: 'Indore', cstate: 'Madhya Pradesh',
    ccountry: 'India', cpostcode: '484110',
    phonenumber: '7509304100', dob: '29/07/1996',
    pannumber: 'ABCTY1234D', email: 'aayushj@tech-radix.com',
    unitnumber: '678/AE', occupation: 'Business',

    jo_firstname: 'Piyush Jain', jo_fathername: 'Ajay Kumar Jain',
    jo_paddress: 'PU4 Vijay Nagar', jo_pcity: 'Indore',
    jo_pstate: 'Madhya Pradesh', jo_pcountry: 'India',
    jo_ppostcode: '484110', jo_caddress: 'Applo Tower',
    jo_ccity: 'Indore', jo_cstate: 'Madhya Pradesh',
    jo_ccountry: 'India', jo_cpostcode: '484110',
    jo_phonenumber: '7509304100', jo_dob: '29/07/1996',
    jo_email: 'aayushj@tech-radix.com', jo_occupation: 'Business',

    sjo_firstname: 'Seema Jain', sjo_fathername: 'Gyan Chandra jain',
    sjo_paddress: 'PU4 Vijay Nagar', sjo_pcity: 'Indore',
    sjo_pstate: 'Madhya Pradesh', sjo_pcountry: 'India',
    sjo_ppostcode: '484110', sjo_caddress: 'Applo Tower',
    sjo_ccity: 'Indore', sjo_cstate: 'Madhya Pradesh',
    sjo_ccountry: 'India', sjo_cpostcode: '484110',
    sjo_phonenumber: '7509304100', sjo_dob: '29/07/1996',
    sjo_email: 'aayushj@tech-radix.com', sjo_occupation: 'Business',

    date: '23/04/1998', place: 'Burhar',
    
    poi: '', poa: '', podob: '', poo: '', pop: '', poijo1: '', poijo2: ''
    
  });

  let [checkFirstName, setCheckFirstName] = useState('');
  let [checkFatherName, setCheckFatherName] = useState('');
  let [checkPaddress, setCheckPaddress] = useState('');
  let [checkPcity, setCheckPcity] = useState('');
  let [checkPstate, setCheckPstate] = useState('');
  let [checkPcountry, setCheckPcountry] = useState('');
  let [checkPpostcode, setCheckPpostcode] = useState('');
  let [checkCaddress, setCheckCaddress] = useState('');
  let [checkCcity, setCheckCcity] = useState('');
  let [checkCstate, setCheckCstate] = useState('');
  let [checkCcountry, setCheckCcountry] = useState('');
  let [checkCpostcode, setCheckCpostcode] = useState('');
  let [checkPhonenumber, setCheckPhonenumber] = useState('');
  let [checkDob, setCheckDob] = useState('');
  let [checkPannumber, setCheckPannumber] = useState('');
  let [checkEmail, setCheckEmail] = useState('');
  let [checkUnitnumber, setCheckUnitnumber] = useState('');
  let [checkOccupation, setCheckOccupation] = useState('');

  let [checkJO_FirstName, setCheckJO_FirstName] = useState('');
  let [checkJO_FatherName, setCheckJO_FatherName] = useState('');
  let [checkJO_Paddress, setCheckJO_Paddress] = useState('');
  let [checkJO_Pcity, setCheckJO_Pcity] = useState('');
  let [checkJO_Pstate, setCheckJO_Pstate] = useState('');
  let [checkJO_Pcountry, setCheckJO_Pcountry] = useState('');
  let [checkJO_Ppostcode, setCheckJO_Ppostcode] = useState('');
  let [checkJO_Caddress, setCheckJO_Caddress] = useState('');
  let [checkJO_Ccity, setCheckJO_Ccity] = useState('');
  let [checkJO_Cstate, setCheckJO_Cstate] = useState('');
  let [checkJO_Ccountry, setCheckJO_Ccountry] = useState('');
  let [checkJO_Cpostcode, setCheckJO_Cpostcode] = useState('');
  let [checkJO_Phonenumber, setCheckJO_Phonenumber] = useState('');
  let [checkJO_Dob, setCheckJO_Dob] = useState('');
  let [checkJO_Email, setCheckJO_Email] = useState('');
  let [checkJO_Occupation, setCheckJO_Occupation] = useState('');

  let [checkSJO_FirstName, setCheckSJO_FirstName] = useState('');
  let [checkSJO_FatherName, setCheckSJO_FatherName] = useState('');
  let [checkSJO_Paddress, setCheckSJO_Paddress] = useState('');
  let [checkSJO_Pcity, setCheckSJO_Pcity] = useState('');
  let [checkSJO_Pstate, setCheckSJO_Pstate] = useState('');
  let [checkSJO_Pcountry, setCheckSJO_Pcountry] = useState('');
  let [checkSJO_Ppostcode, setCheckSJO_Ppostcode] = useState('');
  let [checkSJO_Caddress, setCheckSJO_Caddress] = useState('');
  let [checkSJO_Ccity, setCheckSJO_Ccity] = useState('');
  let [checkSJO_Cstate, setCheckSJO_Cstate] = useState('');
  let [checkSJO_Ccountry, setCheckSJO_Ccountry] = useState('');
  let [checkSJO_Cpostcode, setCheckSJO_Cpostcode] = useState('');
  let [checkSJO_Phonenumber, setCheckSJO_Phonenumber] = useState('');
  let [checkSJO_Dob, setCheckSJO_Dob] = useState('');
  let [checkSJO_Email, setCheckSJO_Email] = useState('');
  let [checkSJO_Occupation, setCheckSJO_Occupation] = useState('');
  
  let [checkDate, setCheckDate] = useState('');
  let [checkPlace, setCheckPlace] = useState('');
  let [checkSignature, setCheckSignature] = useState(false);
  let [checkSignatureerror, setCheckSignatureerror] = useState('');
    
  let [checkPoi, setCheckpoi] = useState('');
  let [checkPoa, setCheckpoa] = useState('');
  let [checkPodob, setCheckpodob] = useState('');
  let [checkPoo, setCheckpoo] = useState('');
  let [checkPop, setCheckpop] = useState('');
  let [checkPoijo1, setCheckpoijo1] = useState('');
  let [checkPoijo2, setCheckpoijo2] = useState('');

  let [checkUpoi, setCheckupoi] = useState(false);
  let [checkUpoierror, setCheckupoierror] = useState('');

  let [checkUpoa, setCheckupoa] = useState(false);
  let [checkUpoaerror, setCheckupoaerror] = useState('');

  let [checkUpodob, setCheckupodob] = useState(false);
  let [checkUpodoberror, setCheckupodoberror] = useState('');

  let [checkUpoo, setCheckupoo] = useState(false);
  let [checkUpooerror, setCheckupooerror] = useState('');

  let [checkUpop, setCheckupop] = useState(false);
  let [checkUpoperror, setCheckupoperror] = useState('');

  let [checkUpoijo1, setCheckupoijo1] = useState(false);
  let [checkUpoijo1error, setCheckupoijo1error] = useState('');

  let [checkUnocjo1, setCheckunocjo1] = useState(false);
  let [checkUnocjo1error, setCheckunocjo1error] = useState('');

  let [checkUpoijo2, setCheckupoijo2] = useState(false);
  let [checkUpoijo2error, setCheckupoijo2error] = useState('');

  let [checkUnocjo2, setCheckunocjo2] = useState(false);
  let [checkUnocjo2error, setCheckunocjo2error] = useState('');


let changeFirstName = (e) => {
  setAddData({ ...addData, firstname: e.target.value });
  if (e.target.value.trim() === '') {
      setCheckFirstName('First Name is required');
  } else {
      setCheckFirstName('');
  }
}
  
let changeFatherName = (e) => {
  setAddData({ ...addData, fathername: e.target.value });
  if (e.target.value.trim() === '') {
      setCheckFatherName('Father’s / Husband’s Name is required');
  } else {
      setCheckFatherName('');
  }
}
  
let changePaddress = (e) => {
  setAddData({ ...addData, paddress: e.target.value });
  if (e.target.value.trim() === '') {
      setCheckPaddress('Address is required');
  } else {
      setCheckPaddress('');
  }
  }

  let changePcity = (e) => {
    setAddData({ ...addData, pcity : e.target.value });
    if (e.target.value.trim() === '') {
        setCheckPcity('City Name is required');
    } else {
        setCheckPcity('');
    }
  }

  let changePstate = (e) => {
    setAddData({ ...addData, pstate: e.target.value });
    if (e.target.value.trim() === '') {
        setCheckPstate('State Name is required');
    } else {
        setCheckPstate('');
    }
  }

  let changePcountry = (e) => {
    setAddData({ ...addData, pcountry: e.target.value });
    if (e.target.value.trim() === '') {
        setCheckPcountry('Country Name is required');
    } else {
        setCheckPcountry('');
    }
  }

  let changePpostcode = (e) => {
    setAddData({ ...addData, ppostcode: e.target.value });
    if (e.target.value.trim() === '') {
        setCheckPpostcode('Postcode is required');
    } else {
        setCheckPpostcode(''); 
    }
  }

  let changeCaddress = (e) => {
    setAddData({ ...addData, caddress: e.target.value });
    if (e.target.value.trim() === '') {
        setCheckCaddress('Address is required');
    } else {
        setCheckCaddress('');
    }
    }
  
    let changeCcity = (e) => {
      setAddData({ ...addData, ccity : e.target.value });
      if (e.target.value.trim() === '') {
          setCheckCcity('City Name is required');
      } else {
          setCheckCcity('');
      }
    }
  
    let changeCstate = (e) => {
      setAddData({ ...addData, cstate: e.target.value });
      if (e.target.value.trim() === '') {
          setCheckCstate('State Name is required');
      } else {
          setCheckCstate('');
      }
    }
  
    let changeCcountry = (e) => {
      setAddData({ ...addData, ccountry: e.target.value });
      if (e.target.value.trim() === '') {
          setCheckCcountry('Country Name is required');
      } else {
          setCheckCcountry('');
      }
    }
  
    let changeCpostcode = (e) => {
      setAddData({ ...addData, cpostcode: e.target.value });
      if (e.target.value.trim() === '') {
          setCheckCpostcode('Postcode is required');
      } else {
          setCheckCpostcode('');
      }
  }
  
  let changePhonenumber = (e) => {
    setAddData({ ...addData, phonenumber: e.target.value });
    if (e.target.value.length !== 10 || Number(e.target.value) < 6000000000) {
        setCheckPhonenumber('Mobile Number is required');
    } else {
        setCheckPhonenumber('');
    }
  }

 let changedob = (e) => {
        setAddData({ ...addData, dob: e.target.value });
        if (e.target.value.trim() === ''){
            setCheckDob('Date of birth is required'); } 
        else{
            setCheckDob('');
        }
  }
  
  let changePannumber = (e) => {
    setAddData({ ...addData, pannumber: e.target.value });
    if (e.target.value.trim() === ''){
        setCheckPannumber('Pan Number is required'); } 
    else{
        setCheckPannumber('');
    }
  }

  let changeEmail = (e) => {
    setAddData({ ...addData, email: e.target.value });
    if (!(RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(e.target.value))){
        setCheckEmail('Email is not valid or required'); } 
    else{
        setCheckEmail('');
    }
  }
  
  let changeUnitnumber = (e) => {
    setAddData({ ...addData, unitnumber: e.target.value });
    if (e.target.value.trim() === ''){
        setCheckUnitnumber('Unit Number is required'); } 
    else{
        setCheckUnitnumber('');
    }
  }

  let changeOccupation = (e) => {
    setAddData({ ...addData, occupation: e.target.value });
    if (e.target.value.trim() === ''){
        setCheckOccupation('Occupation is required'); } 
    else{
        setCheckOccupation('');
    }
  }


  let changeJO_FirstName = (e) => {
    setAddData({ ...addData, jo_firstname: e.target.value });
    if (e.target.value.trim() === '') {
        setCheckJO_FirstName('First Name is required');
    } else {
        setCheckJO_FirstName('');
    }
  }
    
  let changeJO_FatherName = (e) => {
    setAddData({ ...addData, jo_fathername: e.target.value });
    if (e.target.value.trim() === '') {
        setCheckJO_FatherName('Father’s / Husband’s Name is required');
    } else {
        setCheckJO_FatherName('');
    }
  }
    
  let changeJO_Paddress = (e) => {
    setAddData({ ...addData, jo_paddress: e.target.value });
    if (e.target.value.trim() === '') {
        setCheckJO_Paddress('Address is required');
    } else {
        setCheckJO_Paddress('');
    }
    }
  
    let changeJO_Pcity = (e) => {
      setAddData({ ...addData, jo_pcity : e.target.value });
      if (e.target.value.trim() === '') {
          setCheckJO_Pcity('City Name is required');
      } else {
          setCheckJO_Pcity('');
      }
    }
  
    let changeJO_Pstate = (e) => {
      setAddData({ ...addData, jo_pstate: e.target.value });
      if (e.target.value.trim() === '') {
          setCheckJO_Pstate('State Name is required');
      } else {
          setCheckJO_Pstate('');
      }
    }
  
    let changeJO_Pcountry = (e) => {
      setAddData({ ...addData, jo_pcountry: e.target.value });
      if (e.target.value.trim() === '') {
          setCheckJO_Pcountry('Country Name is required');
      } else {
          setCheckJO_Pcountry('');
      }
    }
  
    let changeJO_Ppostcode = (e) => {
      setAddData({ ...addData, jo_ppostcode: e.target.value });
      if (e.target.value.trim() === '') {
          setCheckJO_Ppostcode('Postcode is required');
      } else {
          setCheckJO_Ppostcode('');
      }
    }
  
    let changeJO_Caddress = (e) => {
      setAddData({ ...addData, jo_caddress: e.target.value });
      if (e.target.value.trim() === '') {
          setCheckJO_Caddress('Address is required');
      } else {
          setCheckJO_Caddress('');
      }
      }
    
      let changeJO_Ccity = (e) => {
        setAddData({ ...addData, jo_ccity : e.target.value });
        if (e.target.value.trim() === '') {
            setCheckJO_Ccity('City Name is required');
        } else {
            setCheckJO_Ccity('');
        }
      }
    
      let changeJO_Cstate = (e) => {
        setAddData({ ...addData, jo_cstate: e.target.value });
        if (e.target.value.trim() === '') {
            setCheckJO_Cstate('State Name is required');
        } else {
            setCheckJO_Cstate('');
        }
      }
    
      let changeJO_Ccountry = (e) => {
        setAddData({ ...addData, jo_ccountry: e.target.value });
        if (e.target.value.trim() === '') {
            setCheckJO_Ccountry('Country Name is required');
        } else {
            setCheckJO_Ccountry('');
        }
      }
    
      let changeJO_Cpostcode = (e) => {
        setAddData({ ...addData, jo_cpostcode: e.target.value });
        if (e.target.value.trim() === '') {
            setCheckJO_Cpostcode('Postcode is required');
        } else {
            setCheckJO_Cpostcode('');
        }
    }
    
    let changeJO_Phonenumber = (e) => {
      setAddData({ ...addData, jo_phonenumber: e.target.value });
      if (e.target.value.length !== 10 || Number(e.target.value) < 6000000000) {
          setCheckJO_Phonenumber('Mobile Number is required');
      } else {
          setCheckJO_Phonenumber('');
      }
    }
  
   let changeJO_dob = (e) => {
          setAddData({ ...addData, jo_dob: e.target.value });
          if (e.target.value.trim() === ''){
              setCheckJO_Dob('Date of birth is required'); } 
          else{
              setCheckJO_Dob('');
          }
    }
  
    let changeJO_Email = (e) => {
      setAddData({ ...addData, jo_email: e.target.value });
      if (!(RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(e.target.value))){
          setCheckJO_Email('Email is not valid or required'); } 
      else{
          setCheckJO_Email('');
      }
    }
  
    let changeJO_Occupation = (e) => {
      setAddData({ ...addData, jo_occupation: e.target.value });
      if (e.target.value.trim() === ''){
          setCheckJO_Occupation('Occupation is required'); } 
      else{
          setCheckJO_Occupation('');
      }
  }
  

  let changeSJO_FirstName = (e) => {
    setAddData({ ...addData, sjo_firstname: e.target.value });
    if (e.target.value.trim() === '') {
        setCheckSJO_FirstName('First Name is required');
    } else {
        setCheckSJO_FirstName('');
    }
  }
    
  let changeSJO_FatherName = (e) => {
    setAddData({ ...addData, sjo_fathername: e.target.value });
    if (e.target.value.trim() === '') {
        setCheckSJO_FatherName('Father’s / Husband’s Name is required');
    } else {
        setCheckSJO_FatherName('');
    }
  }
    
  let changeSJO_Paddress = (e) => {
    setAddData({ ...addData, sjo_paddress: e.target.value });
    if (e.target.value.trim() === '') {
        setCheckSJO_Paddress('Address is required');
    } else {
        setCheckSJO_Paddress('');
    }
    }
  
    let changeSJO_Pcity = (e) => {
      setAddData({ ...addData, sjo_pcity : e.target.value });
      if (e.target.value.trim() === '') {
          setCheckSJO_Pcity('City Name is required');
      } else {
          setCheckSJO_Pcity('');
      }
    }
  
    let changeSJO_Pstate = (e) => {
      setAddData({ ...addData, sjo_pstate: e.target.value });
      if (e.target.value.trim() === '') {
          setCheckSJO_Pstate('State Name is required');
      } else {
          setCheckSJO_Pstate('');
      }
    }
  
    let changeSJO_Pcountry = (e) => {
      setAddData({ ...addData, sjo_pcountry: e.target.value });
      if (e.target.value.trim() === '') {
          setCheckSJO_Pcountry('Country Name is required');
      } else {
          setCheckSJO_Pcountry('');
      }
    }
  
    let changeSJO_Ppostcode = (e) => {
      setAddData({ ...addData, sjo_ppostcode: e.target.value });
      if (e.target.value.trim() === '') {
          setCheckSJO_Ppostcode('Postcode is required');
      } else {
          setCheckSJO_Ppostcode('');
      }
    }
  
    let changeSJO_Caddress = (e) => {
      setAddData({ ...addData, sjo_caddress: e.target.value });
      if (e.target.value.trim() === '') {
          setCheckSJO_Caddress('Address is required');
      } else {
          setCheckSJO_Caddress('');
      }
      }
    
      let changeSJO_Ccity = (e) => {
        setAddData({ ...addData, sjo_ccity : e.target.value });
        if (e.target.value.trim() === '') {
            setCheckSJO_Ccity('City Name is required');
        } else {
            setCheckSJO_Ccity('');
        }
      }
    
      let changeSJO_Cstate = (e) => {
        setAddData({ ...addData, sjo_cstate: e.target.value });
        if (e.target.value.trim() === '') {
            setCheckSJO_Cstate('State Name is required');
        } else {
            setCheckSJO_Cstate('');
        }
      }
    
      let changeSJO_Ccountry = (e) => {
        setAddData({ ...addData, sjo_ccountry: e.target.value });
        if (e.target.value.trim() === '') {
            setCheckSJO_Ccountry('Country Name is required');
        } else {
            setCheckSJO_Ccountry('');
        }
      }
    
      let changeSJO_Cpostcode = (e) => {
        setAddData({ ...addData, sjo_cpostcode: e.target.value });
        if (e.target.value.trim() === '') {
            setCheckSJO_Cpostcode('Postcode is required');
        } else {
            setCheckSJO_Cpostcode('');
        }
    }
    
    let changeSJO_Phonenumber = (e) => {
      setAddData({ ...addData, sjo_phonenumber: e.target.value });
      if (e.target.value.length !== 10 || Number(e.target.value) < 6000000000) {
          setCheckSJO_Phonenumber('Mobile Number is required');
      } else {
          setCheckSJO_Phonenumber('');
      }
    }
  
   let changeSJO_dob = (e) => {
          setAddData({ ...addData, sjo_dob: e.target.value });
          if (e.target.value.trim() === ''){
              setCheckSJO_Dob('Date of birth is required'); } 
          else{
              setCheckSJO_Dob('');
          }
    }
  
    let changeSJO_Email = (e) => {
      setAddData({ ...addData, sjo_email: e.target.value });
      if (!(RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(e.target.value))){
          setCheckSJO_Email('Email is not valid or required'); } 
      else{
          setCheckSJO_Email('');
      }
    }
  
    let changeSJO_Occupation = (e) => {
      setAddData({ ...addData, sjo_occupation: e.target.value });
      if (e.target.value.trim() === ''){
          setCheckSJO_Occupation('Occupation is required'); } 
      else{
          setCheckSJO_Occupation('');
      }
  }

  let changeSignature = (e) => {
    e.preventDefault();
    if (e.target.files[0].type === 'application/pdf') {
      setCheckSignature(true); setCheckSignatureerror('');
    } else {
      setCheckSignature(false); setCheckSignatureerror('Signature is required or invalid');
    }
  }
  

  let changeDate = (e) => {
    setAddData({ ...addData, date: e.target.value });
    if (e.target.value.trim() === ''){
        setCheckDate('Date is required'); } 
    else{
        setCheckDate('');
    }
}

let changePlace = (e) => {
  setAddData({ ...addData, place: e.target.value });
  if (e.target.value.trim() === ''){
      setCheckPlace('Place is required'); } 
  else{
      setCheckPlace('');
  }
    }




    let poiChange = (e) => {
        setAddData({ ...addData, [e.target.name]: e.target.value });
        if (e.target.value === ''){
          setCheckpoi('Select proof of identity');
        } 
        else{
            setCheckpoi('');
        }
      }
    
      let poaChange = (e) => {
        setAddData({ ...addData, [e.target.name]: e.target.value });
        if (e.target.value === ''){
          setCheckpoa('Select proof of address');
        } 
        else{
            setCheckpoa('');
        }
      }
    
      let podobChange = (e) => {
        setAddData({ ...addData, [e.target.name]: e.target.value });
        if (e.target.value === ''){
          setCheckpodob('Select proof of date of birth');
        } 
        else{
            setCheckpodob('');
        }
      }
    
      let pooChange = (e) => {
        setAddData({ ...addData, [e.target.name]: e.target.value });
        if (e.target.value === ''){
          setCheckpoo('Select proof of ownership');
        } 
        else{
            setCheckpoo('');
        }
      }
    
      let popChange = (e) => {
        setAddData({ ...addData, [e.target.name]: e.target.value });
        if (e.target.value === ''){
          setCheckpop('Select proof of payment');
        } 
        else{
            setCheckpop('');
        }
      }
    
      let poijo1Change = (e) => {
        setAddData({ ...addData, [e.target.name]: e.target.value });
        if (e.target.value === ''){
          setCheckpoijo1('Select proof of identity of joint owner 1');
        } 
        else{
            setCheckpoijo1('');
        }
      }
    
      let poijo2Change = (e) => {
        setAddData({ ...addData, [e.target.name]: e.target.value });
        if (e.target.value === ''){
          setCheckpoijo2('Select proof of identity of joint owner 2');
        } 
        else{
            setCheckpoijo2('');
        }
      }
    
    let changeUpoi = (e) => {
        e.preventDefault();
        if (e.target.files[0].type === 'application/pdf') {
          setCheckupoi(true); setCheckupoierror('');
        } else {
          setCheckupoi(false); setCheckupoierror('Proof of identity is required or invalid');
        }
      }
    
      let changeUpoa = (e) => {
        e.preventDefault();
        if (e.target.files[0].type === 'application/pdf') {
          setCheckupoa(true); setCheckupoaerror('');
        } else {
          setCheckupoa(false); setCheckupoaerror('Proof of address is required or invalid');
        }
      }
    
      let changeUpodob = (e) => {
        e.preventDefault();
        if (e.target.files[0].type === 'application/pdf') {
          setCheckupodob(true); setCheckupodoberror('');
        } else {
          setCheckupodob(false); setCheckupodoberror('Proof of date of birth is required or invalid');
        }
      }
    
      let changeUpoo = (e) => {
        e.preventDefault();
        if (e.target.files[0].type === 'application/pdf') {
          setCheckupoo(true); setCheckupooerror('');
        } else {
          setCheckupoo(false); setCheckupooerror('Proof of ownership is required or invalid');
        }
      }
    
      let changeUpop = (e) => {
        e.preventDefault();
        if (e.target.files[0].type === 'application/pdf') {
          setCheckupop(true); setCheckupoperror('');
        } else {
          setCheckupop(false); setCheckupoperror('Proof of payment is required or invalid');
        }
      }
    
      let changeUpoijo1 = (e) => {
        e.preventDefault();
        if (e.target.files[0].type === 'application/pdf') {
          setCheckupoijo1(true); setCheckupoijo1error('');
        } else {
          setCheckupoijo1(false); setCheckupoijo1error('Proof of identity of joint owner 1 is required or invalid');
        }
      }
    
      let changeUnocjo1 = (e) => {
        e.preventDefault();
        if (e.target.files[0].type === 'application/pdf') {
          setCheckunocjo1(true); setCheckunocjo1error('');
        } else {
          setCheckunocjo1(false); setCheckunocjo1error('Proof of No Objection Certificate of joint owner 1 is required or invalid');
        }
      }
    
      let changeUpoijo2 = (e) => {
        e.preventDefault();
        if (e.target.files[0].type === 'application/pdf') {
          setCheckupoijo2(true); setCheckupoijo2error('');
        } else {
          setCheckupoijo2(false); setCheckupoijo2error('Proof of identity of joint owner 2 is required or invalid');
        }
      }
    
      let changeUnocjo2 = (e) => {
        e.preventDefault();
        if (e.target.files[0].type === 'application/pdf') {
          setCheckunocjo2(true); setCheckunocjo2error('');
        } else {
          setCheckunocjo2(false); setCheckunocjo2error('Proof of No Objection Certificate of joint owner 2 is required or invalid');
        }
      }
  


  let save = (e) => {
    e.preventDefault();
    let error = 0;
    // let me = "";
    // let ee = "";

    if (addData.firstname.trim() === '') {
      error = 1;
      setCheckFirstName('First name is required');
    }
    else {
      setCheckFirstName('');
    }

    if (addData.fathername.trim() === '') {
      error = 1;
      setCheckFatherName('Father name is required');
    }
    else {
      setCheckFatherName('');
    }

    if (addData.paddress.trim() === '') {
      error = 1;
      setCheckPaddress('Parmenent address is required');
    }
    else {
      setCheckPaddress('');
    }

    if (addData.pcity.trim() === '') {
      error = 1;
      setCheckPcity('Parmenent city is required');
    }
    else {
      setCheckPcity('');
    }

    if (addData.pstate.trim() === '') {
      error = 1;
      setCheckPstate('Parmenent state is required');
    }
    else {
      setCheckPstate('');
    }

    if (addData.pcountry.trim() === '') {
      error = 1;
      setCheckPcountry('Parmenent country is required');
    }
    else {
      setCheckPcountry('');
    }

    if (addData.ppostcode.trim() === '') {
      error = 1;
      setCheckPpostcode('Parmenent postcode is required');
    }
    else {
      setCheckPpostcode('');
    }


    if (addData.caddress.trim() === '') {
      error = 1;
      setCheckCaddress('Correspondence address is required');
    }
    else {
      setCheckCaddress('');
    }

    if (addData.ccity.trim() === '') {
      error = 1;
      setCheckCcity('Correspondence city is required');
    }
    else {
      setCheckCcity('');
    }

    if (addData.cstate.trim() === '') {
      error = 1;
      setCheckCstate('Correspondence state is required');
    }
    else {
      setCheckCstate('');
    }

    if (addData.ccountry.trim() === '') {
      error = 1;
      setCheckCcountry('Correspondence country is required');
    }
    else {
      setCheckCcountry('');
    }

    if (addData.cpostcode.trim() === '') {
      error = 1;
      setCheckCpostcode('Correspondence postcode is required');
    }
    else {
      setCheckCpostcode('');
    }

    if (Number(addData.phonenumber) <= 6000000000 || Number(addData.phonenumber) >= 9999999999) {
      error = 1;
      setCheckPhonenumber('Mobile number is required or invalid');
    } else {
      setCheckPhonenumber('');
    }

    if (addData.dob.trim() === '') {
      error = 1;
      setCheckDob('Date of birth is required');
    }
    else {
      setCheckDob('');
    }

    if (addData.pannumber.trim() === '') {
      error = 1;
      setCheckPannumber('Pan number is required');
    }
    else {
      setCheckPannumber('');
    }

    if (addData.email.trim() === '') {
      error = 1;
      setCheckEmail('Email is required or invalid');
    } else {
      setCheckEmail('');
    }

    if (addData.unitnumber.trim() === '') {
      error = 1;
      setCheckUnitnumber('Unit number is required');
    }
    else {
      setCheckUnitnumber('');
    }

    if (addData.occupation.trim() === '') {
      error = 1;
      setCheckOccupation('Occupation is required');
    }
    else {
      setCheckOccupation('');
    }

    if (checkSignature === false ) {
      error = 1;
      setCheckSignatureerror('Signature is required or invalid');
    }
    else {
      setCheckSignatureerror('');
    }

    if (addData.place.trim() === '') {
      error = 1;
      setCheckPlace('Place is required');
    }
    else {
      setCheckPlace('');
    }

    if (addData.date.trim() === '') {
      error = 1;
      setCheckDate('Date is required');
    }
    else {
      setCheckDate('');
    }

    
    if (ownerType === false) {

      if (isOpen === true) {
      if (addData.jo_firstname.trim() === '') {
        error = 1;
        setCheckJO_FirstName('First name is required');
      }
      else {
        setCheckJO_FirstName('');
      }

      if (addData.jo_fathername.trim() === '') {
        error = 1;
        setCheckJO_FatherName('Father name is required');
      }
      else {
        setCheckJO_FatherName('');
      }

      if (addData.jo_paddress.trim() === '') {
        error = 1;
        setCheckJO_Paddress('Parmenent address is required');
      }
      else {
        setCheckJO_Paddress('');
      }

      if (addData.jo_pcity.trim() === '') {
        error = 1;
        setCheckJO_Pcity('Parmenent city is required');
      }
      else {
        setCheckJO_Pcity('');
      }

      if (addData.jo_pstate.trim() === '') {
        error = 1;
        setCheckJO_Pstate('Parmenent state is required');
      }
      else {
        setCheckJO_Pstate('');
      }

      if (addData.jo_pcountry.trim() === '') {
        error = 1;
        setCheckJO_Pcountry('Parmenent country is required');
      }
      else {
        setCheckJO_Pcountry('');
      }

      if (addData.jo_ppostcode.trim() === '') {
        error = 1;
        setCheckJO_Ppostcode('Parmenent postcode is required');
      }
      else {
        setCheckJO_Ppostcode('');
      }


      if (addData.jo_caddress.trim() === '') {
        error = 1;
        setCheckJO_Caddress('Correspondence address is required');
      }
      else {
        setCheckJO_Caddress('');
      }

      if (addData.jo_ccity.trim() === '') {
        error = 1;
        setCheckJO_Ccity('Correspondence city is required');
      }
      else {
        setCheckJO_Ccity('');
      }

      if (addData.jo_cstate.trim() === '') {
        error = 1;
        setCheckJO_Cstate('Correspondence state is required');
      }
      else {
        setCheckJO_Cstate('');
      }

      if (addData.jo_ccountry.trim() === '') {
        error = 1;
        setCheckJO_Ccountry('Correspondence country is required');
      }
      else {
        setCheckJO_Ccountry('');
      }

      if (addData.jo_cpostcode.trim() === '') {
        error = 1;
        setCheckJO_Cpostcode('Correspondence postcode is required');
      }
      else {
        setCheckJO_Cpostcode('');
      }

      if (Number(addData.jo_phonenumber) <= 6000000000 || Number(addData.jo_phonenumber) >= 9999999999) {
        error = 1;
        setCheckJO_Phonenumber('Mobile number is required or invalid');
      } else {
        setCheckJO_Phonenumber('');
      }

      if (addData.jo_dob.trim() === '') {
        error = 1;
        setCheckJO_Dob('Date of birth is required');
      }
      else {
        setCheckJO_Dob('');
      }

      if (addData.jo_email.trim() === '') {
        error = 1;
        setCheckJO_Email('Email is required or invalid');
      } else {
        setCheckJO_Email('');
      }

      if (addData.jo_occupation.trim() === '') {
        error = 1;
        setCheckJO_Occupation('Occupation is required');
      }
      else {
        setCheckJO_Occupation('');
      }
    }


if(isOpen2===true){
      if (addData.sjo_firstname.trim() === '') {
        error = 1;
        setCheckSJO_FirstName('First name is required');
      }
      else {
        setCheckSJO_FirstName('');
      }

      if (addData.sjo_fathername.trim() === '') {
        error = 1;
        setCheckSJO_FatherName('Father name is required');
      }
      else {
        setCheckSJO_FatherName('');
      }

      if (addData.sjo_paddress.trim() === '') {
        error = 1;
        setCheckSJO_Paddress('Parmenent address is required');
      }
      else {
        setCheckSJO_Paddress('');
      }

      if (addData.sjo_pcity.trim() === '') {
        error = 1;
        setCheckSJO_Pcity('Parmenent city is required');
      }
      else {
        setCheckSJO_Pcity('');
      }

      if (addData.sjo_pstate.trim() === '') {
        error = 1;
        setCheckSJO_Pstate('Parmenent state is required');
      }
      else {
        setCheckSJO_Pstate('');
      }

      if (addData.sjo_pcountry.trim() === '') {
        error = 1;
        setCheckSJO_Pcountry('Parmenent country is required');
      }
      else {
        setCheckSJO_Pcountry('');
      }

      if (addData.sjo_ppostcode.trim() === '') {
        error = 1;
        setCheckSJO_Ppostcode('Parmenent postcode is required');
      }
      else {
        setCheckSJO_Ppostcode('');
      }


      if (addData.sjo_caddress.trim() === '') {
        error = 1;
        setCheckSJO_Caddress('Correspondence address is required');
      }
      else {
        setCheckSJO_Caddress('');
      }

      if (addData.sjo_ccity.trim() === '') {
        error = 1;
        setCheckSJO_Ccity('Correspondence city is required');
      }
      else {
        setCheckSJO_Ccity('');
      }

      if (addData.sjo_cstate.trim() === '') {
        error = 1;
        setCheckSJO_Cstate('Correspondence state is required');
      }
      else {
        setCheckSJO_Cstate('');
      }

      if (addData.sjo_ccountry.trim() === '') {
        error = 1;
        setCheckSJO_Ccountry('Correspondence country is required');
      }
      else {
        setCheckSJO_Ccountry('');
      }

      if (addData.sjo_cpostcode.trim() === '') {
        error = 1;
        setCheckSJO_Cpostcode('Correspondence postcode is required');
      }
      else {
        setCheckSJO_Cpostcode('');
      }

      if (Number(addData.sjo_phonenumber) <= 6000000000 || Number(addData.sjo_phonenumber) >= 9999999999) {
        error = 1;
        setCheckSJO_Phonenumber('Mobile number is required or invalid');
      } else {
        setCheckSJO_Phonenumber('');
      }

      if (addData.sjo_dob.trim() === '') {
        error = 1;
        setCheckSJO_Dob('Date of birth is required');
      }
      else {
        setCheckSJO_Dob('');
      }

      if (addData.sjo_email.trim() === '') {
        error = 1;
        setCheckSJO_Email('Email is required or invalid');
      } else {
        setCheckSJO_Email('');
      }

      if (addData.sjo_occupation.trim() === '') {
        error = 1;
        setCheckSJO_Occupation('Occupation is required');
      }
      else {
        setCheckSJO_Occupation('');
      }
    }
      }
      
      if (addData.poi === '') {
        error = 1;
        setCheckpoi('Select proof of identity');
      }
  
      if (addData.poa === '') {
        error = 1;
        setCheckpoa('Select proof of address');
      }
  
      if (addData.podob === '') {
        error = 1;
        setCheckpodob('Select proof of date of birth');
      }
  
      if (addData.poo === '') {
        error = 1;
        setCheckpoo('Select proof of ownership');
      }
  
      if (addData.pop === '') {
        error = 1;
        setCheckpop('Select proof of payment');
      }
  
      if (addData.poijo1 === '') {
        error = 1;
        setCheckpoijo1('Select proof of identity of joint owner 1');
      }
  
      if (addData.poijo2 === '') {
        error = 1;
        setCheckpoijo2('Select proof of identity of joint owner 2');
      }
  
      if (checkUpoi === false) {
        error = 1;
        setCheckupoierror('Proof of identity is required or invalid');
      }
      else {
        setCheckupoierror('');
      }
  
      if (checkUpoa === false) {
        error = 1;
        setCheckupoaerror('Proof of address is required or invalid');
      }
      else {
        setCheckupoaerror(''); 
      }
  
      if (checkUpodob === false) {
        error = 1;
        setCheckupodoberror('Proof of date of birth is required or invalid');
      }
      else {
        setCheckupodoberror(''); 
      }
  
      if (checkUpoo === false) {
        error = 1;
        setCheckupooerror('Proof of ownership is required or invalid');
      }
      else {
        setCheckupooerror(''); 
      }
  
      if (checkUpop === false) {
        error = 1;
        setCheckupoperror('Proof of payment is required or invalid');
      }
      else {
        setCheckupoperror(''); 
      }
  
      if (checkUpoijo1 === false) {
        error = 1;
        setCheckupoijo1error('Proof of identity of joint owner 1 is required or invalid');
      }
      else {
        setCheckupoijo1error(''); 
      }
  
      if (checkUnocjo1 === false) {
        error = 1;
        setCheckunocjo1error('Proof of No Objection Certificate of joint owner 1 is required or invalid');
      }
      else {
        setCheckunocjo1error(''); 
      }
  
      if (checkUpoijo2 === false) {
        error = 1;
        setCheckupoijo2error('Proof of identity of joint owner 2 is required or invalid');
      }
      else {
        setCheckupoijo2error(''); 
      }
  
      if (checkUnocjo2 === false) {
        error = 1;
        setCheckunocjo2error('Proof of No Objection Certificate of joint owner 2 is required or invalid');
      }
      else {
        setCheckunocjo2error(''); 
      }

  }

  let resetFunction = () => {
    setIsOpen(false);
    setCheckJO_FirstName('');
    setCheckJO_FatherName('');
    setCheckJO_Paddress('');
    setCheckJO_Pcity('');
    setCheckJO_Pstate('');
    setCheckJO_Pcountry('');
    setCheckJO_Ppostcode('');
    setCheckJO_Caddress('');
    setCheckJO_Ccity('');
    setCheckJO_Cstate('');
    setCheckJO_Ccountry('');
    setCheckJO_Cpostcode('');
    setCheckJO_Phonenumber('');
    setCheckJO_Dob('');
    setCheckJO_Email('');
    setCheckJO_Occupation('');
  }

  let resetFunction2 = () => {
    setIsOpen2(false);
    setCheckSJO_FirstName('');
    setCheckSJO_FatherName('');
    setCheckSJO_Paddress('');
    setCheckSJO_Pcity('');
    setCheckSJO_Pstate('');
    setCheckSJO_Pcountry('');
    setCheckSJO_Ppostcode('');
    setCheckSJO_Caddress('');
    setCheckSJO_Ccity('');
    setCheckSJO_Cstate('');
    setCheckSJO_Ccountry('');
    setCheckSJO_Cpostcode('');
    setCheckSJO_Phonenumber('');
    setCheckSJO_Dob('');
    setCheckSJO_Email('');
    setCheckSJO_Occupation('');
  }

  let addressCopy = (e) => {
    if (e.target.checked === true) {
      setAddData({ ...addData, caddress: addData.paddress, ccity: addData.pcity, cstate: addData.pstate, ccountry: addData.pcountry, cpostcode: addData.ppostcode });
      checkPaddress.trim() === '' && setCheckCaddress('');
      checkPcity.trim() === '' && setCheckCcity('');
      checkPstate.trim() === '' && setCheckCstate('');
      checkPcountry.trim() === '' && setCheckCcountry('');
      checkPpostcode.trim() === '' && setCheckCpostcode('');
    } else {
      setAddData({ ...addData, caddress: (''), ccity: (''), cstate: (''), ccountry: (''), cpostcode: ('') });
    }
  }

  let jop_addressCopy = (e) => {
    if (e.target.checked === true) {
      setAddData({ ...addData, jo_paddress: addData.paddress, jo_pcity: addData.pcity, jo_pstate: addData.pstate, jo_pcountry: addData.pcountry, jo_ppostcode: addData.ppostcode });
      checkPaddress.trim() === '' && setCheckJO_Paddress('');
      checkPcity.trim() === '' && setCheckJO_Pcity('');
      checkPstate.trim() === '' && setCheckJO_Pstate('');
      checkPcountry.trim() === '' && setCheckJO_Pcountry('');
      checkPpostcode.trim() === '' && setCheckJO_Ppostcode('');
    } else {
      setAddData({ ...addData, jo_paddress: (''), jo_pcity: (''), jo_pstate: (''), jo_pcountry: (''), jo_ppostcode: ('') });
    }
  }
  
  let joc_addressCopy = (e) => {
    if (e.target.checked === true) {
      setAddData({ ...addData, jo_caddress: addData.jo_paddress, jo_ccity: addData.jo_pcity, jo_cstate: addData.jo_pstate, jo_ccountry: addData.jo_pcountry, jo_cpostcode: addData.jo_ppostcode });
      checkJO_Paddress.trim() === '' && setCheckJO_Caddress('');
      checkJO_Pcity.trim() === '' && setCheckJO_Ccity('');
      checkJO_Pstate.trim() === '' && setCheckJO_Cstate('');
      checkJO_Pcountry.trim() === '' && setCheckJO_Ccountry('');
      checkJO_Ppostcode.trim() === '' && setCheckJO_Cpostcode('');
    } else {
      setAddData({ ...addData, jo_caddress: (''), jo_ccity: (''), jo_cstate: (''), jo_ccountry: (''), jo_cpostcode: ('') });
    }
  }
  
  let sjop_addressCopy = (e) => {
    if (e.target.checked === true) {
      setAddData({ ...addData, sjo_paddress: addData.paddress, sjo_pcity: addData.pcity, sjo_pstate: addData.pstate, sjo_pcountry: addData.pcountry, sjo_ppostcode: addData.ppostcode });
      checkPaddress.trim() === '' && setCheckSJO_Paddress('');
      checkPcity.trim() === '' && setCheckSJO_Pcity('');
      checkPstate.trim() === '' && setCheckSJO_Pstate('');
      checkPcountry.trim() === '' && setCheckSJO_Pcountry('');
      checkPpostcode.trim() === '' && setCheckSJO_Ppostcode('');
    } else {
      setAddData({ ...addData, sjo_paddress: (''), sjo_pcity: (''), sjo_pstate: (''), sjo_pcountry: (''), sjo_ppostcode: ('') });
    }
  }


  let sjoc_addressCopy = (e) => {
    if (e.target.checked === true) {
      setAddData({ ...addData, sjo_caddress: addData.sjo_paddress, sjo_ccity: addData.sjo_pcity, sjo_cstate: addData.sjo_pstate, sjo_ccountry: addData.sjo_pcountry, sjo_cpostcode: addData.sjo_ppostcode });
      checkSJO_Paddress.trim() === '' && setCheckSJO_Caddress('');
      checkSJO_Pcity.trim() === '' && setCheckSJO_Ccity('');
      checkSJO_Pstate.trim() === '' && setCheckSJO_Cstate('');
      checkSJO_Pcountry.trim() === '' && setCheckSJO_Ccountry('');
      checkSJO_Ppostcode.trim() === '' && setCheckSJO_Cpostcode('');
    } else {
      setAddData({ ...addData, sjo_caddress: (''), sjo_ccity: (''), sjo_cstate: (''), sjo_ccountry: (''), sjo_cpostcode: ('') });
    }
  }
  
  let clearField = () => {
    setAddData({ ...addData, jo_paddress: (''), jo_pcity: (''), jo_pstate: (''), jo_pcountry: (''), jo_ppostcode: (''), jo_caddress: (''), jo_ccity: (''), jo_cstate: (''), jo_ccountry: (''), jo_cpostcode: (''), sjo_paddress: (''), sjo_pcity: (''), sjo_pstate: (''), sjo_pcountry: (''), sjo_ppostcode: ('') , sjo_caddress: (''), sjo_ccity: (''), sjo_cstate: (''), sjo_ccountry: (''), sjo_cpostcode: ('')   });
  }
  
  let resetField1 = () => {
    setAddData({ ...addData, jo_paddress: (''), jo_pcity: (''), jo_pstate: (''), jo_pcountry: (''), jo_ppostcode: (''), jo_caddress: (''), jo_ccity: (''), jo_cstate: (''), jo_ccountry: (''), jo_cpostcode: ('')  });
  }

  let resetField2 = () => {
    setAddData({ ...addData, sjo_paddress: (''), sjo_pcity: (''), sjo_pstate: (''), sjo_pcountry: (''), sjo_ppostcode: (''), sjo_caddress: (''), sjo_ccity: (''), sjo_cstate: (''), sjo_ccountry: (''), sjo_cpostcode: ('')  });
  }

  return (
    <>
        <main>
          <div className='main__container'>
          <div className="container" style={{fontFamily:'sans-serif' ,fontWeight:'semibold'}}>
            <form onSubmit={save}>
                <div className="row">
                    <h4 className="text-center" style={{ fontFamily:'sans-serif', "color": "#13547A" ,"fontSize":'24px' , fontWeight: 600  }}>
                      Edit Member Profile
                    </h4>
                    <div className="text-center">
                      {
                        /* {image !== "" ? <img
                        alt="profile"
                        src={image}
                        className="img-fluid mt-3 rounded-circle "
                        style={{ width: "100px", height: "100px" }}
                      /> : */
                        <img
                          alt="profile"
                          src={require("../../InnerComponets/assets/images/profilee.jpg")}
                          className="img-fluid rounded-circle "
                          style={{ width: "100px", height: "100px" }}
                        />
                      }
                      <input type="file" accept="image/*" className='d-none' name="image-upload" id="input"
                        // onChange={imageHandler} 
                        />
                      {/* <div className="label">
                        <label className="image-upload font-bold mb-3" htmlFor="input">
                          <span className='camicon'><HiCamera /></span></label>
                      </div> */}
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm mt-3 mr-3">
                    <span className='form-text ml-3' style={{ fontFamily:'sans-serif', "color": "#000000" , fontWeight: 400 ,"fontSize":'16px' }}>Name of the Applicant*</span>
                      <input
                        style={{ borderRadius: "30px" }}
                        type="text"
                        className="form-control py-3 mt-1"
                        placeholder="Enter Applicant Name"
                        onChange={changeFirstName}
                        defaultValue={addData.firstname}
                    />
                    <small className="text-danger">{checkFirstName}</small>
                    </div>
                    <div className="col-sm mt-3 mr-3">
                    <span className='form-text ml-3' style={{ fontFamily:'sans-serif', "color": "#000000" , fontWeight: 400 ,"fontSize":'16px' }}>Father’s / Husband’s Name*</span>
                      <input
                        style={{ borderRadius: "30px" }}
                        type="text"
                        className="form-control py-3 mt-1"
                        placeholder="Enter Father’s / Husband’s Name"
                        onChange={changeFatherName}
                        defaultValue={addData.fathername}
                    />
                    <small className="text-danger">{checkFatherName}</small>
                    </div>
                    <div className="w-100"></div>
                    <span className='form-text mt-3 ' style={{ fontFamily:'sans-serif', "color": "#13547A" , fontWeight: 600 ,"fontSize":'18px' }}>Permanent Address</span>
                    <div className="col-sm mt-3 mr-3">
                    <span className='form-text ml-3' style={{ fontFamily:'sans-serif', "color": "#000000" , fontWeight: 400 ,"fontSize":'16px' }}>Address*</span>
                      <input
                        style={{ borderRadius: "30px" }}
                        type="text"
                        className="form-control py-3 mt-1 "
                        placeholder="Enter Address"
                        onChange={changePaddress}
                        defaultValue={addData.paddress}
                    />
                    <small className="text-danger">{checkPaddress}</small>
                    </div>
                    <div className="col-sm mt-3 mr-3">
                    <span className='form-text ml-3' style={{ fontFamily:'sans-serif', "color": "#000000" , fontWeight: 400 ,"fontSize":'16px' }}>City*</span>
                      <input
                        style={{ borderRadius: "30px" }}
                        type="text"
                        className="form-control py-3 mt-1 "
                        placeholder="Enter City"
                        onChange={changePcity}
                        defaultValue={addData.pcity}
                    />
                    <small className="text-danger">{checkPcity}</small>
                    </div>
                    <div className="w-100"></div>
                    <div className="col-sm mt-3 mr-3">
                    <span className='form-text ml-3' style={{ fontFamily:'sans-serif', "color": "#000000" , fontWeight: 400 ,"fontSize":'16px' }}>State*</span>
                      <input
                        style={{ borderRadius: "30px" }}
                        type="text"
                        className="form-control py-3 mt-1 "
                        placeholder="Enter State"
                        onChange={changePstate}
                        defaultValue={addData.pstate}
                    />
                    <small className="text-danger">{checkPstate}</small>
                    </div>
                    <div className="col-sm mt-3 mr-3">
                    <span className='form-text ml-3' style={{ fontFamily:'sans-serif', "color": "#000000" , fontWeight: 400 ,"fontSize":'16px' }}>Country*</span>
                      <input
                        style={{ borderRadius: "30px" }}
                        type="text"
                        className="form-control py-3 mt-1 "
                        placeholder="Enter Country"
                        onChange={changePcountry}
                        defaultValue={addData.pcountry}
                    />
                    <small className="text-danger">{checkPcountry}</small>
                    </div>
                    <div className="w-100"></div>
                    <div className="col-sm mt-3 mr-3">
                    <span className='form-text ml-3' style={{ fontFamily:'sans-serif', "color": "#000000" , fontWeight: 400 ,"fontSize":'16px' }}>Postcode*</span>
                      <input
                        style={{ borderRadius: "30px" }}
                        type="text"
                        className="form-control py-3 mt-1 "
                        placeholder="Enter Postcode"
                        onChange={changePpostcode}
                        defaultValue={addData.ppostcode}
                    />
                    <small className="text-danger">{checkPpostcode}</small>
                    </div>
                    <div className="col-sm"></div>
                    <div className="w-100"></div>
                    <div className="col-sm mt-3 mr-3">
                        <span className='form-text mt-3 ' style={{ fontFamily:'sans-serif', "color": "#13547A" , fontWeight: 600 ,"fontSize":'18px' }}>Correspondence Address</span>
                    </div>
                    <div className="col-sm mt-3 mr-3">
                        <input type="checkbox" onClick={addressCopy} id="same_as_permanent_address" name="same_as_permanent_address" value="same_as_permanent_address" className='me-2' />
                        <label for="same_as_permanent_address" style={{ fontFamily:'sans-serif', "color": "#000000" , fontWeight: 400 ,"fontSize":'16px' }}> Same as Permanent Address</label>
                    </div>
                    <div className="w-100"></div>
                    <div className="col-sm mt-3 mr-3">
                    <span className='form-text ml-3' style={{ fontFamily:'sans-serif', "color": "#000000" , fontWeight: 400 ,"fontSize":'16px' }}>Address*</span>
                      <input
                        style={{ borderRadius: "30px" }}
                        type="text"
                        className="form-control py-3 mt-1"
                        placeholder="Enter Address"
                        onChange={changeCaddress}
                        defaultValue={addData.caddress}
                    />
                    <small className="text-danger">{checkCaddress}</small>
                    </div>
                    <div className="col-sm mt-3 mr-3">
                    <span className='form-text ml-3' style={{ fontFamily:'sans-serif', "color": "#000000" , fontWeight: 400 ,"fontSize":'16px' }}>City*</span>
                      <input
                        style={{ borderRadius: "30px" }}
                        type="text"
                        className="form-control py-3 mt-1"
                        placeholder="Enter City"
                      onChange={changeCcity}
                      defaultValue={addData.ccity}
                    />
                    <small className="text-danger">{checkCcity}</small>
                    </div>
                    <div className="w-100"></div>
                    <div className="col-sm mt-3 mr-3">
                    <span className='form-text ml-3' style={{ fontFamily:'sans-serif', "color": "#000000" , fontWeight: 400 ,"fontSize":'16px' }}>State*</span>
                      <input
                        style={{ borderRadius: "30px" }}
                        type="text"
                        className="form-control py-3 mt-1"
                        placeholder="Enter State"
                      onChange={changeCstate}
                      defaultValue={addData.cstate}
                    />
                    <small className="text-danger">{checkCstate}</small>
                    </div>
                    <div className="col-sm mt-3 mr-3">
                    <span className='form-text ml-3' style={{ fontFamily:'sans-serif', "color": "#000000" , fontWeight: 400 ,"fontSize":'16px' }}>Country*</span>
                      <input
                        style={{ borderRadius: "30px" }}
                        type="text"
                        className="form-control py-3 mt-1 "
                        placeholder="Enter Country"
                      onChange={changeCcountry}
                      defaultValue={addData.ccountry}
                    />
                    <small className="text-danger">{checkCcountry}</small>
                    </div>
                    <div className="w-100"></div>
                    <div className="col-sm mt-3 mr-3">
                    <span className='form-text ml-3' style={{ fontFamily:'sans-serif', "color": "#000000" , fontWeight: 400 ,"fontSize":'16px' }}>Postcode*</span>
                      <input
                        style={{ borderRadius: "30px" }}
                        type="text"
                        className="form-control py-3 mt-1 "
                        placeholder="Enter Postcode"
                        onChange={changeCpostcode}
                        defaultValue={addData.cpostcode}
                      />
                      <small className="text-danger">{checkCpostcode}</small>
                    </div>
                    <div className="col-sm"></div>
                    <div className="w-100"></div>
                    <div className="col-sm mt-3 mr-3">
                    <span className='form-text ml-3' style={{ fontFamily:'sans-serif', "color": "#000000" , fontWeight: 400 ,"fontSize":'16px' }}>Phone Number With Code*</span>
                      <input
                        style={{ borderRadius: "30px" }}
                        type="text"
                        className="form-control py-3 mt-1 "
                        placeholder="Enter phone number"
                        onChange={changePhonenumber}
                        defaultValue={addData.pcity}
                    />
                    <small className="text-danger">{checkPhonenumber}</small>
                    </div>
                    <div className="col-sm mt-3 mr-3">
                    <span className='form-text ml-3' style={{ fontFamily:'sans-serif', "color": "#000000" , fontWeight: 400 ,"fontSize":'16px' }}>Date Of Birth* </span>
                      <input
                        style={{ borderRadius: "30px" }}
                        type="date"
                        name="first_name"
                        className="form-control py-3 mt-1 "
                        placeholder="Enter Date Of Birth" 
                        onChange={changedob}
                        defaultValue={addData.dob}
                      />
                    <small className="text-danger">{checkDob}</small>
                    </div>
                    <div className="w-100"></div>
                    <div className="col-sm mt-3 mr-3">
                    <span className='form-text ml-3' style={{ fontFamily:'sans-serif', "color": "#000000" , fontWeight: 400 ,"fontSize":'16px' }}>Pan number*</span>
                      <input
                        style={{ borderRadius: "30px" }}
                        type="text"
                        name="first_name"
                        className="form-control py-3 mt-1 "
                        placeholder="Enter Pan Number" 
                        onChange={changePannumber}
                        defaultValue={addData.pannumber}
                    />
                    <small className="text-danger">{checkPannumber}</small>
                    </div>
                    <div className="col-sm mt-3 mr-3">
                    <span className='form-text ml-3' style={{ fontFamily:'sans-serif', "color": "#000000" , fontWeight: 400 ,"fontSize":'16px' }}>Email ID*</span>
                      <input
                        style={{ borderRadius: "30px" }}
                        type="text"
                        name="first_name"
                        className="form-control py-3 mt-1 "
                        placeholder="Enter Email ID"
                        onChange={changeEmail}
                        defaultValue={addData.email}
                    />
                      <small className="text-danger">{checkEmail}</small>
                    </div>
                    <div className="w-100"></div>
                    <div className="col-sm mt-3 mr-3">
                    <span className='form-text ml-3' style={{ fontFamily:'sans-serif', "color": "#000000" , fontWeight: 400 ,"fontSize":'16px' }}>Unit Number*</span>
                      <input
                        style={{ borderRadius: "30px" }}
                        type="text"
                        name="first_name"
                        className="form-control py-3 mt-1 "
                        placeholder="Enter Unit Number"
                        onChange={changeUnitnumber}
                        defaultValue={addData.unitnumber}
                    />
                    <small className="text-danger">{checkUnitnumber}</small>
                    </div>
                    <div className="col-sm mt-3 mr-3">
                    <span className='form-text ml-3' style={{ fontFamily:'sans-serif', "color": "#000000" , fontWeight: 400 ,"fontSize":'16px' }}>Occupation*</span>
                      <input
                        style={{ borderRadius: "30px" }}
                        type="text"
                        name="first_name"
                        className="form-control py-3 mt-1 "
                        placeholder="Enter Occupation" 
                        onChange={changeOccupation}
                        defaultValue={addData.occupation}
                      />
                      <small className="text-danger">{checkOccupation}</small>
                    </div>
                    <div className="w-100"></div>
                    <div className="col-sm mt-3 mr-3">
                    <span className='form-text ml-3' style={{ fontFamily:'sans-serif', "color": "#000000" , fontWeight: 400 ,"fontSize":'16px' }}>Owner Type*</span><br />
                    <input onClick={() => { setIsOpen(false); setIsOpen2(false); setOwnertype(true); resetFunction(); resetFunction2(); clearField() }} className='mt-3' type="radio" id="single" name="owner_type" value="single" />
                      <label for="html">Single</label>
                    <input className="ml-3" onClick={() => { setIsOpen(true); setOwnertype(false) }} type="radio" id="joint" name="owner_type" value="joint" />
                      <label for="css">Joint</label><br />
                    </div>
                    <div className="col-sm"></div>
                  <div className="w-100"></div>
                  
                  {isOpen ? <>
                    <span className='form-text mt-3 ' style={{ fontFamily: 'sans-serif', "color": "#13547A", fontWeight: 600, "fontSize": '18px' }}>Joint Owner Brief Particulars are as under</span>
                    <div className="col-sm mt-3 mr-3">
                    <span className='form-text ml-3' style={{ fontFamily:'sans-serif', "color": "#000000" , fontWeight: 400 ,"fontSize":'16px' }}>Name of the Applicant*</span>
                      <input
                        style={{ borderRadius: "30px" }}
                        type="text"
                        name="first_name"
                        className="form-control py-3 mt-1"
                        placeholder="Enter Applicant Name"
                        onChange={changeJO_FirstName}
                        defaultValue={addData.jo_firstname}
                      />
                      <small className="text-danger">{checkJO_FirstName}</small>
                    </div>
                    <div className="col-sm mt-3 mr-3">
                    <span className='form-text ml-3' style={{ fontFamily:'sans-serif', "color": "#000000" , fontWeight: 400 ,"fontSize":'16px' }}>Father’s / Husband’s Name*</span>
                    <input
                        style={{ borderRadius: "30px" }}
                        type="text"
                        className="form-control py-3 mt-1"
                        placeholder="Enter Father’s / Husband’s Name"
                        onChange={changeJO_FatherName}
                        defaultValue={addData.jo_fathername}
                    />
                    <small className="text-danger">{checkJO_FatherName}</small>
                    </div>
                    <div className="w-100"></div>
                    <div className="col-sm mt-3 mr-3">
                    <span className='form-text mt-3 ' style={{ fontFamily:'sans-serif', "color": "#13547A" , fontWeight: 600 ,"fontSize":'18px' }}>Permanent Address</span>
                    </div>
                    <div className="col-sm mt-3 mr-3">
                    <input onClick={jop_addressCopy} type="checkbox" id="same_as_primary_applicant" name="same_as_primary_applicant" value="same_as_primary_applicant" className='me-2' />
                    <label for="same_as_primary_applicant" style={{ fontFamily:'sans-serif', "color": "#000000" , fontWeight: 400 ,"fontSize":'16px' }}> Same as Primary Applicant</label>
                    </div>
                    <div className="w-100"></div>
                    <div className="col-sm mt-3 mr-3">
                    <span className='form-text ml-3' style={{ fontFamily:'sans-serif', "color": "#000000" , fontWeight: 400 ,"fontSize":'16px' }}>Address*</span>              
                      <input
                        style={{ borderRadius: "30px" }}
                        type="text"
                        className="form-control py-3 mt-1 "
                        placeholder="Enter Address"
                        onChange={changeJO_Paddress}
                        defaultValue={addData.jo_paddress}
                    />
                    <small className="text-danger">{checkJO_Paddress}</small>
                    </div>
                    <div className="col-sm mt-3 mr-3">
                    <span className='form-text ml-3' style={{ fontFamily:'sans-serif', "color": "#000000" , fontWeight: 400 ,"fontSize":'16px' }}>City*</span>
                    <input
                        style={{ borderRadius: "30px" }}
                        type="text"
                        className="form-control py-3 mt-1"
                        placeholder="Enter City"
                        onChange={changeJO_Pcity}
                        defaultValue={addData.jo_pcity}
                    />
                    <small className="text-danger">{checkJO_Pcity}</small>
                    </div>
                    <div className="w-100"></div>
                    <div className="col-sm mt-3 mr-3">
                    <span className='form-text ml-3' style={{ fontFamily:'sans-serif', "color": "#000000" , fontWeight: 400 ,"fontSize":'16px' }}>State*</span>
                    <input
                        style={{ borderRadius: "30px" }}
                        type="text"
                        className="form-control py-3 mt-1"
                        placeholder="Enter State"
                        onChange={changeJO_Pstate}
                        defaultValue={addData.jo_pstate}
                    />
                    <small className="text-danger">{checkJO_Pstate}</small>
                    </div>
                    <div className="col-sm mt-3 mr-3">
                    <span className='form-text ml-3' style={{ fontFamily:'sans-serif', "color": "#000000" , fontWeight: 400 ,"fontSize":'16px' }}>Country*</span>
                    <input
                        style={{ borderRadius: "30px" }}
                        type="text"
                        className="form-control py-3 mt-1 "
                        placeholder="Enter Country"
                        onChange={changeJO_Pcountry}
                        defaultValue={addData.jo_pcountry}
                    />
                    <small className="text-danger">{checkJO_Pcountry}</small>
                    </div>
                    <div className="w-100"></div>
                    <div className="col-sm mt-3 mr-3">
                    <span className='form-text ml-3' style={{ fontFamily:'sans-serif', "color": "#000000" , fontWeight: 400 ,"fontSize":'16px' }}>Postcode*</span>
                    <input
                        style={{ borderRadius: "30px" }}
                        type="text"
                        className="form-control py-3 mt-1 "
                        placeholder="Enter Postcode"
                        onChange={changeJO_Ppostcode}
                        defaultValue={addData.jo_ppostcode}
                      />
                      <small className="text-danger">{checkJO_Ppostcode}</small>
                    </div>
                    <div className="col-sm"></div>
                    <div className="w-100"></div>
                    <div className="col-sm mt-3 mr-3">
                        <span className='form-text mt-3 ' style={{ fontFamily:'sans-serif', "color": "#13547A" , fontWeight: 600 ,"fontSize":'18px' }}>Correspondence Address</span>
                    </div>
                    <div className="col-sm mt-3 mr-3">
                        <input onClick={joc_addressCopy} type="checkbox" id="same_as_permanent_address" name="same_as_permanent_address=" value="same_as_permanent_address=" className='me-2' />
                        <label for="same_as_permanent_address=" style={{ fontFamily:'sans-serif', "color": "#000000" , fontWeight: 400 ,"fontSize":'16px' }}>Same as Permanent Address</label>
                    </div>
                    <div className="w-100"></div>
                    <div className="col-sm mt-3 mr-3">
                    <span className='form-text ml-3' style={{ fontFamily:'sans-serif', "color": "#000000" , fontWeight: 400 ,"fontSize":'16px' }}>Address*</span>
                    <input
                        style={{ borderRadius: "30px" }}
                        type="text"
                        className="form-control py-3 mt-1"
                        placeholder="Enter Address"
                        onChange={changeJO_Caddress}
                        defaultValue={addData.jo_caddress}
                    />
                    <small className="text-danger">{checkJO_Caddress}</small>
                    </div>
                    <div className="col-sm mt-3 mr-3">
                    <span className='form-text ml-3' style={{ fontFamily:'sans-serif', "color": "#000000" , fontWeight: 400 ,"fontSize":'16px' }}>City*</span>
                    <input
                        style={{ borderRadius: "30px" }}
                        type="text"
                        className="form-control py-3 mt-1"
                        placeholder="Enter City"
                        onChange={changeJO_Ccity}
                        defaultValue={addData.jo_ccity}
                    />
                    <small className="text-danger">{checkJO_Ccity}</small>
                    </div>
                    <div className="w-100"></div>
                    <div className="col-sm mt-3 mr-3">
                    <span className='form-text ml-3' style={{ fontFamily:'sans-serif', "color": "#000000" , fontWeight: 400 ,"fontSize":'16px' }}>State*</span>
                    <input
                        style={{ borderRadius: "30px" }}
                        type="text"
                        className="form-control py-3 mt-1"
                        placeholder="Enter State"
                        onChange={changeJO_Cstate}
                        defaultValue={addData.jo_cstate}
                    />
                    <small className="text-danger">{checkJO_Cstate}</small>
                    </div>
                    <div className="col-sm mt-3 mr-3">
                    <span className='form-text ml-3' style={{ fontFamily:'sans-serif', "color": "#000000" , fontWeight: 400 ,"fontSize":'16px' }}>Country*</span>
                    <input
                        style={{ borderRadius: "30px" }}
                        type="text"
                        className="form-control py-3 mt-1 "
                        placeholder="Enter Country"
                        onChange={changeJO_Ccountry}
                        defaultValue={addData.jo_ccountry}
                    />
                    <small className="text-danger">{checkJO_Ccountry}</small>
                    </div>
                    <div className="w-100"></div>
                    <div className="col-sm mt-3 mr-3">
                    <span className='form-text ml-3' style={{ fontFamily:'sans-serif', "color": "#000000" , fontWeight: 400 ,"fontSize":'16px' }}>Postcode*</span>
                    <input
                        style={{ borderRadius: "30px" }}
                        type="text"
                        className="form-control py-3 mt-1 "
                        placeholder="Enter Postcode"
                        onChange={changeJO_Cpostcode}
                        defaultValue={addData.jo_cpostcode}
                      />
                      <small className="text-danger">{checkJO_Cpostcode}</small>
                    </div>
                    <div className="col-sm"></div>
                    <div className="w-100"></div>
                    <div className="col-sm mt-3 mr-3">
                    <span className='form-text ml-3' style={{ fontFamily:'sans-serif', "color": "#000000" , fontWeight: 400 ,"fontSize":'16px' }}>Phone Number With Code*</span>
                    <input
                        style={{ borderRadius: "30px" }}
                        type="text"
                        className="form-control py-3 mt-1 "
                        placeholder="Enter phone number"
                        onChange={changeJO_Phonenumber}
                        defaultValue={addData.jo_phonenumber}
                    />
                    <small className="text-danger">{checkJO_Phonenumber}</small>
                    </div>
                    <div className="col-sm mt-3 mr-3">
                    <span className='form-text ml-3' style={{ fontFamily:'sans-serif', "color": "#000000" , fontWeight: 400 ,"fontSize":'16px' }}>Date Of Birth* </span>
                    <input
                        style={{ borderRadius: "30px" }}
                        type="date"
                        name="first_name"
                        className="form-control py-3 mt-1 "
                        placeholder="Enter Date Of Birth" 
                        onChange={changeJO_dob}
                        defaultValue={addData.jo_dob}
                      />
                    <small className="text-danger">{checkJO_Dob}</small>
                    </div>
                    <div className="w-100"></div>
                    <div className="col-sm mt-3 mr-3">
                    <span className='form-text ml-3' style={{ fontFamily:'sans-serif', "color": "#000000" , fontWeight: 400 ,"fontSize":'16px' }}>Occupation*</span>
                    <input
                        style={{ borderRadius: "30px" }}
                        type="text"
                        name="first_name"
                        className="form-control py-3 mt-1 "
                        placeholder="Enter Occupation" 
                        onChange={changeJO_Occupation}
                        defaultValue={addData.jo_occupation}
                      />
                      <small className="text-danger">{checkJO_Occupation}</small>
                    </div>
                    <div className="col-sm mt-3 mr-3">
                    <span className='form-text ml-3' style={{ fontFamily:'sans-serif', "color": "#000000" , fontWeight: 400 ,"fontSize":'16px' }}>Email ID*</span>
                    <input
                        style={{ borderRadius: "30px" }}
                        type="text"
                        name="first_name"
                        className="form-control py-3 mt-1 "
                        placeholder="Enter Email ID"
                        onChange={changeJO_Email}
                        defaultValue={addData.jo_email}
                    />
                      <small className="text-danger">{checkJO_Email}</small>
                    </div>
                    <div className="w-100"></div>
                  
                    <div className="col-md  mt-3 mr-3">
                      <div className='row d-flex justify-content-start'>  
                        <div className='col-md col-lg-6'>                          
                          <button
                            type="button"
                            onClick={() => { setIsOpen2(true) }}
                            style={{ backgroundColor: "#13547A", borderRadius: "30px" , "color": "#ffffff" }}
                            className="btn w-100 py-3"
                            label='Submit'>
                            Add 2nd Joint Owner
                        </button>
                      </div>  
                      </div>
                    </div>
                    <div className="col-md mt-3 mr-3">
                      <div className='row d-flex justify-content-end'>  
                        <div className='col-md-5'>                          
                          <button
                            onClick={() => { resetFunction(); resetField1() }}
                            style={{ backgroundColor: "#13547A", borderRadius: "30px" , "color": "#ffffff" }}
                            className="btn w-100 py-3"
                            type='submit' label='Submit'>
                            Reset
                        </button>
                      </div>  
                      </div>
                    </div>
                    <div className="w-100"></div>
                  </> : null}

                    {isOpen2 ? <>
                    <span className='form-text mt-3 ' style={{ fontFamily: 'sans-serif', "color": "#13547A", fontWeight: 600, "fontSize": '18px' }}>Second Joint Owner Brief Particulars are as under</span>
                    <div className="col-sm mt-3 mr-3">
                    <span className='form-text ml-3' style={{ fontFamily:'sans-serif', "color": "#000000" , fontWeight: 400 ,"fontSize":'16px' }}>Name of the Applicant*</span>
                      <input
                        style={{ borderRadius: "30px" }}
                        type="text"
                        name="first_name"
                        className="form-control py-3 mt-1"
                        placeholder="Enter Applicant Name"
                        onChange={changeSJO_FirstName}
                        defaultValue={addData.sjo_firstname}
                      />
                      <small className="text-danger">{checkSJO_FirstName}</small>
                    </div>
                    <div className="col-sm mt-3 mr-3">
                    <span className='form-text ml-3' style={{ fontFamily:'sans-serif', "color": "#000000" , fontWeight: 400 ,"fontSize":'16px' }}>Father’s / Husband’s Name*</span>
                    <input
                        style={{ borderRadius: "30px" }}
                        type="text"
                        className="form-control py-3 mt-1"
                        placeholder="Enter Father’s / Husband’s Name"
                        onChange={changeSJO_FatherName}
                        defaultValue={addData.sjo_fathername}
                    />
                    <small className="text-danger">{checkSJO_FatherName}</small>
                    </div>
                    <div className="w-100"></div>
                    <div className="col-sm mt-3 mr-3">
                    <span className='form-text mt-3 ' style={{ fontFamily:'sans-serif', "color": "#13547A" , fontWeight: 600 ,"fontSize":'18px' }}>Permanent Address</span>
                    </div>
                    <div className="col-sm mt-3 mr-3">
                    <input onClick={sjop_addressCopy} type="checkbox" id="same_as_primary_applicant" name="same_as_primary_applicant" value="same_as_primary_applicant" className='me-2' />
                    <label for="same_as_primary_applicant" style={{ fontFamily:'sans-serif', "color": "#000000" , fontWeight: 400 ,"fontSize":'16px' }}> Same as Primary Applicant</label>
                    </div>
                    <div className="w-100"></div>

                    <div className="col-sm mt-3 mr-3">
                    <span className='form-text ml-3' style={{ fontFamily:'sans-serif', "color": "#000000" , fontWeight: 400 ,"fontSize":'16px' }}>Address*</span>
                    <input
                        style={{ borderRadius: "30px" }}
                        type="text"
                        className="form-control py-3 mt-1 "
                        placeholder="Enter Address"
                        onChange={changeSJO_Paddress}
                        defaultValue={addData.sjo_paddress}
                    />
                    <small className="text-danger">{checkSJO_Paddress}</small>
                    </div>
                    <div className="col-sm mt-3 mr-3">
                    <span className='form-text ml-3' style={{ fontFamily:'sans-serif', "color": "#000000" , fontWeight: 400 ,"fontSize":'16px' }}>City*</span>
                    <input
                        style={{ borderRadius: "30px" }}
                        type="text"
                        className="form-control py-3 mt-1"
                        placeholder="Enter City"
                        onChange={changeSJO_Pcity}
                        defaultValue={addData.sjo_pcity}
                    />
                    <small className="text-danger">{checkSJO_Pcity}</small>
                    </div>
                    <div className="w-100"></div>
                    <div className="col-sm mt-3 mr-3">
                    <span className='form-text ml-3' style={{ fontFamily:'sans-serif', "color": "#000000" , fontWeight: 400 ,"fontSize":'16px' }}>State*</span>
                    <input
                        style={{ borderRadius: "30px" }}
                        type="text"
                        className="form-control py-3 mt-1"
                        placeholder="Enter State"
                        onChange={changeSJO_Pstate}
                        defaultValue={addData.sjo_pstate}
                    />
                    <small className="text-danger">{checkSJO_Pstate}</small>
                    </div>
                    <div className="col-sm mt-3 mr-3">
                    <span className='form-text ml-3' style={{ fontFamily:'sans-serif', "color": "#000000" , fontWeight: 400 ,"fontSize":'16px' }}>Country*</span>
                    <input
                        style={{ borderRadius: "30px" }}
                        type="text"
                        className="form-control py-3 mt-1 "
                        placeholder="Enter Country"
                        onChange={changeSJO_Pcountry}
                        defaultValue={addData.sjo_pcountry}
                    />
                    <small className="text-danger">{checkSJO_Pcountry}</small>
                    </div>
                    <div className="w-100"></div>
                    <div className="col-sm mt-3 mr-3">
                    <span className='form-text ml-3' style={{ fontFamily:'sans-serif', "color": "#000000" , fontWeight: 400 ,"fontSize":'16px' }}>Postcode*</span>
                    <input
                        style={{ borderRadius: "30px" }}
                        type="text"
                        className="form-control py-3 mt-1 "
                        placeholder="Enter Postcode"
                        onChange={changeSJO_Ppostcode}
                        defaultValue={addData.sjo_ppostcode}
                      />
                      <small className="text-danger">{checkSJO_Ppostcode}</small>
                    </div>
                    <div className="col-sm"></div>
                    <div className="w-100"></div>
                    <div className="col-sm mt-3 mr-3">
                        <span className='form-text mt-3' style={{ fontFamily:'sans-serif', "color": "#13547A" , fontWeight: 600 ,"fontSize":'18px' }}>Correspondence Address</span>
                    </div>
                    <div className="col-sm mt-3 mr-3">
                        <input onClick={sjoc_addressCopy} type="checkbox" id="same_as_permanent_address" name="same_as_permanent_address=" value="same_as_permanent_address=" className='me-2' />
                        <label for="same_as_permanent_address=" style={{ fontFamily:'sans-serif', "color": "#000000" , fontWeight: 400 ,"fontSize":'16px' }}>Same as Permanent Address</label>
                    </div>
                    <div className="w-100"></div>
                    <div className="col-sm mt-3 mr-3">
                    <span className='form-text ml-3' style={{ fontFamily:'sans-serif', "color": "#000000" , fontWeight: 400 ,"fontSize":'16px' }}>Address*</span>
                    <input
                        style={{ borderRadius: "30px" }}
                        type="text"
                        className="form-control py-3 mt-1"
                      placeholder="Enter Address"
                        onChange={changeSJO_Caddress}
                        defaultValue={addData.sjo_caddress}
                    />
                    <small className="text-danger">{checkSJO_Caddress}</small>
                    </div>
                    <div className="col-sm mt-3 mr-3">
                    <span className='form-text ml-3' style={{ fontFamily:'sans-serif', "color": "#000000" , fontWeight: 400 ,"fontSize":'16px' }}>City*</span>
                    <input
                        style={{ borderRadius: "30px" }}
                        type="text"
                        className="form-control py-3 mt-1"
                        placeholder="Enter City"
                        onChange={changeSJO_Ccity}
                        defaultValue={addData.sjo_ccity}
                    />
                    <small className="text-danger">{checkSJO_Ccity}</small>
                    </div>
                    <div className="w-100"></div>
                    <div className="col-sm mt-3 mr-3">
                    <span className='form-text ml-3' style={{ fontFamily:'sans-serif', "color": "#000000" , fontWeight: 400 ,"fontSize":'16px' }}>State*</span>
                    <input
                        style={{ borderRadius: "30px" }}
                        type="text"
                        className="form-control py-3 mt-1"
                        placeholder="Enter State"
                        onChange={changeSJO_Cstate}
                        defaultValue={addData.sjo_cstate}
                    />
                    <small className="text-danger">{checkSJO_Cstate}</small>
                    </div>
                    <div className="col-sm mt-3 mr-3">
                    <span className='form-text ml-3' style={{ fontFamily:'sans-serif', "color": "#000000" , fontWeight: 400 ,"fontSize":'16px' }}>Country*</span>
                    <input
                        style={{ borderRadius: "30px" }}
                        type="text"
                        className="form-control py-3 mt-1 "
                        placeholder="Enter Country"
                        onChange={changeSJO_Ccountry}
                        defaultValue={addData.sjo_ccountry}
                    />
                    <small className="text-danger">{checkSJO_Ccountry}</small>
                    </div>
                    <div className="w-100"></div>
                    <div className="col-sm mt-3 mr-3">
                    <span className='form-text ml-3' style={{ fontFamily:'sans-serif', "color": "#000000" , fontWeight: 400 ,"fontSize":'16px' }}>Postcode*</span>
                    <input
                        style={{ borderRadius: "30px" }}
                        type="text"
                        className="form-control py-3 mt-1 "
                        placeholder="Enter Postcode"
                        onChange={changeSJO_Cpostcode}
                        defaultValue={addData.sjo_cpostcode}
                      />
                      <small className="text-danger">{checkSJO_Cpostcode}</small>
                    </div>
                    <div className="col-sm"></div>
                    <div className="w-100"></div>
                    <div className="col-sm mt-3 mr-3">
                    <span className='form-text ml-3' style={{ fontFamily:'sans-serif', "color": "#000000" , fontWeight: 400 ,"fontSize":'16px' }}>Phone Number With Code*</span>
                    <input
                        style={{ borderRadius: "30px" }}
                        type="text"
                        className="form-control py-3 mt-1 "
                        placeholder="Enter phone number"
                        onChange={changeSJO_Phonenumber}
                        defaultValue={addData.sjo_phonenumber}
                    />
                    <small className="text-danger">{checkSJO_Phonenumber}</small>
                    </div>
                    <div className="col-sm mt-3 mr-3">
                    <span className='form-text ml-3' style={{ fontFamily:'sans-serif', "color": "#000000" , fontWeight: 400 ,"fontSize":'16px' }}>Date Of Birth* </span>
                    <input
                        style={{ borderRadius: "30px" }}
                        type="date"
                        name="first_name"
                        className="form-control py-3 mt-1 "
                        placeholder="Enter Date Of Birth" 
                        onChange={changeSJO_dob}
                        defaultValue={addData.sjo_dob}
                      />
                    <small className="text-danger">{checkSJO_Dob}</small>
                    </div>
                    <div className="w-100"></div>
                    <div className="col-sm mt-3 mr-3">
                    <span className='form-text ml-3' style={{ fontFamily:'sans-serif', "color": "#000000" , fontWeight: 400 ,"fontSize":'16px' }}>Occupation*</span>
                    <input
                        style={{ borderRadius: "30px" }}
                        type="text"
                        name="first_name"
                        className="form-control py-3 mt-1 "
                        placeholder="Enter Occupation" 
                        onChange={changeSJO_Occupation}
                        defaultValue={addData.sjo_occupation}
                      />
                      <small className="text-danger">{checkSJO_Occupation}</small>
                    </div>
                    <div className="col-sm mt-3 mr-3">
                    <span className='form-text ml-3' style={{ fontFamily:'sans-serif', "color": "#000000" , fontWeight: 400 ,"fontSize":'16px' }}>Email ID*</span>
                    <input
                        style={{ borderRadius: "30px" }}
                        type="text"
                        name="first_name"
                        className="form-control py-3 mt-1 "
                        placeholder="Enter Email ID"
                        onChange={changeSJO_Email}
                        defaultValue={addData.sjo_email}
                    />
                      <small className="text-danger">{checkSJO_Email}</small>
                    </div>
                    <div className="w-100"></div>
                  </>:null}
                  
                    <div className="col-sm mt-3">
                    <span className='form-text ms-3' style={{ "color": "#000000" ,"fontSize":'15px'  }}>Upload Signature</span>
                    <div className="input-container mt-1">
                        <div className="input-group">
                          <input
                            className="form-control py-3 editinput"
                            type="file"
                            placeholder="document Docs"
                            aria-label="Search"
                            id="inputGroupFile01"
                            name="document"
                            onChange={changeSignature}
                          />
                          <div className="w-50 editbtn text-center">
                            <label
                              className="pt-3 text-white"
                              htmlFor="inputGroupFile01"
                            >
                            PDF
                            </label>
                          </div>
                        </div>
                        <small className="text-danger">{checkSignatureerror}</small>
                      </div>
                    </div>
                    <div className="col-md mt-3 mr-3">
                      <div className='row d-flex justify-content-end mt-4'>  
                        <div className='col-md-5'>                          
                        <button
                            onClick={() => { resetFunction2(); resetField2() }}
                            style={{ backgroundColor: "#13547A", borderRadius: "30px" , "color": "#ffffff" }}
                            className="btn w-100 py-3"
                            type="button" label='Submit'>
                            Reset
                        </button>
                      </div>  
                      </div>
                    </div>
                    <div className="w-100"></div>
                    <div className="col-sm mt-3 mr-3">
                    <span className='form-text ml-3' style={{ fontFamily:'sans-serif', "color": "#000000" , fontWeight: 400 ,"fontSize":'16px' }}>Date*</span>
                      <input
                        style={{ borderRadius: "30px" }}
                        type="date"
                        name="first_name"
                        className="form-control py-3 mt-1 "
                        placeholder="Enter Date"
                        onChange={changeDate}
                        defaultValue={addData.date}
                    />
                      <small className="text-danger">{checkDate}</small>
                    </div>
                    <div className="col-sm"></div>
                    <div className="w-100"></div>
                    <div className="col-sm mt-3 mr-3">
                    <span className='form-text ml-3' style={{ fontFamily:'sans-serif', "color": "#000000" , fontWeight: 400 ,"fontSize":'16px' }}>Place*</span>
                      <input
                        style={{ borderRadius: "30px" }}
                        type="text"
                        name="first_name"
                        className="form-control py-3 mt-1 "
                        placeholder="Enter Place"
                        onChange={changePlace}
                        defaultValue={addData.place}
                    />
                      <small className="text-danger">{checkPlace}</small>
                    </div>
                    <div className="col-sm"></div>
                    <div className="w-100"></div>
                </div>
                              
                      <div className="row">
                        <span className='form-text mt-3 ' style={{ fontFamily: 'sans-serif', "color": "#13547A", fontWeight: 600, "fontSize": '18px' }}>Member Documents</span>
                        <div className="col-sm mt-3 mr-3">
                        <span className='form-text ml-3' style={{ fontFamily:'sans-serif', "color": "#000000" , fontWeight: 400 ,"fontSize":'16px' }}>For proof of identity*</span>
                            <select 
                                name="poi" 
                                style={{ borderRadius: "30px" }}
                                className="form-select py-3 mt-1"
                                onChange={poiChange}>
                                    <option disabled selected={true}>Select Document</option>
                                    <option value="Aadhaarcard">Aadhaar Card</option>
                                    <option value="Pancard">Pan Card</option>
                            </select>
                        <span className='form-text text-danger'>{checkPoi}</span>
                        </div>
                        <div className="col-sm mt-3">
                        <span className='form-text ms-3' style={{ "color": "#000000" ,"fontSize":'15px'  }}>Upload proof of identity*</span>
                        <div className="input-container mt-1">
                            <div className="input-group">
                              <input
                                className="form-control py-3 editinput"
                                type="file"
                                placeholder="document Docs"
                                aria-label="Search"
                                id="inputGroupFile01"
                                name="document"
                                onChange={changeUpoi}
                              />
                              <div className="w-50 editbtn text-center">
                                <label
                                  className="pt-3 text-white"
                                  htmlFor="inputGroupFile01"
                                >
                                  PDF
                                </label>
                              </div>
                            </div>
                            <small className="text-danger">{checkUpoierror}</small>
                          </div>
                        </div>
                        <div className="w-100"></div>
                        <div className="col-sm mt-3 mr-3">
                        <span className='form-text ml-3' style={{ fontFamily:'sans-serif', "color": "#000000" , fontWeight: 400 ,"fontSize":'16px' }}>For proof of address*</span>
                            <select 
                                name="poa" 
                                style={{ borderRadius: "30px" }}
                                className="form-select py-3 mt-1"
                                onChange={poaChange}>
                                    <option disabled selected={true}>Select Document</option>
                                    <option value="volvo">Aadhaar Card</option>
                                    <option value="saab">Pan Card</option>
                              </select>
                              <span className='form-text text-danger'>{checkPoa}</span>
                        </div>
                        <div className="col-sm mt-3">
                        <span className='form-text ms-3' style={{ "color": "#000000" ,"fontSize":'15px'  }}>Upload proof of address*</span>
                        <div className="input-container mt-1">
                            <div className="input-group">
                              <input
                                className="form-control py-3 editinput"
                                type="file"
                                placeholder="document Docs"
                                aria-label="Search"
                                id="inputGroupFile01"
                                name="document"
                                onChange={changeUpoa}
                              />
                              <div className="w-50 editbtn text-center">
                                <label
                                  className="pt-3 text-white"
                                  htmlFor="inputGroupFile01"
                                >
                                PDF
                                </label>
                              </div>
                            </div>
                            <small className="text-danger">{checkUpoaerror}</small>
                          </div>
                        </div>
                        <div className="w-100"></div>               
                        <div className="col-sm mt-3 mr-3">
                        <span className='form-text ml-3' style={{ fontFamily:'sans-serif', "color": "#000000" , fontWeight: 400 ,"fontSize":'16px' }}>For proof of date of birth*</span>
                            <select 
                                name="podob" 
                                style={{ borderRadius: "30px" }}
                                className="form-select py-3 mt-1"
                                onChange={podobChange}>
                                    <option disabled selected={true}>Select Document</option>
                                    <option value="volvo">Aadhaar Card</option>
                                    <option value="saab">Pan Card</option>
                            </select>
                            <span className='form-text text-danger'>{checkPodob}</span>
                        </div>
                        <div className="col-sm mt-3">
                        <span className='form-text ms-3' style={{ "color": "#000000" ,"fontSize":'15px'  }}>Upload proof of date of birth*</span>
                        <div className="input-container mt-1">
                            <div className="input-group">
                              <input
                                className="form-control py-3 editinput"
                                type="file"
                                placeholder="document Docs"
                                aria-label="Search"
                                id="inputGroupFile01"
                                name="document"
                                onChange={changeUpodob}
                              />
                              <div className="w-50 editbtn text-center">
                                <label
                                  className="pt-3 text-white"
                                  htmlFor="inputGroupFile01"
                                >
                                PDF
                                </label>
                              </div>
                            </div>
                            <small className="text-danger">{checkUpodoberror}</small>
                          </div>
                        </div>
                        <div className="w-100"></div>
                        <div className="col-sm mt-3 mr-3">
                        <span className='form-text ml-3' style={{ fontFamily:'sans-serif', "color": "#000000" , fontWeight: 400 ,"fontSize":'16px' }}>For proof of ownership*</span>
                            <select 
                                name="poo" 
                                style={{ borderRadius: "30px" }}
                                className="form-select py-3 mt-1"
                                onChange={pooChange}>
                                    <option disabled selected={true}>Select Document</option>
                                    <option value="volvo">Aadhaar Card</option>
                                    <option value="saab">Pan Card</option>
                            </select>
                            <span className='form-text text-danger'>{checkPoo}</span>
                        </div>
                        <div className="col-sm mt-3">
                        <span className='form-text ms-3' style={{ "color": "#000000" ,"fontSize":'15px'  }}>Upload proof of ownership*</span>
                        <div className="input-container mt-1">
                            <div className="input-group">
                              <input
                                className="form-control py-3 editinput"
                                type="file"
                                placeholder="document Docs"
                                aria-label="Search"
                                id="inputGroupFile01"
                                name="document"
                                onChange={changeUpoo}
                              />
                              <div className="w-50 editbtn text-center">
                                <label
                                  className="pt-3 text-white"
                                  htmlFor="inputGroupFile01"
                                >
                                PDF
                                </label>
                              </div>
                            </div>
                            <small className="text-danger">{checkUpooerror}</small>
                          </div>
                        </div>
                        <div className="w-100"></div>
                        <div className="col-sm mt-3 mr-3">
                        <span className='form-text ml-3' style={{ fontFamily:'sans-serif', "color": "#000000" , fontWeight: 400 ,"fontSize":'16px' }}>For proof of payment*</span>
                            <select 
                                name="pop" 
                                style={{ borderRadius: "30px" }}
                                className="form-select py-3 mt-1"
                                onChange={popChange}>
                                    <option disabled selected={true}>Select Document</option>
                                    <option value="volvo">Aadhaar Card</option>
                                    <option value="saab">Pan Card</option>
                          </select>
                          <span className='form-text text-danger'>{checkPop}</span>
                        </div>
                        <div className="col-sm mt-3">
                        <span className='form-text ms-3' style={{ "color": "#000000" ,"fontSize":'15px'  }}>Upload proof of payment*</span>
                        <div className="input-container mt-1">
                            <div className="input-group">
                              <input
                                className="form-control py-3 editinput"
                                type="file"
                                placeholder="document Docs"
                                aria-label="Search"
                                id="inputGroupFile01"
                                name="document"
                                onChange={changeUpop}
                              />
                              <div className="w-50 editbtn text-center">
                                <label
                                  className="pt-3 text-white"
                                  htmlFor="inputGroupFile01"
                                >
                                PDF
                                </label>
                              </div>
                            </div>
                            <small className="text-danger">{checkUpoperror}</small>
                          </div>
                        </div>
                        <div className="w-100"></div>
                        <span className='form-text mt-3 ' style={{ fontFamily:'sans-serif', "color": "#13547A" , fontWeight: 600 ,"fontSize":'18px' }}>Only to be filled in case of joint owner</span>
                        <div className="col-sm mt-3 mr-3">
                        <span className='form-text ml-3' style={{ fontFamily:'sans-serif', "color": "#000000" , fontWeight: 400 ,"fontSize":'16px' }}>For proof of identity of joint owner 1</span>
                            <select 
                                name="poijo1" 
                                style={{ borderRadius: "30px" }}
                                className="form-select py-3 mt-1"
                                onChange={poijo1Change}>
                                    <option disabled selected={true}>Select Document</option>
                                    <option value="volvo">Aadhaar Card</option>
                                    <option value="saab">Pan Card</option>
                            </select>
                            <span className='form-text text-danger'>{checkPoijo1}</span>
                        </div>
                        <div className="col-sm mt-3">
                        <span className='form-text ms-3' style={{ "color": "#000000" ,"fontSize":'15px'  }}>Upload proof of identity of joint owner 1</span>
                        <div className="input-container mt-1">
                            <div className="input-group">
                              <input
                                className="form-control py-3 editinput"
                                type="file"
                                placeholder="document Docs"
                                aria-label="Search"
                                id="inputGroupFile01"
                                name="document"
                                onChange={changeUpoijo1}
                              />
                              <div className="w-50 editbtn text-center">
                                <label
                                  className="pt-3 text-white"
                                  htmlFor="inputGroupFile01"
                                >
                                PDF
                                </label>
                              </div>
                            </div>
                            <small className="text-danger">{checkUpoijo1error}</small>
                          </div>
                        </div>
                        <div className="w-100"></div>
                        <div className="col-sm mt-3 mr-3 ml-3">
                        <input type="checkbox" id="same_as_permanent_address" name="same_as_permanent_address" value="same_as_permanent_address" className='me-2' />
                        <label for="same_as_permanent_address" style={{ fontFamily:'sans-serif', "color": "#13547A" , fontWeight: 400 ,"fontSize":'16px' }}>No Objection Certificate of joint owner 1</label>
                        </div>
                        <div className="w-100"></div>           
                        <div className="col-sm mt-3 mr-3">
                            <Button
                                style={{ backgroundColor: "#13547A", borderRadius: "30px" , "color": "#ffffff" }}
                                className="btn w-100 py-3"
                                onClick={() => { setShow(true)}}>
                                Download Certificate
                            </Button>
                        </div>
                        <div className="col-sm mt-3">
                        <div className="input-container mt-1">
                            <div className="input-group">
                              <input
                                className="form-control py-3 editinput"
                                type="file"
                                placeholder="document Docs"
                                aria-label="Search"
                                id="inputGroupFile01"
                                name="document"
                                onChange={changeUnocjo1}
                              />
                              <div className="w-50 editbtn text-center">
                                <label
                                  className="pt-3 text-white"
                                  htmlFor="inputGroupFile01"
                                >
                                PDF
                                </label>
                              </div>
                            </div>
                            <small className="text-danger">{checkUnocjo1error}</small>
                          </div>
                        </div>
                        <div className="w-100"></div>              
                        <div className="col-sm mt-3 mr-3">
                        <span className='form-text ml-3' style={{ fontFamily:'sans-serif', "color": "#000000" , fontWeight: 400 ,"fontSize":'16px' }}>For proof of identity of joint owner 2</span>
                            <select 
                                name="poijo2" 
                                style={{ borderRadius: "30px" }}
                                className="form-select py-3 mt-1"
                                onChange={poijo2Change}>
                                    <option disabled selected={true}>Select Document</option>
                                    <option value="volvo">Aadhaar Card</option>
                                    <option value="saab">Pan Card</option>
                            </select>
                            <span className='form-text text-danger'>{checkPoijo2}</span>
                        </div>
                        <div className="col-sm mt-3">
                        <span className='form-text ms-3' style={{ "color": "#000000" ,"fontSize":'15px'  }}>Upload proof of identity of joint owner 2</span>
                        <div className="input-container mt-1">
                            <div className="input-group">
                              <input
                                className="form-control py-3 editinput"
                                type="file"
                                placeholder="document Docs"
                                aria-label="Search"
                                id="inputGroupFile01"
                                name="document"
                                onChange={changeUpoijo2}
                              />
                              <div className="w-50 editbtn text-center">
                                <label
                                  className="pt-3 text-white"
                                  htmlFor="inputGroupFile01"
                                >
                                PDF
                                </label>
                              </div>
                            </div>
                            <small className="text-danger">{checkUpoijo2error}</small>
                          </div>
                        </div>
                        <div className="w-100"></div>
                        <div className="col-sm mt-3 mr-3 ml-3">
                        <input type="checkbox" id="same_as_permanent_address" name="same_as_permanent_address" value="same_as_permanent_address" className='me-2' />
                        <label for="same_as_permanent_address" style={{ fontFamily:'sans-serif', "color": "#13547A" , fontWeight: 400 ,"fontSize":'16px' }}>No Objection Certificate of joint owner 2</label>
                        </div>
                        <div className="w-100"></div>                
                        <div className="col-sm mt-3 mr-3">
                            <Button
                                onClick={() => { setShow(true)}}
                                style={{ backgroundColor: "#13547A", borderRadius: "30px" , "color": "#ffffff" }}
                                className="btn w-100 py-3">
                                Download Certificate
                            </Button>
                        </div>
                        <div className="col-sm mt-3">
                        <div className="input-container mt-1">
                            <div className="input-group">
                              <input
                                className="form-control py-3 editinput"
                                type="file"
                                placeholder="document Docs"
                                aria-label="Search"
                                id="inputGroupFile01"
                                name="document"
                                onChange={changeUnocjo2}
                              />
                              <div className="w-50 editbtn text-center">
                                <label
                                  className="pt-3 text-white"
                                  htmlFor="inputGroupFile01"
                                >
                                PDF
                                </label>
                              </div>
                            </div>
                            <small className="text-danger">{checkUnocjo2error}</small>
                          </div>
                        </div>
                        <div className="w-100"></div>       
                      </div>
                
                <div className='row d-flex justify-content-center'>
                  <div className="col-md-6">
                        <button
                          // to="/paymentpage"
                          style={{ borderRadius: "30px" , "color": "#ffffff" }}
                          className="btn w-100 mt-3 py-3 editbtnsubmit border-0"
                          type='submit' label='Submit'>
                          Save & Next
                      </button>
                  </div>
                </div>
              </form>
            </div>
            </div>
                <Modal show={show} onHide={() => { setShow(false) }} className="mt-5">
                    <Modal.Header closeButton style={{padding:'3px', background:'#13547A', paddingRight:'15px'}}>
                        <Modal.Title style={{fontSize:'20px', marginLeft:'5px', fontFamily:'sans-serif', color:'#ffffff'}}>
                            NOC Certificate
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body >
                        <NocModal closeModal={setShow}/>
                    </Modal.Body>
                </Modal>
        </main>
    </>
  );
}
