import React, { useState } from "react";
import { Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import './style.css'

function Navigation() {

    let navigate = useNavigate()
    let [navColor, setNavColor] = useState(0)

    return (
        <>
            <Row>
                <div >
                    <div className="row large shadow d-flex justify-content-evenly w-100 navcolor" style={{  position: 'fixed',  zIndex: 1030, margin: '0px', padding: '0px' }} >
                        <div className="col-10">
                            <nav className="navbar navbar-expand-lg fw-bold " >
                                <div className="container-fluid ">
                                    <Link className="navbar-brand  text-white"> <img src={require('../images/logo.png')} style={{ height: '50px', width: '50px' }} alt="logo"></img></Link>
                                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                        <span className="navbar-toggler-icon"></span>
                                    </button>
                                    <div className="collapse navbar-collapse " id="navbarSupportedContent">
                                        <ul className="navbar-nav ms-auto mb-2 mb-lg-0 " style={{fontWeight:300}}>
                                            <li className="nav-item ">
                                                <a className="nav-link  " aria-current="page" style={{ color: navColor === 0 ? '#000' : '#fff' }}
                                                    onClick={() => { navigate('/home'); setNavColor(0) }}>Home</a>
                                            </li>
                                            <li className="nav-item dropdown ">
                                                <a className="nav-link dropdown-toggle text-white " role="button" data-bs-toggle="dropdown" aria-expanded="false"
                                                    style={{ color: navColor === 2 ? '#000' : '#fff', }} onClick={() => { navigate('/about'); setNavColor(2) }}
                                                >
                                                    About
                                                </a>
                                                <ul className="dropdown-menu">
                                                    <li><a className="dropdown-item " onClick={() => { navigate('/mission'); setNavColor(2) }}>Mission</a></li>
                                                    <li><a className="dropdown-item" onClick={() => { navigate('/vision'); setNavColor(2) }}>Vision</a></li>
                                                    <li><a className="dropdown-item" onClick={() => { navigate('/concerns'); setNavColor(2) }}>Concerns With The Projects </a></li>
                                                </ul>
                                            </li>
                                            <li className="nav-item ">
                                                <a className="nav-link " aria-current="page"
                                                    style={{ color: navColor === 3 ? '#000' : '#fff' }}
                                                    onClick={() => { navigate('/contact'); setNavColor(3) }}>Contact </a>
                                            </li>
                                            <Link style={{textDecoration:"none"}} to="/login">
                                                <li className="nav-item text-white ml-5">
                                                    <button className="btn" style={{ background: 'teal', color: "white" }} type="submit" >Login</button>
                                                </li>
                                            </Link>
                                        </ul>
                                    </div>
                                </div>
                            </nav>
                        </div>
                    </div>
                </div>
            </Row>
        </>
    )
}
export default Navigation