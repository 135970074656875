import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { Row, Col, Card } from "reactstrap";
import { BiCalendar } from "react-icons/bi";
import { IoLogoYoutube } from "react-icons/io";
import Form from 'react-bootstrap/Form';
import { useNavigate } from "react-router-dom";
import 'react-phone-number-input/style.css'
import Input from 'react-phone-number-input'

function Content() {

    const [value, setValue] = useState();
    const [phone2, setPhone2] = useState();
    const [isOpen, setIsOpen] = useState(false);
    const [isOpen2, setIsOpen2] = useState(false);
    let navigate = useNavigate();

    return (
        <>
            <Row className="mt-6">
                <Row className="d-flex justify-content-center" >

                    <Col lg={6} md={10} className='d-flex justify-content-center mt-1' data-aos="zoom-in" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="600">
                        <div className="ms-5">
                            <span style={{ color: '#000', fontSize: '30px', fontWeight: 500, }}>Trending News  </span>
                            {/* <hr style={{height: '2px',color: 'teal',backgroundColor: 'teal', borderTop: ' 1px solid blue'}}></hr> */}
                            <div className="trending-news mt-1">
                                <div className="trending-news-content">
                                    <div style={{ position: 'relative', top: '56%', marginLeft: "20px" }}>
                                        <Button style={{ background: 'teal', border: 'none', marginRight: '10px' }} onClick={() => { navigate('/news') }} >News</Button>
                                        <BiCalendar style={{ fontSize: "23px", color: "white" }} /> <span style={{ color: 'white' }}>Septemer 6,2022</span>  <br></br>
                                        <span style={{ color: "white", fontSize: '20px', fontWeight: 'bold' }}>NCDRC Penalises Raheja Developer For Delay In Revanta Project In Gurgaon</span>
                                    </div>

                                </div>
                            </div>
                        </div>
                        {/* <img src={require("../images/phase1.jpg")} height='450' width='80%'
                            style={{
                                borderRadius: " 23px",
                                boxShadow: "3px 5px 11px 4px grey",
                          
                            }}></img>
                        <span style={{ position: 'absolute', top: '', backgrouond:'rgba(0,0,0,.6)',display:'flex'}}>NCDR</span> */}
                    </Col>

                    <Col lg={6} md={12} className='d-flex flex-column justify-content-center mt-1 ' data-aos="zoom-in" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="600">
                        <span className="text-center" style={{ color: '#000', fontSize: '30px', fontWeight: 500, }}>All  </span>

                        <div>
                            <Col className="d-flex justify-content-space " >
                                <img src={require('../images/b-block.jpeg')} style={{ height: '98px', width: '150px', borderRadius: '20px' }} alt="block"></img>
                                <div>
                                    <Button style={{ margin: '15px', padding: '4px 10px 4px 10px', fontSize: '14px', background: 'teal', border: 'none', fontWeight: '500' }} onClick={() => { navigate('/construction') }}>Construction Update</Button><br></br>
                                    <span style={{ fontWeight: 'bold', margin: '10px', }}>B-C Block Decking Still Has A Bunch</span>    </div>

                            </Col>

                        </div>
                        <hr style={{ width: "90%", color: 'black', opacity: '1.25' }}></hr>
                        <Col className="d-flex justify-content-space" >

                            <img src={require('../images/order.jpeg')} style={{ height: '98px', width: '150px', borderRadius: '20px' }} alt="order"></img>
                            <div>
                                <Button style={{ margin: '15px', padding: '4px 10px 4px 10px', fontSize: '14px', background: 'teal', border: 'none', fontWeight: '500' }} onClick={() => { navigate('/courtorder') }}>Court Order</Button><br></br>
                                <span style={{ fontWeight: 'bold', margin: '10px', }}>Raheja Developers To Refund Buyers For Delaying</span>    </div>

                        </Col>
                        <hr style={{ width: "90%", color: 'black', opacity: '1.25' }}></hr>
                        <Col className="d-flex justify-content-space" >

                            <img src={require('../images/phase1.jpg')} style={{ height: '98px', width: '150px', borderRadius: '20px' }} alt="phase"></img>
                            <div>
                                <Button style={{ margin: '15px', padding: '4px 10px 4px 10px', fontSize: '14px', background: 'teal', border: 'none', fontWeight: '500' }} onClick={() => { navigate('/news') }}>News</Button><br></br>
                                <span style={{ fontWeight: 'bold', margin: '10px' }}>NCDRC Penalises Raheja Developer For Delay In</span>    </div>

                        </Col>

                    </Col>
                </Row>
                <Row className="mt-5">
                    <Col className='text-center mt-4 ' data-aos="zoom-in" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="600">
                        <span style={{ color: '#000', fontSize: '30px', fontWeight: 700, }}>Categories </span>

                    </Col>
                </Row>


                <div data-aos="fade-up" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="600">

                    <Row lg={12} className='d-flex justify-content-center' >
                        <Col className="p-0 d-flex justify-content-center  " >
                            <div className="Category-1 ms-1 mt-1">
                                <div className="category-content ">
                                    <div className="d-flex   justify-content-center">
                                        <div className="mt-4"><Button style={{ background: ' #ffffff5e', padding: "3px 8px 3px 8px", border: 'none' }} onClick={() => { navigate('/news') }}>News</Button></div>

                                    </div>
                                    <div className="d-flex  justify-content-center">

                                        <div className="mt-4" ><Button style={{ background: ' #ffffff5e', padding: "3px 8px 3px 8px", border: 'none' }}>01</Button></div>
                                    </div>
                                </div>
                            </div>

                        </Col>
                        <Col className="p-0 d-flex justify-content-center">
                            <div className="Category-2 mt-1">
                                <div className="category-content ">
                                    <div className="d-flex   justify-content-center">
                                        <div className="mt-4"><Button style={{ background: ' #ffffff5e', padding: "3px 8px 3px 8px", border: 'none' }} onClick={() => { navigate('/courtorder') }}>Court Order</Button></div>

                                    </div>
                                    <div className="d-flex  justify-content-center">

                                        <div className="mt-4" ><Button style={{ background: ' #ffffff5e', padding: "3px 8px 3px 8px", border: 'none' }}>02</Button></div>
                                    </div>
                                </div>
                            </div>

                        </Col>
                        <Col className="p-0 d-flex justify-content-center">
                            <div className="Category-3 mt-1">
                                <div className="category-content ">
                                    <div className="d-flex   justify-content-center">
                                        <div className="mt-4"><Button style={{ background: ' #ffffff5e', padding: "3px 8px 3px 8px", border: 'none' }} onClick={() => { navigate('/construction') }}>Construction Update</Button></div>

                                    </div>
                                    <div className="d-flex  justify-content-center">

                                        <div className="mt-4" ><Button style={{ background: ' #ffffff5e', padding: "3px 8px 3px 8px", border: 'none' }}>03</Button></div>
                                    </div>
                                </div>
                            </div>

                        </Col>
                        <Col className="p-0 d-flex justify-content-center">
                            <div className="Category-4 mt-1">
                                <div className="category-content ">
                                    <div className="d-flex   justify-content-center">
                                        <div className="mt-4"><Button style={{ background: ' #ffffff5e', padding: "3px 8px 3px 8px", border: 'none' }} onClick={() => { navigate('/cheatedhomebuyers') }}>Cheated Home Buyers</Button></div>

                                    </div>
                                    <div className="d-flex  justify-content-center">

                                        <div className="mt-4" ><Button style={{ background: ' #ffffff5e', padding: "3px 8px 3px 8px", border: 'none' }}>04</Button></div>
                                    </div>
                                </div>
                            </div>

                        </Col>
                        <Col className="p-0 d-flex justify-content-center">
                            <div className="Category-5 mt-1">
                                <div className="category-content ">
                                    <div className="d-flex   justify-content-center">
                                        <div className="mt-4"><Button style={{ background: ' #ffffff5e', padding: "3px 8px 3px 8px", border: 'none' }} onClick={() => { navigate('/refundpayment') }}>Refund Payment</Button></div>

                                    </div>
                                    <div className="d-flex  justify-content-center">

                                        <div className="mt-4" ><Button style={{ background: ' #ffffff5e', padding: "3px 8px 3px 8px", border: 'none' }}>05</Button></div>
                                    </div>
                                </div>
                            </div>

                        </Col>

                    </Row>
                </div>
                <div className='youtube-feed'>
                    <Row className="bg-black p-5 d-flex justify-content-center">
                        <h2 className="text-white ms-5 "><IoLogoYoutube style={{ color: '#b30000', marginLeft: '20px', fontSize: '35px' }} /> YouTube Feeds</h2>
                        <Col className="d-flex justify-content-center">
                            <iframe src='https://www.youtube.com/embed/V3oe440eTzU' title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen height='400' width='90%'></iframe>

                        </Col>
                        <Row className="d-flex justify-content-center">
                            <Col className="d-flex justify-content-center" style={{ borderRadius: '20px' }}>
                                <iframe src='https://www.youtube.com/embed/V3oe440eTzU' style={{ borderRadius: '20px' }} title="YouTube video player" frameborder="1" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen height='280' width='300'></iframe>
                            </Col>
                            <Col className="d-flex justify-content-center" >
                                <iframe src='https://www.youtube.com/embed/NzcmV8RFP_w' title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen height='280' width='300'></iframe>
                            </Col>
                            <Col className="d-flex justify-content-center" >
                                <iframe src='https://www.youtube.com/embed/CwPdzd3eGvU' title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen height='280' width='300'></iframe>
                            </Col>
                        </Row>
                    </Row>
                </div>
                <div>
                    <Row>
                        <span style={{ color: '#000', fontSize: '30px', fontWeight: 700, textAlign: 'center' }}>Top Posts </span>
                        <Col className="d-flex flex-column justify-content-center align-items-center">
                            <img src={require('../images/building.jpeg')} style={{ height: '180px', width: '270px', borderRadius: '10px' }} alt="building"></img>
                            <div>
                                <Button style={{ margin: '15px 5px 15px 5px', padding: '4px 9px 4px 9px', fontSize: '11px', background: 'teal', border: 'none', fontWeight: '500' }} onClick={() => { navigate('/cheatedhomebuyers') }}>Cheated Home buyers</Button><span style={{ fontSize: '12px' }}><BiCalendar /> 06 September, 2022</span>
                            </div>

                            <span style={{ fontWeight: 'bold' }}>Raheja Developers Are Playing Spot The <br></br>Difference With</span>
                            <img src={require('../images/b-block.jpeg')} style={{ height: '180px', width: '277px', borderRadius: '10px', marginTop: '20px' }} alt="b-block"></img>
                            <div>
                                <Button style={{ margin: '15px 5px 15px 5px', padding: '4px 9px 4px 9px', fontSize: '11px', background: 'teal', border: 'none', fontWeight: '500' }} onClick={() => { navigate('/construction') }}>Construction Update</Button><span style={{ fontSize: '12px' }}><BiCalendar /> 06 September, 2022</span>
                            </div>
                            <span style={{ fontWeight: 'bold' }}>B-C Block Decking Still Has A Bunch Of</span>
                        </Col>

                        <Col className="d-flex flex-column justify-content-center align-items-center">
                            <img src={require('../images/order.jpeg')} className='order-image' alt="orderr"></img>
                            <div >
                                <Button style={{ margin: '15px 5px 15px 5px', padding: '4px 9px 4px 9px', fontSize: '11px', background: 'teal', border: 'none', fontWeight: '500' }} onClick={() => { navigate('/courtorder') }}>Court Order</Button><span style={{ fontSize: '12px' }}><BiCalendar /> 06 September, 2022</span>
                            </div>

                            <span style={{ fontWeight: 'bold' }}>Raheja Developers To Refund Buyers For Delaying Gurgaon</span>

                        </Col>
                        <Col className="d-flex flex-column justify-content-center align-items-center">
                            <img src={require('../images/phase1.jpg')} style={{ height: '180px', width: '270px', borderRadius: '10px' }} alt="phase1"></img>
                            <div>
                                <Button style={{ margin: '15px 5px 15px 5px', padding: '4px 9px 4px 9px', fontSize: '11px', background: 'teal', border: 'none', fontWeight: '500' }} onClick={() => { navigate('/news') }}>News</Button><span style={{ fontSize: '12px' }}><BiCalendar /> 06 September, 2022</span>
                            </div>
                            <span style={{ fontWeight: 'bold' }}>NCDRC Penalises Raheja Developer For <br></br>Delay In Revanta</span>
                            <img src={require('../images/b-block.jpeg')} style={{ height: '180px', width: '277px', borderRadius: '10px', marginTop: '20px' }} alt="title"></img>
                            <div>
                                <Button style={{ margin: '15px 5px 15px 5px', padding: '4px 9px 4px 9px', fontSize: '11px', background: 'teal', border: 'none', fontWeight: '500' }} onClick={() => { navigate('/refundpayment') }}>Refund Payment</Button><span style={{ fontSize: '12px' }}><BiCalendar /> 06 September, 2022</span>
                            </div>
                            <span style={{ fontWeight: 'bold' }}>Builder Told To Refund Payment, Hand<br></br>Over Flats</span>
                        </Col>
                    </Row>
                </div>
                <div className="text-center">Twitter link
                    <a className="twitter-timeline" data-width="500" data-height="500" data-theme="dark" href="https://twitter.com/SocietyRGFBA?ref_src=twsrc%5Etfw">Tweets by SocietyRGFBA</a>
                    <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>
                </div>
                {/* sedcond link */}
                {/* <script src="https://apps.elfsight.com/p/platform.js" defer></script>
                    <div className="elfsight-app-d9e81688-3e6f-4906-90c9-04b780d50cab"></div> */}

                <div className="mt-3 d-flex justify-content-center flex-column">

                    <Row className="p-4">
                        <Card className="p-3">
                            <span style={{ color: '#000', fontSize: '30px', fontWeight: 700, textAlign: 'center' }}>Introduction Form </span>
                            <span className="text-center text-dark fmt-2" style={{ fontSize: '14px' }} >If you are a buyer in the Raheja Revanta Project and want to join us or simply want further information, please introduce yourselves to us by filling out the following questionnaire. We promise to get back to you as soon as we possibly can. Looking forward to hearing from you.</span>
                            <Form className="mt-5 p-4">
                                <Form.Group className="mb-3">
                                    <Row>
                                        <Col  ><Form.Label className="text-dark">First Owner's Name
                                        </Form.Label>
                                            <Form.Control required type="text" placeholder="Enter First Owner's Name" style={{ borderLeftColor: 'grey', borderRightColor: 'grey', borderTopColor: 'grey', borderBottomColor: 'grey' }} /></Col>
                                        <Col  ><Form.Label className='text-dark'>Second Owner's Name</Form.Label>
                                            <Form.Control required type="text" placeholder="Enter Second Owner's Name" style={{ borderLeftColor: 'grey', borderRightColor: 'grey', borderTopColor: 'grey', borderBottomColor: 'grey' }} /></Col>
                                    </Row>
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Row>
                                        <Col  ><Form.Label className='text-dark'>Email of first owner
                                        </Form.Label>
                                            <Form.Control required type="email" placeholder="Enter first owner email" style={{ borderLeftColor: 'grey', borderRightColor: 'grey', borderTopColor: 'grey', borderBottomColor: 'grey' }} /></Col>
                                        <Col  ><Form.Label className='text-dark'> Email of Second Owner </Form.Label>
                                            <Form.Control required type="email" placeholder="Enter Second Owner's email" style={{ borderLeftColor: 'grey', borderRightColor: 'grey', borderTopColor: 'grey', borderBottomColor: 'grey' }} /></Col>
                                    </Row>
                                </Form.Group>

                                <Form.Group className="mb-3">
                                    <Row>
                                        <Col>
                                            <Form.Label className='text-dark'>Phone number 1</Form.Label>
                                            {/* <Form.Control required type="number" placeholder="Enter Phone number 1" style={{ borderLeftColor: 'grey', borderRightColor: 'grey', borderTopColor: 'grey', borderBottomColor: 'grey' }} /> */}
                                            <Input
                                                required
                                                style={{ lineHeight: '2.0' }}
                                                international
                                                defaultCountry="US"
                                                value={value}
                                                onChange={setValue}
                                            />
                                        </Col>
                                        <Col>
                                            <Form.Label className='text-dark' >Phone number 2</Form.Label>
                                            {/* <Form.Control required type="number" placeholder="Enter Phone number 2" style={{ borderLeftColor: 'grey', borderRightColor: 'grey', borderTopColor: 'grey', borderBottomColor: 'grey' }} /> */}
                                            <Input
                                                style={{ lineHeight: '2.0' }}
                                                international
                                                defaultCountry="US"
                                                value={phone2}
                                                onChange={setPhone2}
                                            />
                                        </Col>
                                    </Row>
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Row>
                                        <Col><Form.Label className='text-dark'>Address Line 1

                                        </Form.Label>
                                            <Form.Control required type="text" placeholder="Enter Address Line 1" style={{ borderLeftColor: 'grey', borderRightColor: 'grey', borderTopColor: 'grey', borderBottomColor: 'grey' }} /></Col>
                                        <Col><Form.Label className='text-dark' >Address Line 2</Form.Label>
                                            <Form.Control required type="text" placeholder="Enter Address Line 2" style={{ borderLeftColor: 'grey', borderRightColor: 'grey', borderTopColor: 'grey', borderBottomColor: 'grey' }} /></Col>
                                    </Row>
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Row>
                                        <Col  ><Form.Label className='text-dark'>City

                                        </Form.Label>
                                            <Form.Control required type="text" placeholder="Enter City" style={{ borderLeftColor: 'grey', borderRightColor: 'grey', borderTopColor: 'grey', borderBottomColor: 'grey' }} /></Col>
                                        <Col  ><Form.Label className='text-dark' >State</Form.Label>
                                            <Form.Control required type="text" placeholder="Enter State" style={{ borderLeftColor: 'grey', borderRightColor: 'grey', borderTopColor: 'grey', borderBottomColor: 'grey' }} /></Col>
                                    </Row>
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Row>
                                        <Col  ><Form.Label className='text-dark'>Country

                                        </Form.Label>
                                            <Form.Control required type="text" placeholder="Enter Country Name" style={{ borderLeftColor: 'grey', borderRightColor: 'grey', borderTopColor: 'grey', borderBottomColor: 'grey' }} /></Col>
                                        <Col  ><Form.Label className='text-dark' style={{ borderLeftColor: 'grey', borderRightColor: 'grey', borderTopColor: 'grey', borderBottomColor: 'grey' }}>Post Code</Form.Label>
                                            <Form.Control required type="text" placeholder="Enter Post Code" style={{ borderLeftColor: 'grey', borderRightColor: 'grey', borderTopColor: 'grey', borderBottomColor: 'grey' }} /></Col>
                                    </Row>
                                </Form.Group>

                                <div className='d-flex justify-content-start'>
                                    <Button onClick={() => { setIsOpen(true) }} style={{ background: 'teal', border: "#ff5f13", paddingLeft: '15px', paddingRight: '15px', paddingTop: '5px', paddingBottom: '5px', color: 'white' }} >
                                        Next
                                    </Button>
                                </div>

                            </Form>
                            {isOpen ?
                                <Form className=" p-4">
                            <h3 className="text-center mb-3">Unit Information Section</h3>
                                <Form.Group className="mb-3">
                                    <Row>
                                        <Col  ><Form.Label className="text-dark">Unit Number (eg. A-101 or IF01-01)
                                        </Form.Label>
                                            <Form.Control required type="text" placeholder="Enter Unit" style={{ borderLeftColor: 'grey', borderRightColor: 'grey', borderTopColor: 'grey', borderBottomColor: 'grey' }} /></Col>
                                        <Col  ><Form.Label className='text-dark'>Date of Builder Buyer Agreement</Form.Label>
                                            <Form.Control required type="date" placeholder="Enter Second Owner's Name" style={{ borderLeftColor: 'grey', borderRightColor: 'grey', borderTopColor: 'grey', borderBottomColor: 'grey' }} /></Col>
                                    </Row>
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Row>
                                        <Col  ><Form.Label className='text-dark'>
                                        Completion Period in Builder Buyer Agreement.
                                        </Form.Label>
                                            <Form.Control required type="email" placeholder="Enter  Completion Period in Builder Buyer Agreement in 6 month usually" style={{ borderLeftColor: 'grey', borderRightColor: 'grey', borderTopColor: 'grey', borderBottomColor: 'grey' }} /></Col>
                                        <Col  ><Form.Label className='text-dark'> Unit Type </Form.Label><br></br>
                                          <Col className="ms-2">
                                                <Form.Check
                                                    inline
                                                    label="Townhouse (Tapas)"
                                                    name="unit"
                                                    type='radio'
                                          
                                                />
                                                <Form.Check
                                                    inline
                                                    label="Tower (Surya)"
                                                    name="unit"
                                                    type='radio'

                                                />
                                          </Col>  </Col>
                                    </Row>
                                </Form.Group>

                                <Form.Group className="mb-3">
                                    <Row>
                                        <Col  ><Form.Label className='text-dark'>Type of Builder Buyer Agreement </Form.Label><br></br>
                                            <Col className="ms-2">
                                                <Form.Check
                                                    inline
                                                    label="Construction Link"
                                                    name="builder"
                                                    type='radio'

                                                />
                                                <Form.Check
                                                    inline
                                                    label="Time Link"
                                                    name="builder"
                                                    type='radio'

                                                />
                                                <Form.Check
                                                    inline
                                                    label="Buy Back"
                                                    name="builder"
                                                    type='radio'

                                                />
                                                <Form.Check
                                                    inline
                                                    label="Other"
                                                    name="builder"
                                                    type='radio'

                                                />
                                            </Col>  </Col>
                                        <Col  ><Form.Label className='text-dark'> Total Money paid as percentage of total amount to be paid for apartment </Form.Label><br></br>
                                            <Col className="ms-2">
                                                <Form.Check
                                                    inline
                                                    label="60%"
                                                    name="percentage"
                                                    type='radio'

                                                />
                                                <Form.Check
                                                    inline
                                                    label="60%-70%"
                                                    name="percentage"
                                                    type='radio'

                                                />
                                                <Form.Check
                                                    inline
                                                    label="70%-80%"
                                                    name="percentage"
                                                    type='radio'

                                                />
                                                <Form.Check
                                                    inline
                                                    label="80%-90%"
                                                    name="percentage"
                                                    type='radio'

                                                />
                                                <Form.Check
                                                    inline
                                                    label="90%"
                                                    name="percentage"
                                                    type='radio'

                                                />
                                            </Col>  </Col>
                                    </Row>
                                </Form.Group>
                             
                             <div className='d-flex justify-content-start'>
                                    <Button onClick={() => { setIsOpen2(true) }} style={{ background: 'teal', border: "#ff5f13", paddingLeft: '15px', paddingRight: '15px', paddingTop: '5px', paddingBottom: '5px', color: 'white' }} >
                                        Next
                                    </Button>
                                    <Button onClick={() => { setIsOpen(false) }} style={{ background: 'black', border: "#ff5f13", paddingLeft: '15px', paddingRight: '15px', paddingTop: '5px', paddingBottom: '5px', color: 'white', marginLeft: '15px' }} >
                                        Cancel
                                    </Button>
                                </div>

                            </Form>:null}

                            {isOpen2?
                                <Form className=" p-4">
                                    <h3 className="text-center mb-3">Miscellaneous Section</h3>
                                  
                                    <Form.Group className="mb-3">
                                        <Row>
                                            <Col  ><Form.Label className="text-secondary mb-2">Do you have currently have or had in the past any cas against Raheja Developers Reagarding Their Revanta Project in any of the below legal forums? </Form.Label><br></br>
                                               
                                                    <Form.Check
                                                        inline
                                                        label="NCDRC (National Consumer Disputes Rderessal Commission)"
                                            
                                                    type='checkbox'

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="NCLT"
                                           
                                                    type='checkbox'

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="Haryana RERA (Real Estate Regulatory Authority)"
                                                 
                                                    type='checkbox'

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="Supreme Court"
                                                
                                                    type='checkbox'

                                                    />
                                                <Form.Check
                                                    inline
                                                    label="None"
                                                   
                                                    type='checkbox'

                                                />
                                                </Col> 
                                            
                                        </Row>
                                    </Form.Group>

                                    <div className='d-flex justify-content-start'>
                                        <Button  style={{ background: 'teal', border: "#ff5f13", paddingLeft: '15px', paddingRight: '15px', paddingTop: '5px', paddingBottom: '5px', color: 'white' }} >
                                        Submit
                                        </Button>
                                        <Button onClick={() => { setIsOpen2(false) }} style={{ background: 'black', border: "#ff5f13", paddingLeft: '15px', paddingRight: '15px', paddingTop: '5px', paddingBottom: '5px', color: 'white', marginLeft:'15px' }} >
                                            Cancel
                                        </Button>
                                    </div>

                                </Form>:null}
                        </Card>
                    </Row>
                </div>
            </Row>

        </>)

}
export default Content;
