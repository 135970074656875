import React from 'react'
import { Col, Row,  } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const Vision = () => {
    return (
        <div>
            <div className='contact-image vh-100'>
                <Row className="mt-8">
                    <Col className='text-center ' style={{ marginTop: '20px' }}>
                    <nav aria-label="breadcrumb" >
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/home" className="text-500 text-base font-semibold" style={{ textDecoration: "none" }}>Home</Link></li>
                                <li className="breadcrumb-item active" aria-current="page" ><Link to="/vision" className="text-teal-600  text-base font-semibold" style={{ textDecoration: "none" }}>Vision</Link></li>
                            </ol>
                        </nav>
                        <span className="" style={{ color: '#1a2141', fontSize: '30px', fontWeight: 700, textTransform: 'uppercase', lineHeight: 1.2, marginTop: '100px' }}>Vision</span>
                    </Col>
                    <div data-aos="fade-up" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="600">

                        <Row lg={12} className='d-flex justify-content-start' >
                            <Col className="d-flex flex-column justify-content-start mt-4 m-3 ">
                                <p style={{ fontSize: '17px', color: '#777777', fontStyle: 'sans-serif' }}>
                                    RGFBA (raheja gurgaon flat buyers association) represents the interests of all buyers and residents.
                                    The project has been delayed beyond the acceptable time limits. Our main vision is to fulfill the
                                    promises made by the builder including:
                                </p>
                                <ul style={{ fontSize: '17px', color: '#777777', fontStyle: 'sans-serif', marginTop: '20px' }}>
                                    <li >Align what the builder promised in terms of penalty, facilities, and completion deadlines with what is the reality today and in the future.</li>
                                    <li className='mt-2'> Prevent the builder from indulging in unethical practices that further delay possession or cause quality-related issues to creep in, charging more on the pretext of super-area increase and other frivolous charges, etc.</li>
                                    <li className='mt-2'>Seek justice by way of compensation or otherwise.</li>
                                </ul>
                            </Col>
                        </Row>
                    </div>
                </Row>
            </div>
        </div>
    )
}

export default Vision
